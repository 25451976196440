import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loading from '@artemis/components/Loading';
import { useDispatch } from 'react-redux';
import { setHqOInitData } from '@artemis/store/embed/slice';
import {
  setupClient,
  init,
  getStorage,
  setStorage,
} from '@artemis/integrations/embed/hqo/hqoClient';
import { EMBED_INTEGRATION_TYPE } from '@artemis/integrations/embed/constants';
import {
  logIntegrationAutomaticLogout,
  logIntegrationInitialized,
  logIntegrationInitialLoad,
} from '@artemis/integrations/embed/analytics';
import { EMBED_LOCAL_TEST } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import AuthenticationContext from './AuthenticationContext';
import useAuth from './useAuth';

const EmbeddedHqoWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const isEmbedLocalTest = useQueryCheck(EMBED_LOCAL_TEST);

  const [clientLoaded, setClientLoaded] = useState(false);
  const [logoutAfterInit, setLogoutAfterInit] = useState(false);
  const authData = useAuth();
  const { initialized, authenticated, logout } = authData;

  // Use a stubbed implementation of HqO's SDK for local testing, when `?embedLocalTest=1` is present
  const useStubs =
    isEmbedLocalTest && process.env.RT_ENVIRONMENT === 'development';

  useEffect(() => {
    logIntegrationInitialLoad({
      integrationType: EMBED_INTEGRATION_TYPE.HQO,
    });

    // Load HqO client and save HqO init data
    const loadClient = async () => {
      await setupClient({ useStubs });
      const initData = await init();
      dispatch(setHqOInitData(initData));
    };

    // Check if user signed out on hqo
    // If so, sign out of ritual
    const checkSignOutStatus = async () => {
      const { retainRitualSession } = await getStorage({
        key: 'retainRitualSession',
      });
      if (!retainRitualSession) {
        await setStorage({
          key: 'retainRitualSession',
          value: true,
        });
        logIntegrationAutomaticLogout({
          integrationType: EMBED_INTEGRATION_TYPE.HQO,
        });
        setLogoutAfterInit(true);
      }
    };

    const initializeHqO = async () => {
      await loadClient();
      await checkSignOutStatus();
      setClientLoaded(true);

      logIntegrationInitialized({
        integrationType: EMBED_INTEGRATION_TYPE.HQO,
      });
    };

    initializeHqO();
  }, []);

  useEffect(() => {
    if (initialized && logoutAfterInit) {
      if (authenticated) {
        logout();
      } else {
        setLogoutAfterInit(false);
      }
    }
  }, [initialized, authenticated, logoutAfterInit]);

  if (!clientLoaded || logoutAfterInit) {
    return <Loading isLoading data-testid="embedded-hqo-loading" />;
  }

  return (
    <AuthenticationContext.Provider value={authData}>
      {children}
    </AuthenticationContext.Provider>
  );
};

EmbeddedHqoWrapper.propTypes = {
  children: PropTypes.element,
};

export default EmbeddedHqoWrapper;
