/* istanbul ignore file */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
const INIT_HANDLER = 'initResponse';
const GET_PAYMENT_METHODS_HANDLER = 'getPaymentMethodsResponse';
const TOKENIZE_CARD_HANDLER = 'tokenizeCardResponse';
const SHOW_NATIVE_PAYMENT_HANDLER = 'showNativePaymentResponse';
const GET_STORAGE_HANDLER = 'getStorageResponse';

const initResponse = {
  jwt_token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ2ZXJzaW9uIjoyLCJzdWIiOjE1OTQ3MSwidXNlcl91dWlkIjoiYmM2NzZjNzEtNWIyMC00YWQzLWFlNzAtZmU2MTM2ODQ0N2Y0IiwiaWF0IjoxNjcwNTM2MzYyLCJleHAiOjE3NTY5MzYzNjJ9.jHBbNUUZ64ebDPXXCSLq5As9bRuisdEJX43_GHBA6W8',
  session_token:
    'eyJhbGciOiJSUzUxMiIsInR5cCI6IkpXVCIsImtpZCI6IjEwYmExOGVhLTc0MzgtNDM4Yi04NGU5LTdkNGM0Njc5Y2Y0MSJ9.eyJ1c2VyX3V1aWQiOiJiYzY3NmM3MS01YjIwLTRhZDMtYWU3MC1mZTYxMzY4NDQ3ZjQiLCJhcHBfYnJhbmQiOiJIUU8iLCJidWlsZGluZ191dWlkIjoiZDc1YTU0ZjctODVmMC00MmU2LWJhMDktZTc0M2NjYjkzNDNjIiwiYXBwX3V1aWQiOiIxMGJhMThlYS03NDM4LTQzOGItODRlOS03ZDRjNDY3OWNmNDEiLCJiYXNlX3VybCI6Imh0dHBzOi8vd3d3Lmhxb2FwcC5jb20iLCJpYXQiOjE2NzUxMTU1ODF9.wkpOMdLn5YLKCKIb9c30DVIBg8XObco_AOonXNNYKFPsuJks13hxfrBVdDlr2CWyntq-dEYK1IWB--JCKDgg4YcH3J2E0vFMdrY9yhR753v20mKw1ZMNPbDhG99s1zndqecDpQgb_XOm8d16h3vG6_f6Iu2JEu67iJMignLbC5FoWg4GamFCNXcx_QW37_hrpkmyGNGqgXuhmvvX8QWs7h4c0-RAuUL3Kli8gA0-zpxOl7B0p0Lk6xyUoYER9rufW5JcHrAHmmyloqtPnkPUptGtL60iFdL4uFCl3CDyLVwRqQiVgSjdDv5ne2nLcAxVNb1M1YoGu7-3Gxr4LHz41Q',
  brand: 'hqo',
  building: {
    address: '38 Chauncy Street',
    timezone: 'America/New_York',
    currency_type: 'USD',
    name: 'Ritual Integration',
    theme: {
      primary_font_color: '',
      primary2: '#7c7933',
      font: { heading: {}, title: {}, body: {} },
      primary1: '#257bad',
    },
    uuid: 'd75a54f7-85f0-42e6-ba09-e743ccb9343c',
  },
  theme: {
    primary_font_color: '',
    primary2: '#7c7933',
    font: { heading: {}, title: {}, body: {} },
    primary1: '#257bad',
  },
  user: {
    roles: [
      {
        global: true,
        updated_at: '2020-06-26T14:17:46.000Z',
        id: 1,
        created_at: '',
        uuid: '83bac84f-ebf7-4292-be5e-88175de1aae8',
        display_name: 'Building Tenant',
        permissions: [],
        name: 'building_tenant',
        description:
          'Building tenant, has no admin or web app permissions, but can view and use functionality on mobile app',
      },
    ],
    test: 1,
    id: 159471,
    company_uuid: '2d520280-73cc-456c-9872-48f7f66f2495',
    company: {
      name: 'Breadberry Demo',
      uuid: '2d520280-73cc-456c-9872-48f7f66f2495',
    },
    uuid: 'bc676c71-5b20-4ad3-ae70-fe61368447f4',
    email: 'ritual+test2@hqo.co',
    last_name: 'Test',
    first_name: 'Ritual',
  },
};

const getPaymentMethodsResponse = {
  cards: [
    {
      card_last_four: '6782',
      card_brand: 'VISA',
      token_id: 'HQO_CARD_ID_1',
    },
    {
      card_last_four: '4242',
      card_brand: 'VISA',
      token_id: 'HQO_CARD_ID_2',
    },
  ],
  apple_pay: true,
  google_pay: false,
};

const tokenizeCardResponse = {
  card_id: 'HQO_CARD_ID',
  token: 'STRIPE_PAYMENT_METHOD_ID',
};

const showNativePaymentSuccessResponse = {
  success: true,
  id: 'HQO_VAULTED_APPLE_PAY_ID',
};

const showNativePaymentErrorResponse = {
  success: false,
  id: '',
};

// A stubbed implementation of HqO's SDK for local testing
export class StubbedHqoSDK {
  constructor({ app_uuid, develop }) {
    this.uuid = app_uuid;
    this.develop = develop;
    this.handlers = {};
  }

  on(handlerName, handler) {
    this.handlers[handlerName] = handler;
  }

  invokeHandler(handlerName, data) {
    const handler = this.handlers[handlerName];
    if (handler) {
      setTimeout(() => {
        this.handlers[handlerName](data);
      }, 1000); // delay calling handler, to simulate latency of HqO's SDK
    } else {
      // eslint-disable-next-line no-console
      console.error('No handler set for: ', handlerName);
    }
  }

  init() {
    this.invokeHandler(INIT_HANDLER, initResponse);
  }

  get payments() {
    return {
      getPaymentMethods: () =>
        this.invokeHandler(
          GET_PAYMENT_METHODS_HANDLER,
          getPaymentMethodsResponse,
        ),
      tokenizeCard: paymentId =>
        this.invokeHandler(TOKENIZE_CARD_HANDLER, tokenizeCardResponse),
      showNativePayment: (
        paymentType,
        { currency, authorization_total, subtotal, tax },
      ) => {
        // eslint-disable-next-line no-alert
        const confirmed = prompt(
          `Do you agree to pay $${authorization_total} ${currency}?`,
          'YES',
        );
        if (confirmed === 'YES') {
          this.invokeHandler(
            SHOW_NATIVE_PAYMENT_HANDLER,
            showNativePaymentSuccessResponse,
          );
        } else {
          this.invokeHandler(
            SHOW_NATIVE_PAYMENT_HANDLER,
            showNativePaymentErrorResponse,
          );
        }
      },
    };
  }

  get storage() {
    return {
      get: key => {
        const data = window.localStorage.getItem(`hqo_test_${key}`);
        this.invokeHandler(GET_STORAGE_HANDLER, { [key]: data });
      },
      set: (key, data) => window.localStorage.setItem(`hqo_test_${key}`, data),
    };
  }
}
