import auth from './auth/slice';
import cart from './cart/slice';
import catalog from './catalog/slice';
import delivery from './delivery/slice';
import deliveryAddresses from './deliveryAddresses/slice';
import embed from './embed/slice';
import fulfillment from './fulfillment/slice';
import groupOrder from './groupOrder/slice';
import incentives from './incentives/slice';
import invite from './invite/slice';
import ipData from './ipData/slice';
import menu from './menu/slice';
import merchant from './merchant/slice';
import merchantGroup from './merchantGroup/slice';
import merchantPreview from './merchantPreview/slice';
import order from './order/slice';
import organization from './organization/slice';
import paymentOptions from './paymentOptions/slice';
import points from './points/slice';
import promoCodes from './promoCodes/slice';
import resourceSet from './resourceSet/slice';
import scheduling from './scheduling/slice';
import localSelections from './localSelections/slice';
import teams from './teams/slice';
import toasts from './toasts/slice';
import user from './user/slice';
import verifyEmail from './verifyEmail/slice';
import workspace from './workspace/slice';

export default {
  auth,
  cart,
  catalog,
  delivery,
  deliveryAddresses,
  embed,
  fulfillment,
  groupOrder,
  incentives,
  invite,
  ipData,
  localSelections,
  menu,
  merchant,
  merchantGroup,
  merchantPreview,
  order,
  organization,
  paymentOptions,
  points,
  promoCodes,
  resourceSet,
  scheduling,
  teams,
  toasts,
  user,
  verifyEmail,
  workspace,
};
