import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import createSagaMiddleware from 'redux-saga';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import { logErrorInSentry } from '@artemis/integrations/sentry';
import createAthenaApiClient from '@artemis/api/athena';
import createClientUserGatewayApiClient from '@artemis/api/clientUserGateway';
import createContentGatewayApiClient from '@artemis/api/contentGateway';
import createOrgspacesApiClient from '@artemis/api/orgspaces';
import createFulfillmentsApiClient from '@artemis/api/fulfillment';
import createInvitesApiClient from '@artemis/api/invites';
import { name as LOCAL_SELECTIONS } from '@artemis/store/localSelections/slice';
import reducer from './rootReducer';
import rootSaga from './rootSaga';

function makeStore(context, initialState) {
  const { req = null, res = null } = context?.ctx ?? {};
  const persistConfig = {
    key: 'root',
    storage: storage('redux-persist'),
    whitelist: [LOCAL_SELECTIONS], // Only persist these slices
  };
  const persistedReducer = persistReducer(
    persistConfig,
    combineReducers(reducer),
  );
  const sagaMiddleware = createSagaMiddleware({
    context: {},
    onError(err) {
      logErrorInSentry(err);
    },
  });
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat([
        sagaMiddleware,
      ]),
    preloadedState: initialState,
  });

  const apiContext = {
    isServer: typeof window === 'undefined',
    req,
    res,
    store,
  };

  sagaMiddleware.setContext({
    athenaApiClient: createAthenaApiClient(apiContext),
    clientUserGatewayApiClient: createClientUserGatewayApiClient(apiContext),
    contentGatewayApiClient: createContentGatewayApiClient(apiContext),
    orgspacesApiClient: createOrgspacesApiClient(apiContext),
    fulfillmentApiClient: createFulfillmentsApiClient(apiContext),
    invitesApiClient: createInvitesApiClient(apiContext),
  });

  let sagaTask = sagaMiddleware.run(rootSaga);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./rootReducer');
      store.replaceReducer(nextRootReducer);
    });
    module.hot.accept('./rootSaga', () => {
      // eslint-disable-next-line global-require
      const getNewSagas = require('./rootSaga');
      sagaTask.cancel();
      sagaTask.done.then(() => {
        sagaTask = sagaMiddleware.run(function* replacedSaga() {
          yield getNewSagas();
        });
      });
    });
  }

  store.sagaTask = sagaTask;
  return store;
}

export default makeStore;

export const wrapper = createWrapper(makeStore);
