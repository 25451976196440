/* eslint-disable no-param-reassign */
import axios from 'axios';
import { parse, serialize } from 'cookie';
import queryString from 'query-string';
import { getKcTokenClientSide } from '@artemis/integrations/auth/utils';
import { getSource } from '@artemis/store/order/selectors';
import {
  ORDER_SOURCE_HEADER,
  SOURCE_ID_HEADER,
  SOURCE_SUBDOMAIN_HEADER,
  SOURCE_SUBDOMAIN_ID_HEADER,
} from '@artemis/utils/constants';
import { addCookiesToResponse } from '@artemis/utils/cookie';
import { logFailedRequest } from '@artemis/utils/log';
import { ORDER_IDENTIFIER_TYPES } from '@artemis/utils/query/constants';
import { OFFER_ID_COOKIE } from '@artemis/utils/cookies/constants';
import { getAcceptLanguageHeader } from './util';

const url = require('url');

const formatSourceHeaders = source => ({
  ...(source?.orderSource && {
    [ORDER_SOURCE_HEADER]: source.orderSource,
  }),
  ...(source?.sourceId && {
    [SOURCE_ID_HEADER]: source.sourceId,
  }),
  ...(source?.subdomain && {
    [SOURCE_SUBDOMAIN_HEADER]: source.subdomain,
  }),
  ...(source?.subdomainId && {
    [SOURCE_SUBDOMAIN_ID_HEADER]: source.subdomainId,
  }),
});

const createServerApiClient = ({ req, res, store }) => {
  let baseURL = `${process.env.RT_SERVER_BACKEND_URL}`;
  if (
    ['development', 'sandbox', 'qa'].includes(process.env.RT_ENVIRONMENT) &&
    req
  ) {
    const params = url.parse(req.url, true).query;
    baseURL = params.server_backend_url || baseURL;
  }

  const apiClient = axios.create({ baseURL, headers: {} });

  apiClient.interceptors.request.use(async config => {
    const cookies = parse(req?.headers?.cookie || '');

    const languages =
      config.useMenuLanguage && cookies['rt-lang-menu']
        ? [cookies['rt-lang-menu'], req.rtLang]
        : [req.rtLang];

    config.headers['Accept-Language'] = `${languages.join(',')};q=0.9`;
    cookies['rt-lang'] = req.rtLang;
    if (req.offerId) {
      cookies[OFFER_ID_COOKIE] = req.offerId;
    }

    const cookieStr = Object.entries(cookies)
      .map(([name, value]) => `${name}=${encodeURIComponent(value)}`)
      .join('; ');

    config.headers.Cookie = cookieStr;

    if (req?.accessToken) {
      config.headers.authorization = `Bearer ${req.accessToken}`;
    }

    const orderSource = getSource(store?.getState());
    if (orderSource) {
      config.headers = {
        ...config.headers,
        ...formatSourceHeaders(orderSource),
      };
    }

    return config;
  });

  apiClient.interceptors.response.use(
    apiResponse => {
      if (!res.headersSent) {
        if (!apiResponse.headers['set-cookie']) {
          apiResponse.headers['set-cookie'] = [];
        }
        apiResponse.headers['set-cookie'].push(
          serialize('rt-lang', req.rtLang, {
            path: '/',
            domain: process.env.RT_SHARED_COOKIE_DOMAIN,
          }),
        );
        addCookiesToResponse({
          res,
          cookies: apiResponse.headers['set-cookie'],
        });
      }
      return apiResponse;
    },
    err => {
      logFailedRequest({ logger: req.logger, err });
    },
  );
  return apiClient;
};

export const createBrowserApiClient = ({ store } = {}) => {
  let baseURL = `${process.env.RT_BROWSER_BACKEND_URL}`;
  if (['development', 'sandbox', 'qa'].includes(process.env.RT_ENVIRONMENT)) {
    const searchParams = new URLSearchParams(window.location.search);
    baseURL = searchParams.get('server_backend_url') || baseURL;
  }

  const apiClient = axios.create({
    baseURL,
    withCredentials: true,
  });

  apiClient.interceptors.request.use(config => {
    const kcToken = getKcTokenClientSide();
    try {
      if (kcToken) {
        config.headers.authorization = `Bearer ${kcToken}`;
      }
    } catch {
      // intentionally empty
    }

    config.headers['Accept-Language'] = getAcceptLanguageHeader({
      useMenuLanguage: config.useMenuLanguage,
    });

    if (store) {
      const orderSource = getSource(store?.getState());
      if (orderSource) {
        config.headers = {
          ...config.headers,
          ...formatSourceHeaders(orderSource),
        };
      }
    }

    return config;
  });

  return apiClient;
};

export const createAthenaApiClient = ({ isServer, req, res, store } = {}) => {
  if (isServer) {
    return createServerApiClient({ req, res, store });
  }
  return createBrowserApiClient({ store });
};

export const fetchUser = async apiClient => apiClient.get(`/v1/user`);

export const updateUser = async (apiClient, data) =>
  apiClient.patch(`/v1/user`, data);

export const fetchAnalyticsIdentity = async apiClient =>
  apiClient.get('/_COOKIES');

export const fetchMerchantGroup = async (apiClient, externalId) =>
  apiClient.get(`/v1/brands?externalId=${externalId}`);

export const fetchMerchant = async (
  apiClient,
  { externalId, brandExternalId, cartCheckoutCode, id },
) => {
  const merchant = await apiClient.get('/v1/merchants', {
    params: {
      externalId,
      brandExternalId,
      id,
      ...(cartCheckoutCode && { cartCheckoutCode }), // this parameter should be omitted if false
    },
  });

  return {
    ...merchant,
    data: {
      ...merchant.data,
      promotions: [
        {
          type: 'EMPLOYEE_PRICING',
          summary: 'Food Fest',
        },
      ],
    },
  };
};

export const fetchMerchantPreview = async (
  apiClient,
  merchantPreviewId,
  countryCode,
) => {
  let endpoint = '/v1/merchantpreview';
  const merchantPreviewIdString = `merchantPreviewId=${merchantPreviewId}`;
  const countryCodeString = `countryCode=${countryCode}`;
  if (merchantPreviewId && countryCode) {
    endpoint += `?${merchantPreviewIdString}&${countryCodeString}`;
  } else if (merchantPreviewId) {
    endpoint += `?${merchantPreviewIdString}`;
  } else if (countryCode) {
    endpoint += `?${countryCodeString}`;
  }
  return apiClient.get(endpoint);
};

export const fetchMerchantDeliveryInfo = async (apiClient, { merchantId }) =>
  apiClient.get(`/merchants/${merchantId}/delivery-info`);

export const fetchMenuWithExternalId = async (
  apiClient,
  { externalId, debug, menuDraftId, excludeChoices, includeChoicesForItemId },
) => {
  const endpoint = queryString.stringifyUrl(
    {
      url: `/v1/menus`,
      query: {
        merchantExternalId: externalId,
        excludeChoices,
        includeChoicesForItemId,
        debug,
        menuDraftId,
      },
    },
    { skipNull: true },
  );
  return apiClient.get(endpoint, { useMenuLanguage: true });
};

export const fetchMenu = async (
  apiClient,
  { id, dateTime, debug, menuDraftId, excludeChoices },
) => {
  const endpoint = queryString.stringifyUrl(
    {
      url: `/v1/menu/${id}`,
      query: { excludeChoices, dateTime, debug, menuDraftId },
    },
    { skipNull: true },
  );
  return apiClient.get(endpoint, { useMenuLanguage: true });
};

export const fetchMenuItemChoices = async (apiClient, { menuId, itemId }) => {
  const endpoint = queryString.stringifyUrl(
    { url: `/v1/menu/${menuId}/items/${itemId}/choices` },
    { skipNull: true },
  );
  return apiClient.get(endpoint, { useMenuLanguage: true });
};

export const fetchCart = async (apiClient, { merchantId }) =>
  apiClient.get(`/v1/cart/${merchantId}`, { useMenuLanguage: true });

export const fetchCartDeliveryInfo = async (apiClient, { merchantId }) =>
  apiClient.get(`/cart/${merchantId}/delivery-info`);

export const patchCart = async (apiClient, { merchantId, ...rest }) =>
  apiClient.patch(`/v1/cart/${merchantId}`, { ...rest });

export const addToCart = async (apiClient, { merchantId, item }) =>
  apiClient.post(`/v1/cart/${merchantId}/item`, item, {
    useMenuLanguage: true,
  });

export const modifyItem = async (apiClient, { merchantId, cartItemId, item }) =>
  apiClient.put(`/v1/cart/${merchantId}/item/${cartItemId}`, item, {
    useMenuLanguage: true,
  });

export const removeFromCart = async (apiClient, { merchantId, item }) =>
  apiClient.delete(`/v1/cart/${merchantId}/item/${item}`, {
    useMenuLanguage: true,
  });

export const placeGuestOrder = async (apiClient, { order }) =>
  apiClient.post(`/v1/guest-order`, order);

export const placeOrder = async (apiClient, { order }) =>
  apiClient.post(`/v1/order`, order);

export const setTip = async (
  apiClient,
  { merchantId, selectedTipOptionId, customTipAmountMicro },
) =>
  apiClient.put(`/v1/cart/${merchantId}/config`, {
    selectedTipOptionId,
    customTipAmountMicro,
  });

export const setFulfillment = async (
  apiClient,
  {
    merchantId,
    merchantFulfillmentType,
    userDeliveryAddressDetail,
    userDeliveryAddressId,
  },
) =>
  apiClient.put(`/v1/cart/${merchantId}/fulfillment`, {
    fulfillmentType: merchantFulfillmentType,
    userDeliveryAddressDetail,
    userDeliveryAddressId,
  });

export const addPromo = async (apiClient, { merchantId, checkoutCodeToAdd }) =>
  apiClient.put(`/v1/cart/${merchantId}/checkout-code`, { checkoutCodeToAdd });

export const removePromo = async (
  apiClient,
  { merchantId, checkoutCodeToRemove },
) =>
  apiClient.put(`/v1/cart/${merchantId}/checkout-code`, {
    checkoutCodeToRemove,
  });

export const getPaymentMethods = async (apiClient, { processor } = {}) =>
  apiClient
    .get('/payment/method', { params: { paymentProcessor: processor } })
    .then(({ data }) => data);

export const addPaymentMethod = async (
  apiClient,
  { paymentMethodIdentifier, token, paymentMethodDisplayDetails },
) =>
  apiClient
    .post('/payment/method', {
      paymentMethodIdentifier,
      token,
      paymentMethodDisplayDetails,
    })
    .then(({ data }) => data);

export const deletePaymentMethod = async (apiClient, { methodId }) =>
  apiClient.delete(`/payment/method?paymentMethodId=${methodId}`);

export const setupStripeIntent = async apiClient =>
  apiClient.post('/payment/setup-intent').then(({ data }) => data);

export const getGuestOrder = async (apiClient, { orderId }) =>
  apiClient.get(`/v1/guest-order/${orderId}`);

export const cancelGuestOrder = async (apiClient, { orderId }) =>
  apiClient.post(`/v1/guest-order/cancel/${orderId}`);

export const getOrder = async (apiClient, { orderId }) =>
  apiClient.get(`/v1/order/${orderId}`, { useMenuLanguage: true });

export const cancelOrder = async (apiClient, { orderId }) =>
  apiClient.patch(`/v1/order/${orderId}/cancel`);

export const continueOrder = async (
  apiClient,
  { orderId, useMock = false },
) => {
  if (useMock) {
    // TODO: remove in CORP-382
    await new Promise(resolve => setTimeout(resolve, 1000));
    return {};
  }

  return apiClient.patch(`/v1/order/${orderId}/continue`);
};

export const getOrderWithToken = async (apiClient, { orderId: token }) =>
  apiClient.get(`/v1/order/token/${token}`, { useMenuLanguage: true });

export const cancelOrderWithToken = async (apiClient, { orderId: token }) =>
  apiClient.patch(`/v1/order/token/${token}/cancel`, { useMenuLanguage: true });

export const continueOrderWithToken = async (
  apiClient,
  { orderId: token, useMock = false },
) => {
  if (useMock) {
    // TODO: remove in CORP-382
    await new Promise(resolve => setTimeout(resolve, 1000));
    return {};
  }
  return apiClient.patch(`/v1/order/token/${token}/continue`);
};

export const convertCart = async (
  apiClient,
  { merchantId, sourceType = 'CURRENT_EXTERNAL_ID' },
) =>
  apiClient.post(
    `/v1/users/current/cart/${merchantId}?sourceType=${sourceType}`,
  );

export const fetchDelivery = async (
  apiClient,
  { orderIdentifier, orderIdentifierType = ORDER_IDENTIFIER_TYPES.GUEST_TOKEN },
) =>
  apiClient.get(
    `/v1/delivery-status?orderIdentifier=${orderIdentifier}&orderIdentifierType=${orderIdentifierType}`,
  );

export const fetchDeliveryAddresses = async apiClient =>
  apiClient.get('/delivery-addresses');

export const deleteDeliveryAddress = async (apiClient, { addressId }) =>
  apiClient.delete(`/delivery-addresses/${addressId}`);

export const addDeliveryAddress = async (apiClient, deliveryAddressDetail) =>
  apiClient.post('/delivery-addresses', {
    address: deliveryAddressDetail,
  });

export const setScheduledForTime = async (
  apiClient,
  { merchantId, scheduledForTime },
) =>
  apiClient.put(
    `/v1/cart/${merchantId}/scheduled-for-time`,
    {
      scheduledForTime,
    },
    { useMenuLanguage: true },
  );

export const getAllIncentives = async (
  apiClient,
  { merchantId, cartCheckoutCode },
) =>
  apiClient.get(`/v1/incentive`, {
    params: { merchantId, cartCheckoutCode },
  });

export const getAllIncentivesForCart = async (
  apiClient,
  { merchantId, cartCheckoutCode },
) =>
  apiClient.get(`/v1/cart/${merchantId}/incentives`, {
    params: { cartCheckoutCode },
  });

export const updateCartIncentives = async (
  apiClient,
  { merchantId, incentives, cartCheckoutCode },
) =>
  apiClient.patch(
    `/v1/cart/${merchantId}/incentives`,
    {
      incentives,
    },
    { params: { cartCheckoutCode } },
  );

export const fetchPromoCode = async (apiClient, { promoCode }) =>
  apiClient.get(`/promo-codes/${promoCode}`);

export default createAthenaApiClient;
