import {
  getIsEmbedded,
  getIntegrationType,
} from '@artemis/store/embed/selectors';
import {
  getActiveOrganizationId,
  getOrganizationKeycloakClientId,
} from '@artemis/store/organization/selectors';
import { useSelector } from 'react-redux';
import { getKeycloakConfig } from './utils';

const useKeycloakConfig = () => {
  const isEmbed = useSelector(getIsEmbedded);
  const organizationId = useSelector(getActiveOrganizationId);
  const organizationClientId = useSelector(getOrganizationKeycloakClientId);
  const integrationType = useSelector(getIntegrationType);

  return getKeycloakConfig({
    isEmbed,
    integrationType,
    organizationId,
    organizationClientId,
  });
};

export default useKeycloakConfig;
