import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { fetchPromoCode } from '@artemis/api/athena';
import {
  loadPromoCode,
  loadPromoCodeError,
  loadPromoCodeSuccess,
} from './slice';

export function* loadPromoCodeSaga({ payload: promoCode }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchPromoCode, apiClient, { promoCode });
    yield put(loadPromoCodeSuccess(data));
  } catch (err) {
    yield put(loadPromoCodeError(err));
  }
}

export default function* promoCodesData() {
  yield takeLatest(loadPromoCode.type, loadPromoCodeSaga);
}
