import { useSelector } from 'react-redux';
import Cookies from '@artemis/utils/cookies';
import { setSource } from '@artemis/store/order/slice';
import {
  setActiveOrganization,
  setConfig,
} from '@artemis/store/organization/slice';
import {
  getOrderSource,
  getOrderSourceId,
  getOrderSourceOrigin,
  getOrderSourceSubdomain,
  getOrderSourceSubdomainId,
} from '@artemis/store/order/selectors';
import {
  ORDER_SOURCE,
  ORDER_SOURCE_HEADER,
  SOURCE_ID_HEADER,
  SOURCE_SUBDOMAIN_HEADER,
  SOURCE_SUBDOMAIN_ID_HEADER,
} from '@artemis/utils/constants';
import createOrgspacesApiClient, {
  getPublicConfig,
} from '@artemis/api/orgspaces';
import { parse } from 'cookie';

export const useSetSourceCookie = () => {
  const orderSource = useSelector(getOrderSource);
  const sourceId = useSelector(getOrderSourceId);
  const subdomain = useSelector(getOrderSourceSubdomain);
  const subdomainId = useSelector(getOrderSourceSubdomainId);
  const origin = useSelector(getOrderSourceOrigin);

  return () => {
    // Clear any existing source cookie
    Cookies.remove('source', { domain: process.env.RT_SHARED_COOKIE_DOMAIN });

    if (orderSource !== ORDER_SOURCE.RITUAL_ONE) {
      const in4Hours = 1 / 6; // 4 divided by 24 hours
      Cookies.set(
        'source',
        JSON.stringify({
          orderSource,
          sourceId,
          subdomain,
          subdomainId,
          origin: origin || window.location.origin,
        }),
        { expires: in4Hours, domain: process.env.RT_SHARED_COOKIE_DOMAIN },
      );
    }
  };
};

// Parse order source information from "source" cookie and set organization + order source in redux
export const configureStoreBasedOnOrderSourceCookie = ({ req, store }) => {
  const cookies = parse(req.headers.cookie || '');
  const source = getSourceFromCookie(cookies);
  if (source) {
    store.dispatch(setSource(source));

    if (source.orderSource === ORDER_SOURCE.ORGANIZATION && source.sourceId) {
      store.dispatch(setActiveOrganization(source.sourceId));
    }
  }
};

export const getSourceFromCookie = cookies => {
  const rawValue = cookies?.source;

  if (rawValue) {
    try {
      return JSON.parse(rawValue);
    } catch (e) {
      return null;
    }
  }

  return null;
};

const fetchOrganizationConfigBySubdomain = async ({ ctx, subdomain }) => {
  const { req, res, store } = ctx;
  try {
    const orgspacesApi = createOrgspacesApiClient({ isServer: true, req, res });
    const resp = await getPublicConfig(orgspacesApi, { subdomain });
    const config = resp?.data?.results || [];
    store.dispatch(setConfig(config));
    return config;
  } catch (e) {
    return null;
  }
};

export const parseContextFromHeaders = async ctx => {
  const { req } = ctx;
  const { headers } = req;
  const subdomain = headers[SOURCE_SUBDOMAIN_HEADER];

  if (ORDER_SOURCE_HEADER in headers) {
    const orderSource = headers[ORDER_SOURCE_HEADER];
    const sourceId = headers[SOURCE_ID_HEADER];
    const subdomainId = headers[SOURCE_SUBDOMAIN_ID_HEADER];
    ctx.store.dispatch(
      setSource({
        orderSource,
        sourceId,
        subdomain,
        subdomainId,
      }),
    );

    if (orderSource === ORDER_SOURCE.ORGANIZATION && sourceId) {
      ctx.store.dispatch(setActiveOrganization(sourceId));
    }
  }

  if (subdomain && process.env.RT_DYNAMIC_KEYCLOAK_CLIENTS) {
    return fetchOrganizationConfigBySubdomain({ subdomain, ctx });
  }

  return null;
};
