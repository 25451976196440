import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isModalOpen: false,
  isLoading: false,
  error: null,
  isCheckout: false,
};

const schedulingSlice = createSlice({
  name: 'scheduling',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isModalOpen = true;
      state.isCheckout = action.payload?.isCheckout || false;
    },
    closeModal: state => {
      state.isModalOpen = false;
    },
    setScheduledForTime: state => {
      state.isLoading = true;
    },
    setScheduledForTimeSuccess: state => {
      state.isLoading = false;
      state.isModalOpen = false;
      state.error = null;
    },
    setScheduledForTimeError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  openModal,
  closeModal,
  setScheduledForTime,
  setScheduledForTimeSuccess,
  setScheduledForTimeError,
} = schedulingSlice.actions;

export default schedulingSlice.reducer;
