import { useRouter } from 'next/router';
import {
  HIDE_EXPRESS_SERVICE_PARAM,
  CHECKOUT_LOGIN_PARAM,
  EXPRESS_LOCAL_TEST,
  FILTER_DELIVERY_PARAM,
  FILTER_OPEN_PARAM,
  FILTER_PICKUP_PARAM,
  FILTER_TOP_PARAM,
  GO_CONSUMER_FLOW_PARAM,
  GROUP_ORDER_SHARE_PARAM,
  HIDE_DINE_IN_TOGGLE_PARAM,
  HIDE_PICKUP_PARAM,
  HQO_LINKOUT_PARAM,
  IN_STORE_PARAM,
  MENU_DEBUG_PARAM,
  MENU_GROUP_ORDER_INVITE_PARAM,
  ORDER_LATER_PARAM,
  PICKUP_PARAM,
  SUPPRESS_ORDER_BAR_PARAM,
  HIDE_DOWNLOAD_APP_PARAM,
} from './constants';

/** This serves as documentation for the expected values of `boolean` query params. */
const check = (query, param) => {
  switch (param) {
    case IN_STORE_PARAM:
    case HIDE_DINE_IN_TOGGLE_PARAM:
    case ORDER_LATER_PARAM:
    case HIDE_PICKUP_PARAM:
    case PICKUP_PARAM:
    case HIDE_EXPRESS_SERVICE_PARAM:
    case HIDE_DOWNLOAD_APP_PARAM:
      return query[param] === 'true';
    case CHECKOUT_LOGIN_PARAM:
      return query[param] === 'false';
    case GROUP_ORDER_SHARE_PARAM:
    case MENU_GROUP_ORDER_INVITE_PARAM:
    case GO_CONSUMER_FLOW_PARAM:
    case EXPRESS_LOCAL_TEST:
    case SUPPRESS_ORDER_BAR_PARAM:
    case FILTER_OPEN_PARAM:
    case FILTER_PICKUP_PARAM:
    case FILTER_DELIVERY_PARAM:
    case FILTER_TOP_PARAM:
    case HQO_LINKOUT_PARAM:
      return query[param] === '1';
    case MENU_DEBUG_PARAM:
    default:
      return query[param] !== undefined;
  }
};

/**
 * Compares each provided query param against the appropriate value it was defined for
 * Use this directly when hooks are not available. Otherwise using `useQueryCheck` is preferred.
 * @param {object} query query object
 * @param {string|string[]} params query param name or list of query param names
 */
export const queryCheck = (query, params) =>
  Array.isArray(params)
    ? params.map(param => check(query, param))
    : check(query, params);

/**
 * A hook to compare query param to the appropriate value it was defined for
 * (e.g. `'true'`, `'false'`, `'1'`, `'0'`, or presence of the param).
 * @param {string|string[]} params query param name or list of query param names
 * @returns {boolean|boolean[]|undefined} comparison result.
 *  Returns a list of results if `params` is an array.
 *  Returns `undefined` if `useRouter` is not available.
 */
const useQueryCheck = params => {
  const router = useRouter();
  if (!router) return undefined;
  return queryCheck(router.query, params);
};

export default useQueryCheck;
