/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getKcTokenClientSide } from '@artemis/integrations/auth/utils';
import { logFailedRequest } from '@artemis/utils/log';
import { getAcceptLanguageHeader } from './util';

const createServerApiClient = ({ req }) => {
  const baseURL = `${process.env.RT_CLIENT_USER_GATEWAY_SERVER_BACKEND_URL}`;
  const apiClient = axios.create({ baseURL });

  apiClient.interceptors.request.use(config => {
    config.headers['Accept-Language'] = getAcceptLanguageHeader({ req });

    if (req?.accessToken) {
      config.headers.authorization = `Bearer ${req.accessToken}`;
    }

    return config;
  });

  apiClient.interceptors.response.use(
    () => {},
    err => {
      logFailedRequest({ logger: req.logger, err });
    },
  );

  return apiClient;
};

const createBrowserApiClient = () => {
  const baseURL = `${process.env.RT_CLIENT_USER_GATEWAY_BROWSER_BACKEND_URL}`;

  const apiClient = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 'Accept-Language': getAcceptLanguageHeader() },
  });

  apiClient.interceptors.request.use(async config => {
    try {
      const accessToken = getKcTokenClientSide();
      if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    } catch {
      // intentionally empty
    }
    return config;
  });

  return apiClient;
};

export const createClientUserGatewayApiClient = ({ isServer, req, res }) => {
  if (isServer) {
    return createServerApiClient({ req, res });
  }
  return createBrowserApiClient();
};

export const getRedeemPointsPreview = async (
  apiClient,
  { externalUserId, pointsToRedeem, currency },
) =>
  apiClient.get(
    `/consumer-user/${externalUserId}/balances/redeem-points-preview`,
    {
      params: {
        pointsToRedeem,
        currency,
      },
    },
  );

export const redeemPoints = async (
  apiClient,
  { externalUserId, pointsToRedeem, currency },
) =>
  apiClient.post(
    `/consumer-user/${externalUserId}/balances/redeem-points`,
    null,
    {
      params: {
        pointsToRedeem,
        currency,
      },
    },
  );

export default createClientUserGatewayApiClient;
