/* eslint import/prefer-default-export: 0 */
import { css } from 'styled-components';

export const breakpoints = {
  mobileMax: 767,
  tabletMax: 1024,
};

export const isTablet = (...args) => css`
  @supports (display: grid) {
    @media (min-width: ${breakpoints.mobileMax}px) {
      ${css(...args)}
    }
  }
`;

export const isDesktop = (...args) => css`
  @supports (display: grid) {
    @media (min-width: ${breakpoints.tabletMax}px) {
      ${css(...args)}
    }
  }
`;
