import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  search: {
    isLoading: false,
    data: null,
    error: null,
  },
  teamById: {
    isLoading: false,
    data: null,
    error: null,
    initialized: false,
    isModalOpen: false,
  },
  create: {
    isLoading: false,
    error: null,
  },
  join: {
    isLoading: false,
    error: null,
  },
  leave: {
    isLoading: false,
    isModalOpen: false,
  },
  addLocation: {
    isLoading: false,
    isModalOpen: false,
    error: null,
  },
  sendVerificationEmail: {
    isLoading: false,
    error: null,
  },
  verificationStatus: {
    isLoading: false,
    error: null,
    data: null,
  },
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    loadTeamById: state => {
      state.teamById.isLoading = true;
    },
    loadTeamByIdSuccess: (state, action) => {
      state.teamById.isLoading = false;
      state.teamById.initialized = true;
      state.teamById.data = action.payload;
    },
    loadTeamByIdError: (state, action) => {
      state.teamById.isLoading = false;
      state.teamById.initialized = true;
      state.teamById.error = action.payload;
    },
    openMyTeamModal: state => {
      state.teamById.isModalOpen = true;
    },
    closeMyTeamModal: state => {
      state.teamById.isModalOpen = false;
    },
    searchTeams: state => {
      state.search.isLoading = true;
      state.search.error = null;
    },
    searchTeamsSuccess: (state, action) => {
      state.search.isLoading = false;
      state.search.data = action.payload;
    },
    searchTeamsError: (state, action) => {
      state.search.isLoading = false;
      state.search.error = action.payload.error;
    },
    createTeam: state => {
      state.create.isLoading = true;
      state.create.error = null;
    },
    createTeamSuccess: (state, action) => {
      state.create.isLoading = false;
      state.teamById.data = action.payload.data;
    },
    createTeamError: (state, action) => {
      state.create.isLoading = false;
      state.create.error = action.payload.error;
    },
    joinTeam: state => {
      state.join.isLoading = true;
    },
    joinTeamSuccess: (state, action) => {
      // Do not clear loading state while UI redirects to another page
      state.teamById.data = action.payload.data;
    },
    joinTeamError: (state, action) => {
      state.join.isLoading = false;
      state.join.error = action.payload.error;
    },
    setLeaveTeamModalOpen: (state, action) => {
      state.leave.isModalOpen = action.payload;
    },
    leaveTeam: state => {
      state.leave.isLoading = true;
    },
    leaveTeamSuccess: state => {
      state.leave.isLoading = false;
      state.leave.isModalOpen = false;
      state.teamById.isModalOpen = false;
    },
    leaveTeamError: state => {
      state.leave.isLoading = false;
      state.leave.isModalOpen = false;
    },
    setAddTeamLocationModalOpen: (state, action) => {
      state.addLocation.isModalOpen = action.payload;
    },
    addTeamLocation: state => {
      state.addLocation.isLoading = true;
    },
    addTeamLocationSuccess: state => {
      state.addLocation.isLoading = false;
      state.addLocation.isModalOpen = false;
    },
    addTeamLocationError: (state, action) => {
      state.addLocation.isLoading = false;
      state.addLocation.error = action.payload.error;
    },
    sendVerificationEmail: state => {
      state.sendVerificationEmail.isLoading = true;
      state.sendVerificationEmail.error = null;
    },
    sendVerificationEmailSuccess: (state, action) => {
      state.sendVerificationEmail.isLoading = false;
      state.verificationStatus.data = action.payload.data;
    },
    sendVerificationEmailError: (state, action) => {
      state.sendVerificationEmail.isLoading = false;
      state.sendVerificationEmail.error = action.payload.error;
    },
    loadVerificationStatus: state => {
      state.verificationStatus.isLoading = true;
      state.verificationStatus.error = null;
    },
    loadVerificationStatusSuccess: (state, action) => {
      state.verificationStatus.isLoading = false;
      state.verificationStatus.data = action.payload.data;
    },
    loadVerificationStatusError: (state, action) => {
      state.verificationStatus.isLoading = false;
      state.verificationStatus.error = action.payload.error;
    },
  },
});

export const {
  closeMyTeamModal,
  loadTeamById,
  loadTeamByIdSuccess,
  loadTeamByIdError,
  openMyTeamModal,
  searchTeams,
  searchTeamsSuccess,
  searchTeamsError,
  createTeam,
  createTeamSuccess,
  createTeamError,
  joinTeam,
  joinTeamSuccess,
  joinTeamError,
  setLeaveTeamModalOpen,
  leaveTeam,
  leaveTeamSuccess,
  leaveTeamError,
  setAddTeamLocationModalOpen,
  addTeamLocation,
  addTeamLocationSuccess,
  addTeamLocationError,
  sendVerificationEmail,
  sendVerificationEmailSuccess,
  sendVerificationEmailError,
  loadVerificationStatus,
  loadVerificationStatusSuccess,
  loadVerificationStatusError,
} = teamsSlice.actions;

export default teamsSlice.reducer;
