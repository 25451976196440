import {
  KEYCLOAK_TOKEN_COOKIE,
  KEYCLOAK_REFRESH_TOKEN_COOKIE,
  MAX_TOKEN_COOKIE_SIZE,
  KEYCLOAK_ANONYMOUS_TOKEN_COOKIE,
} from '@artemis/integrations/auth/constants';
import { parse, serialize } from 'cookie';
import {
  getPublicServiceClientId,
  getPublicServiceClientSecret,
} from '@artemis/store/organization/selectors';

import {
  refreshAccessToken,
  getAnonymousAccessToken,
} from '@artemis/api/keycloak';
import { SOURCE_ID_HEADER } from '@artemis/utils/constants';
import { addCookiesToResponse } from '@artemis/utils/cookie';
import Cookies from '@artemis/utils/cookies';
import { EMBED_PARAM } from '@artemis/utils/query/constants';
import {
  getSessionItem,
  removeSessionItem,
  setSessionItem,
} from '@artemis/utils/sessionStorage';
import { getIntegrationTypeFromEmbedValue } from '@artemis/store/embed/utils';
import { EMBED_INTEGRATION_TYPE } from '../embed/constants';

// Keycloak attributes can have the same name, so they are returned as a list.
// We will only ever have 1 usage of each key.
const flattenAttributes = attributes => {
  if (!attributes) return {};
  return Object.entries(attributes).reduce((result, entry) => {
    // eslint-disable-next-line
    result[entry[0]] = entry[1][0];
    return result;
  }, {});
};

export const formatUserProfile = keycloakProfile => ({
  ...keycloakProfile,
  ...flattenAttributes(keycloakProfile?.attributes),
});

/**
 * Verifies the token is not expired, malformed, or incorrectly signed
 */
const isTokenValid = async ({ keycloakConfig, token }) => {
  // Remove "/auth" from end of auth-server-url
  const authServerUrl = keycloakConfig['auth-server-url'].replace(
    /\/auth$/,
    '',
  );

  try {
    const keycloakBackend = (await import('keycloak-backend')).default;
    await keycloakBackend({
      ...keycloakConfig,
      'auth-server-url': authServerUrl,
    }).jwt.verify(token);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Gets an access token for the given request.
 *
 * The current access token from the "kcToken" cookie will be used if it is valid.
 * Otherwise it will try to use the refresh token to get a new token.
 */
export const getOrRefreshAccessToken = async ({
  req,
  res,
  query = {},
  organizationConfig,
}) => {
  const cookies = parse(req.headers.cookie || '');

  const keycloakConfig = getKeycloakConfig({
    isEmbed: EMBED_PARAM in query,
    organizationId: req.headers[SOURCE_ID_HEADER],
    integrationType: getIntegrationTypeFromEmbedValue(query?.[EMBED_PARAM]),
    organizationClientId: organizationConfig?.[0]?.webClientId,
  });

  // Check for a valid access token
  if (cookies[KEYCLOAK_TOKEN_COOKIE]) {
    const token = cookies[KEYCLOAK_TOKEN_COOKIE];
    const tokenValid = await isTokenValid({ keycloakConfig, token });
    if (tokenValid) {
      return token;
    }
    // Clear invalid token
    addCookiesToResponse({
      res,
      cookies: [serialize(KEYCLOAK_TOKEN_COOKIE, '', { path: '/' })],
    });
  }

  // If token was not valid but we have a refresh token, try to get a new access token
  if (cookies[KEYCLOAK_REFRESH_TOKEN_COOKIE]) {
    const refreshToken = cookies[KEYCLOAK_REFRESH_TOKEN_COOKIE];
    const token = await refreshAccessToken({
      refreshToken,
      clientId: keycloakConfig.clientId,
    });

    if (token) {
      if (token.length <= MAX_TOKEN_COOKIE_SIZE) {
        addCookiesToResponse({
          res,
          cookies: [
            serialize(KEYCLOAK_TOKEN_COOKIE, token, {
              path: '/',
              encode: v => v,
            }),
          ],
        });
      }
      return token;
    }
  }

  return null;
};

const fetchAnonymousAccessToken = async ({ store } = {}) => {
  let clientParams = {};
  if (store) {
    const clientId = getPublicServiceClientId(store?.getState());
    const clientSecret = getPublicServiceClientSecret(store?.getState());

    clientParams = {
      ...(clientId && { clientId }),
      ...(clientSecret && { clientSecret }),
    };
  }

  const { token, expiresIn } = await getAnonymousAccessToken(clientParams);
  return { token, expiresIn };
};

export const getOrFetchAnonymousAccessToken = async ({ req, store } = {}) => {
  if (!req) {
    const storedToken = getAnonymousTokenFromSessionStorage();
    if (storedToken) {
      return storedToken;
    }

    const { token, expiresIn } = await fetchAnonymousAccessToken({ store });
    setAnonymousTokenClientSide({ token, expiresIn });
    return token;
  }

  const { token } = await fetchAnonymousAccessToken({ store });
  return token;
};

export const getKcTokenClientSide = () => {
  const token = Cookies.get(KEYCLOAK_TOKEN_COOKIE);
  if (token) {
    return token;
  }
  return getSessionItem(KEYCLOAK_TOKEN_COOKIE, true);
};

const getExpiryDateForTokenCookie = expiresIn => {
  const expireDate = new Date();
  expireDate.setSeconds(expireDate.getSeconds() + expiresIn);
  return expireDate;
};

export const getKeycloakClientId = ({
  isEmbed,
  integrationType,
  organizationId,
  organizationClientId,
}) => {
  const isHqO =
    organizationId === process.env.RT_HQO_ORGANIZATION_ID ||
    integrationType === EMBED_INTEGRATION_TYPE.HQO;

  if (isEmbed && isHqO) {
    return process.env.RT_KEYCLOAK_HQO_CLIENT_ID;
  }

  return organizationClientId || process.env.RT_KEYCLOAK_CONFIG.clientId;
};

export const getKeycloakConfig = ({
  isEmbed,
  integrationType,
  organizationId,
  organizationClientId,
}) => {
  const clientId = getKeycloakClientId({
    isEmbed,
    integrationType,
    organizationId,
    organizationClientId,
  });

  return {
    ...process.env.RT_KEYCLOAK_CONFIG,
    clientId,
    resource: clientId,
  };
};

export const setAnonymousTokenClientSide = ({ token, expiresIn }) => {
  if (token && expiresIn) {
    const expiryDate = getExpiryDateForTokenCookie(expiresIn);
    const expiryTime = expiryDate.getTime();
    setSessionItem(KEYCLOAK_ANONYMOUS_TOKEN_COOKIE, { token, expiryTime });
  }
};

export const getAnonymousTokenFromSessionStorage = () => {
  const data = getSessionItem(KEYCLOAK_ANONYMOUS_TOKEN_COOKIE);
  if (!data) {
    return null;
  }
  const { token, expiryTime } = data;
  const currentTime = new Date().getTime();

  if (currentTime >= expiryTime) {
    removeSessionItem(KEYCLOAK_ANONYMOUS_TOKEN_COOKIE);
    return null;
  }

  return token;
};
