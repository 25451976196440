import React from 'react';
import Proptypes from 'prop-types';
import AuthenticationContext from './AuthenticationContext';
import useAuth from './useAuth';

const AuthenticationContextWrapper = ({ children }) => {
  const authData = useAuth();
  return (
    <AuthenticationContext.Provider value={authData}>
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationContextWrapper.propTypes = {
  children: Proptypes.element,
};

export default AuthenticationContextWrapper;
