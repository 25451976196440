export const handleStripePaymentChallenge = async ({
  apiKey,
  clientSecret,
}) => {
  const stripeKey = apiKey || process.env.RT_STRIPE_KEY;
  const { loadStripe } = await import('@stripe/stripe-js');
  const stripe = await loadStripe(stripeKey);

  const result = await stripe.handleCardAction(clientSecret);
  const { paymentIntent, error } = result;
  if (error) {
    throw new Error(error.message);
  }

  return {
    stripeId: paymentIntent.id,
  };
};
