import { css } from 'styled-components';

const utils = {
  // https://coderwall.com/p/i817wa/one-line-function-to-detect-mobile-devices-with-javascript
  isTouchDevice: args => {
    const ret =
      typeof window !== 'undefined' &&
      'ontouchstart' in window &&
      css`
        ${css(...args)}
      `;
    return ret;
  },
};

export default utils;
