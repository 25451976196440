import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { verifyWorkspaceEmail } from '@artemis/api/contentGateway';
import {
  loadVerifyEmail,
  loadVerifyEmailSuccess,
  loadVerifyEmailError,
} from './slice';

export function* loadVerifyEmailSaga(action) {
  const {
    payload: { emailCode, promoCode },
  } = action;
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(verifyWorkspaceEmail, apiClient, {
      emailCode,
      promoCode,
    });
    yield put(loadVerifyEmailSuccess(data));
  } catch (err) {
    yield put(loadVerifyEmailError(err));
  }
}

export default function* verifyEmailData() {
  yield takeLatest(loadVerifyEmail.type, loadVerifyEmailSaga);
}
