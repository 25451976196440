import { createSelector } from 'reselect';
import { EMBEDDED_PAYMENT_METHODS } from '@artemis/integrations/embed/webviewHooks/constants';
import {
  PAYMENT_OPTION_TYPE,
  PAYMENT_PROVIDER,
  PAYMENT_METHOD_SOURCE,
} from '@artemis/utils/constants';
import { EMBED_INTEGRATION_TYPE } from '@artemis/integrations/embed/constants';
import { getIsActiveOrganizationHqO } from '../organization/selectors';

const getEmbedRoot = state => state.embed;

export const getIsEmbedded = createSelector(
  getEmbedRoot,
  embed => embed.isEmbedded,
);

export const getIntegrationType = createSelector(
  getEmbedRoot,
  embed => embed.integrationType,
);

const getPaymentMethodsRoot = createSelector(
  getEmbedRoot,
  embed => embed.paymentMethods,
);

export const getIsEmbeddedPaymentMethodsLoading = createSelector(
  getPaymentMethodsRoot,
  paymentMethods => paymentMethods?.loading,
);

const getPaymentRoot = createSelector(getEmbedRoot, embed => embed.payment);

export const getSupportedPaymentMethods = createSelector(
  getIsEmbedded,
  getPaymentMethodsRoot,
  (isEmbedded, paymentMethods) => {
    if (!isEmbedded) {
      return [];
    }

    return paymentMethods.supportedMethods || [];
  },
);

export const getEmbeddedPaymentMethodsInitialized = createSelector(
  getPaymentMethodsRoot,
  getIsEmbedded,
  (paymentMethods, isEmbedded) => paymentMethods.initialized || !isEmbedded,
);

export const getSupportsApplePay = createSelector(
  getSupportedPaymentMethods,
  paymentMethods => paymentMethods.includes(EMBEDDED_PAYMENT_METHODS.APPLE_PAY),
);

export const getSupportsGooglePay = createSelector(
  getSupportedPaymentMethods,
  paymentMethods =>
    paymentMethods.includes(EMBEDDED_PAYMENT_METHODS.GOOGLE_PAY),
);

export const getSupportsInAppStoredPayment = createSelector(
  getSupportedPaymentMethods,
  paymentMethods =>
    paymentMethods.includes(EMBEDDED_PAYMENT_METHODS.IN_APP_STORED_PAYMENT),
);

export const getSupportsAnyEmbeddedPaymentMethods = createSelector(
  getSupportedPaymentMethods,
  paymentMethods => paymentMethods.length > 0,
);

export const getEmbeddedStoredCards = createSelector(
  getPaymentMethodsRoot,
  payment =>
    (payment.storedCards || []).map(card => ({
      paymentMethodIdentifier: {
        paymentProvider: PAYMENT_PROVIDER.STRIPE,
        paymentOptionType: PAYMENT_OPTION_TYPE.CREDIT_CARD_VAULTED,
        paymentOptionId: card.token_id,
        paymentMethodSource: PAYMENT_METHOD_SOURCE.HQO,
      },
      token: '',
      paymentMethodDisplayDetails: {
        fromEmbeddedIntegration: true,
        brand: card?.card_brand?.toLowerCase(), // eslint-disable-line camelcase
        last4: card?.card_last_four, // eslint-disable-line camelcase
      },
      selected: false,
    })),
);

export const getIsPaymentLoading = createSelector(
  getPaymentRoot,
  payment => payment.loading,
);

export const getIsDefaultEmbed = createSelector(
  getIsEmbedded,
  getIntegrationType,
  (isEmbedded, integrationType) =>
    isEmbedded && integrationType === EMBED_INTEGRATION_TYPE.DEFAULT,
);

export const getIsHqO = createSelector(
  getIsActiveOrganizationHqO,
  getIntegrationType,
  (isOrgHqO, integrationType) =>
    isOrgHqO || integrationType === EMBED_INTEGRATION_TYPE.HQO,
);

export const getIsEmbeddedHqO = createSelector(
  getIsEmbedded,
  getIsHqO,
  (isEmbedded, isHqO) => isEmbedded && isHqO,
);

const getHqORoot = createSelector(getEmbedRoot, embed => embed?.hqo);

export const getHqOSessionToken = createSelector(
  getHqORoot,
  hqo => hqo?.initData?.session_token || null, // eslint-disable-line camelcase
);

export const getHqOUser = createSelector(
  getHqORoot,
  hqo => hqo?.initData?.user || null,
);

export const getHqOBuilding = createSelector(
  getHqORoot,
  hqo => hqo?.initData?.building || null,
);

export const getShouldUseOTPLoginFlow = createSelector(
  getIsEmbeddedHqO,
  isEmbeddedHqO => isEmbeddedHqO,
);

// Returns extra query params to add to login flow to trigger special HqO auth flow
export const getAdditionalOTPLoginParams = createSelector(
  getIsEmbeddedHqO,
  getHqOUser,
  getHqOBuilding,
  (isEmbeddedHqO, hqoUser) => {
    if (isEmbeddedHqO && hqoUser) {
      const { first_name, last_name, email } = hqoUser; // eslint-disable-line camelcase,no-unused-vars
      return {
        first_name,
        last_name,
        email,
        // TODO: update this when HqO SDK returns phone number
        // phone_country: 'CA',
        // phone_calling: '+1',
        // phone_local: '6474571234',
      };
    }

    return {};
  },
);
