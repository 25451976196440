import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isFetching: false,
  isUpdating: false,
  data: null,
  fetchError: null,
  updateError: null,
  listModal: {
    isOpen: false,
    isSelectable: false,
  },
  detailsModal: {
    isOpen: false,
    incentiveId: null,
  },
};

const incentivesSlice = createSlice({
  name: 'incentives',
  initialState,
  reducers: {
    loadIncentives: state => {
      state.isFetching = true;
    },
    loadIncentivesSuccess: (state, action) => {
      state.isFetching = false;
      state.data = action.payload;
    },
    loadIncentivesError: (state, action) => {
      state.isFetching = false;
      state.fetchError = action.payload;
    },
    updateIncentives: state => {
      state.isUpdating = true;
    },
    updateIncentivesSuccess: state => {
      state.isUpdating = false;
    },
    updateIncentivesError: (state, action) => {
      state.isUpdating = false;
      state.updateError = action.payload;
    },
    toggleListModal: (state, action) => {
      state.listModal.isOpen = action.payload.isOpen;
      state.listModal.isSelectable = action.payload.isSelectable ?? false;
    },
    toggleDetailsModal: (state, action) => {
      state.detailsModal.isOpen = action.payload.isOpen;
      state.detailsModal.incentiveId = action.payload.incentiveId;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.incentives);
  },
});

export const {
  loadIncentives,
  loadIncentivesSuccess,
  loadIncentivesError,
  updateIncentives,
  updateIncentivesSuccess,
  updateIncentivesError,
  toggleListModal,
  toggleDetailsModal,
} = incentivesSlice.actions;

export default incentivesSlice.reducer;
