import { KEYCLOAK_TOKEN_COOKIE } from '@artemis/integrations/auth/constants';

export const SESSION_KEY = {
  GUEST_ADDRESS_DETAILS: 'guest-address-details',
  PROMO_PAGE_SEARCH: 'promopage-search',
  KEYCLOAK_TOKEN: KEYCLOAK_TOKEN_COOKIE,
};

export const getSessionItem = (key, raw = false) => {
  const value = window?.sessionStorage?.getItem(key);
  if (value) {
    return raw ? value : JSON.parse(value);
  }
  return null;
};

export const setSessionItem = (key, value, raw = false) =>
  window?.sessionStorage?.setItem(key, raw ? value : JSON.stringify(value));

export const removeSessionItem = key => window?.sessionStorage?.removeItem(key);
