import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const deliverySlice = createSlice({
  name: 'delivery',
  initialState,
  reducers: {
    loadDelivery: state => {
      state.isLoading = true;
    },
    loadDeliverySuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadDeliveryError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loadDelivery, loadDeliverySuccess, loadDeliveryError } =
  deliverySlice.actions;

export default deliverySlice.reducer;
