export const MERCHANT_AUTH_MODE = {
  DEFAULT: 'DEFAULT',
  CHECKOUT_OTP: 'CHECKOUT_OTP',
};

export const MERCHANT_DISPLAY_MODE = {
  DEFAULT: 'DEFAULT',
  DINE_IN: 'DINE_IN',
  DELIVERY: 'DELIVERY',
  ORDER_AHEAD: 'ORDER_AHEAD',
};

export const FULFILLMENT_TYPE = {
  IN_STORE: 'IN_STORE',
  DELIVERY: 'DELIVERY',
  DINE_IN: 'DINE_IN',
};

export const PLACEMENT_TYPE = {
  DEFAULT: 'DEFAULT',
  IN_STORE: 'IN_STORE',
};

export const PAYMENT_OPTION_TYPE = {
  UNKNOWN_TYPE: 'UNKNOWN_TYPE',
  CREDIT_CARD_VAULTED: 'CREDIT_CARD_VAULTED',
  CREDIT_CARD_ONE_TIME: 'CREDIT_CARD_ONE_TIME',
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY',
  PAY_PAL: 'PAY_PAL',
  INVOICE: 'INVOICE',
};

export const PAYMENT_METHOD_SOURCE = {
  HQO: 'HQO',
};

export const EXPRESS_PAYMENT_BRAND = {
  UNKNOWN: 'UNKNOWN',
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY',
  PAY_PAL: 'PAY_PAL',
  VENMO: 'VENMO',
};

export const PAYMENT_IMAGE = {
  AMEX: 'american express', // Stripe name
  MASTERCARD: 'mastercard',
  VISA: 'visa',
  PAY_PAL: 'paypal',
  GOOGLE_PAY: 'googlePay',
  APPLE_PAY: 'applePay',
  VENMO: 'venmo',
};

export const PAYMENT_PROVIDER = {
  UNKNOWN_PROCESSOR: 'UNKNOWN_PROCESSOR',
  STRIPE: 'STRIPE',
  RITUAL: 'RITUAL',
};

export const PAYMENT_ENTITY_TYPE = {
  PAYMENT_OPTION: 'PAYMENT_OPTION',
  ACCOUNT: 'ACCOUNT',
};

export const INTERVALS = {
  MINUTE_IN_MILLISECONDS: 60000,
};

export const CLAIM_CHECKOUTCODE = {
  ny: 'NYOPEN4BUSINESS',
  la: 'LAOPEN4BUSINESS',
  brookfield: 'PAYPALNYPM',
  westfield: 'PAYPALNYPM',
  'tishman-speyer': 'PAYPALNYPM',
  dps: 'PAYPALDPS', // dps campaign
};

export const ERROR_MODAL = {
  INELIGIBLE_PROMOTION: 'INELIGIBLE_PROMOTION',
  INVALID_SCHEDULED_FOR_TIME: 'INVALID_SCHEDULED_FOR_TIME',
  SCHEDULED_ORDER_ITEM_PREP_TIME: 'SCHEDULED_ORDER_ITEM_PREP_TIME',
  UNAVAILABLE_SCHEDULED_ORDER_ITEM: 'UNAVAILABLE_SCHEDULED_ORDER_ITEM',
  INCENTIVES_INVALID: 'INCENTIVES_INVALID',
  GROUP_ORDER_APP_ONLY: 'GROUP_ORDER_APP_ONLY',
};

export const ORDER_SOURCE = {
  RITUAL_APP: 'RITUAL_APP',
  RITUAL_ONE: 'RITUAL_ONE',
  ORGANIZATION: 'ORGANIZATION',
  GOOGLE_FOOD: 'GOOGLE_FOOD',
};

export const ORDER_SOURCE_HEADER = 'x-ritual-order-source';
export const SOURCE_ID_HEADER = 'x-ritual-order-source-id';
export const SOURCE_SUBDOMAIN_HEADER = 'x-ritual-order-source-subdomain';
export const SOURCE_SUBDOMAIN_ID_HEADER = 'x-ritual-order-source-subdomain-id';

export const MODAL_SIZES = {
  xs: '320px',
  sm: '384px',
  md: '448px',
  lg: '512px',
  xl: '576px',
  '2xl': '672px',
  '3xl': '768px',
  '4xl': '896px',
  '5xl': '1024px',
  '6xl': '1152px',
  full: '100%',
};

export const PAYMENT_SPLIT_TYPE = {
  PAY_SEPARATELY: 'PAY_SEPARATELY',
  FIXED_AMOUNT: 'FIXED_AMOUNT',
};

export const FULFILLMENT_STATE = {
  CREATED: 'CREATED',
  OPEN: 'OPEN',
  SCHEDULED: 'SCHEDULED',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
  EXPIRED: 'EXPIRED',
};

export const FULFILLMENT_GUEST_STATUS = {
  BROWSING: 'BROWSING',
  READY: 'READY',
};

export const CLAIM_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const FULFILLMENT_VISIBILITY = {
  PUBLIC: 'PUBLIC',
};

export const FULFILLMENT_FLOW_TYPE = {
  ADMIN_OPTIONAL_GUEST: 'ADMIN_OPTIONAL_GUEST',
  HOST_ALWAYS_GUEST: 'HOST_ALWAYS_GUEST',
};

export const INVITE_ENTITY_TYPE = {
  FULFILLMENT: 'FULFILLMENT',
  PRIVATE_PIGGYBACK: 'PRIVATE_PIGGYBACK',
};

export const CUSTOM_TIP_RANGE = {
  MIN: 0,
  MAX: 200,
};

export const PERK_TYPE = {
  AMOUNT_OFF: 'AMOUNT_OFF',
  PERCENT_OFF: 'PERCENT_OFF',
  PERCENT_OFF_BASE: 'PERCENT_OFF_BASE',
  FIXED_PRICE: 'FIXED_PRICE',
  FIXED_PRICE_BASE: 'FIXED_PRICE_BASE',
};

export const PERK_DISPLAY_HINT = {
  GENERIC_DISCOUNT: 'GENERIC_DISCOUNT',
  EMPLOYEE_PRICING: 'EMPLOYEE_PRICING',
};

export const ACTIVE_ORDER_ENTITY_TYPE = {
  CART: 'CART',
  ORDER: 'ORDER',
  FULFILLMENT: 'FULFILLMENT',
};

export const MARKETING_CAMPAIGN_TYPE = {
  GENERIC: 'GENERIC',
  EMPLOYEE_PRICING: 'EMPLOYEE_PRICING',
};

export const OS_TYPE = {
  IOS: 'iOS',
  ANDROID: 'Android',
};
