import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
  addTeamLocation as postTeamLocation,
  checkEmailVerificationStatus,
  getTeamDetails,
  createTeam as postTeam,
  joinTeam as postJoinTeam,
  leaveTeam as postLeaveTeam,
  searchTeams as getSearchTeams,
  sendVerificationEmail as postSendVerificationEmail,
} from '@artemis/api/contentGateway';
import {
  loadTeamById,
  loadTeamByIdSuccess,
  loadTeamByIdError,
  searchTeams,
  searchTeamsSuccess,
  searchTeamsError,
  joinTeamSuccess,
  createTeam,
  createTeamSuccess,
  createTeamError,
  joinTeam,
  joinTeamError,
  leaveTeam,
  leaveTeamError,
  leaveTeamSuccess,
  addTeamLocation,
  addTeamLocationSuccess,
  addTeamLocationError,
  sendVerificationEmail,
  sendVerificationEmailSuccess,
  sendVerificationEmailError,
  loadVerificationStatus,
  loadVerificationStatusError,
  loadVerificationStatusSuccess,
} from './slice';

export function* loadTeamByIdSaga({ payload: teamId }) {
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(getTeamDetails, apiClient, { teamId });
    yield put(loadTeamByIdSuccess(data));
  } catch (err) {
    yield put(loadTeamByIdError(err));
  }
}

export function* searchTeamsSaga(action) {
  const {
    payload: { name, limit },
  } = action;

  // Clear search results if name is empty
  if (!name) {
    yield put(searchTeamsSuccess({ results: [] }));
    return;
  }

  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(getSearchTeams, apiClient, {
      name,
      limit,
    });
    yield put(searchTeamsSuccess(data));
  } catch (error) {
    yield put(searchTeamsError({ error }));
  }
}

export function* createTeamSaga({ payload }) {
  try {
    const { name, googlePlaceId, email, onSuccess } = payload;
    const apiClient = yield getContext('contentGatewayApiClient');

    const { data } = yield call(postTeam, apiClient, {
      name,
      googlePlaceId,
      workEmail: email,
    });
    yield put(createTeamSuccess({ data }));
    if (onSuccess) {
      onSuccess(data);
    }
  } catch (error) {
    yield put(createTeamError({ error }));
  }
}

export function* joinTeamSaga({ payload }) {
  try {
    const { teamId, locationId, floorId, onSuccess } = payload;
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(postJoinTeam, apiClient, {
      teamId,
      locationId,
      floorId,
    });
    yield put(joinTeamSuccess({ data }));
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    yield put(joinTeamError({ error }));
  }
}

export function* leaveTeamSaga({ payload: teamId }) {
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    yield call(postLeaveTeam, apiClient, { teamId });
    yield put(leaveTeamSuccess());
  } catch (error) {
    yield put(leaveTeamError({ error }));
  }
}

export function* addTeamLocationSaga({ payload }) {
  try {
    const { teamId, googlePlaceId, workEmail, onSuccess } = payload;

    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(postTeamLocation, apiClient, {
      teamId,
      googlePlaceId,
      workEmail,
    });

    // Reload team details to get updated list of locations
    yield put(loadTeamById(teamId));

    if (onSuccess) {
      onSuccess(data);
    }
    yield put(addTeamLocationSuccess({ data }));
  } catch (error) {
    yield put(addTeamLocationError({ error }));
  }
}

export function* sendVerificationEmailSaga({ payload }) {
  const {
    teamId,
    sourceType,
    subdomain,
    email,
    promoCode,
    onSuccess,
    onError,
  } = payload;
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(postSendVerificationEmail, apiClient, {
      teamId,
      sourceType,
      subdomain,
      email,
      promoCode,
    });
    yield put(sendVerificationEmailSuccess({ data }));

    if (onSuccess) {
      onSuccess(data);
    }
  } catch (err) {
    yield put(sendVerificationEmailError({ error: err }));
    if (onError) {
      onError(err);
    }
  }
}

export function* loadVerificationStatusSaga({ payload }) {
  try {
    const { email, onSuccess } = payload;
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(checkEmailVerificationStatus, apiClient, {
      workEmail: email,
    });
    yield put(loadVerificationStatusSuccess({ data }));

    if (onSuccess) {
      onSuccess(data);
    }
  } catch (error) {
    yield put(loadVerificationStatusError({ error }));
  }
}

export default function* teamsData() {
  yield takeLatest(loadTeamById.type, loadTeamByIdSaga);
  yield takeLatest(searchTeams.type, searchTeamsSaga);
  yield takeLatest(createTeam.type, createTeamSaga);
  yield takeLatest(joinTeam.type, joinTeamSaga);
  yield takeLatest(leaveTeam.type, leaveTeamSaga);
  yield takeLatest(addTeamLocation.type, addTeamLocationSaga);
  yield takeLatest(sendVerificationEmail.type, sendVerificationEmailSaga);
  yield takeLatest(loadVerificationStatus.type, loadVerificationStatusSaga);
}
