import { createSelector } from 'reselect';

const getOrganizationRoot = state => state.organization;

export const getActiveOrganizationId = createSelector(
  getOrganizationRoot,
  organization => organization.organizationId,
);
export const getIsOrganizationActive = createSelector(
  getActiveOrganizationId,
  id => !!id,
);

export const getIsActiveOrganizationHqO = createSelector(
  getActiveOrganizationId,
  id => id === process.env.RT_HQO_ORGANIZATION_ID,
);

export const getIsActiveOrganizationRitual = createSelector(
  getActiveOrganizationId,
  id => id === process.env.RT_RITUAL_ORGANIZATION_ID,
);

const getOrganizationConfig = createSelector(
  getOrganizationRoot,
  organization => organization?.config?.[0],
);

export const getPublicServiceClientId = createSelector(
  getOrganizationConfig,
  config => config?.publicServiceClientId || null,
);

export const getPublicServiceClientSecret = createSelector(
  getOrganizationConfig,
  config => config?.publicServiceClientSecret || null,
);

export const getOrganizationKeycloakClientId = createSelector(
  getOrganizationConfig,
  config => config?.webClientId || null,
);
