import { createSelector } from 'reselect';
import { formatCurrency, microToPoints } from '@artemis/utils/currency-format';
import { ORDER_STATES } from '@artemis/containers/StatusPage/stateMessage/constants';
import {
  getIsMerchantLoading,
  getMerchantId,
  getMerchantTimeZone,
  getUserStampsCollected,
} from '@artemis/store/merchant/selectors';
import {
  formatDate,
  parseDateString,
  FORMAT as TIME_FORMAT,
  getTzDifferenceInDays,
} from '@artemis/utils/time-format';
import {
  ACTIVE_ORDER_ENTITY_TYPE,
  FULFILLMENT_GUEST_STATUS,
  FULFILLMENT_STATE,
  FULFILLMENT_TYPE,
  ORDER_SOURCE,
  PLACEMENT_TYPE,
} from '@artemis/utils/constants';
import { getFulfillmentId } from '../groupOrder/selectors';

const { CART, ORDER, FULFILLMENT } = ACTIVE_ORDER_ENTITY_TYPE;

const getOrderRoot = state => state.order;

const getRawOrder = createSelector(getOrderRoot, order => order?.data);

export const getOrderLoading = createSelector(
  getOrderRoot,
  order => order?.isLoading,
);

export const getOrderError = createSelector(
  getOrderRoot,
  order => order?.error,
);

const getCurrencyCode = createSelector(
  getRawOrder,
  order => order?.currencyCode,
);

const getRemappedOrder = createSelector(
  getRawOrder,
  order =>
    order && {
      ...order,
      orderReceipt: order.orderReceipt && {
        ...order.orderReceipt,
        taxMicro: order.orderReceipt.taxMicro || 0,
        totalPayableMicro: order.orderReceipt.totalMicro,
        subtotalBeforeTaxMicro: order.orderReceipt.subtotalMicro,
      },
    },
);

const remapItemList = createSelector(
  getRemappedOrder,
  getCurrencyCode,
  (order, currencyCode) => {
    if (!order || !order.orderItems) {
      return [];
    }
    return order.orderItems.map(
      ({
        priceMicro: itemPriceMicro,
        originalPriceMicro: itemOriginalPriceMicro,
        itemOptions,
        perk,
        ...itemProperties
      }) => ({
        ...itemProperties,
        priceMicro: itemPriceMicro,
        price: formatCurrency({
          currencyCode,
          value: itemPriceMicro,
        }),
        originalPriceMicro: itemOriginalPriceMicro,
        originalPrice: formatCurrency({
          currencyCode,
          value: itemOriginalPriceMicro,
        }),
        ...(perk
          ? {
              perk: {
                ...perk,
                originalPrice: formatCurrency({
                  value: perk.originalPriceMicro,
                  currencyCode,
                }),
                savings: formatCurrency({
                  value: perk.savingsMicro,
                  currencyCode,
                }),
                discountedPrice: formatCurrency({
                  value: perk.discountedPriceMicro,
                  currencyCode,
                }),
              },
            }
          : {}),
        itemOptions: itemOptions.map(
          ({
            priceMicro: optionPriceMicro,
            originalPriceMicro: optionOriginalPriceMicro,
            ...optionProperties
          }) => ({
            ...optionProperties,
            priceMicro: optionPriceMicro,
            price: formatCurrency({
              currencyCode,
              value: optionPriceMicro,
            }),
            originalPriceMicro: optionOriginalPriceMicro,
            originalPrice: formatCurrency({
              currencyCode,
              value: optionOriginalPriceMicro,
            }),
          }),
        ),
      }),
    );
  },
);

export const remapReceipt = createSelector(
  getRemappedOrder,
  getCurrencyCode,
  (order, currencyCode) => {
    if (!order || !order.orderReceipt) {
      return null;
    }

    const {
      tipMicro,
      subtotalMicro,
      deliveryChargeMicro,
      taxMicro,
      serviceChargeMicro,
      creditMicro,
      totalPayableMicro,
      taxRates,
      serviceCharges,
      orderDiscounts,
    } = order.orderReceipt;

    return {
      ...order.orderReceipt,
      tip: formatCurrency({
        currencyCode,
        value: tipMicro,
      }),
      subtotal: formatCurrency({
        currencyCode,
        value: subtotalMicro,
      }),
      deliveryCharge: formatCurrency({
        currencyCode,
        value: deliveryChargeMicro,
      }),
      tax: formatCurrency({
        currencyCode,
        value: taxMicro,
      }),
      serviceCharge: formatCurrency({
        currencyCode,
        value: serviceChargeMicro,
      }),
      credit: formatCurrency({
        currencyCode,
        value: creditMicro,
      }),
      totalPayable: formatCurrency({
        currencyCode,
        value: totalPayableMicro,
      }),
      taxRates:
        taxRates &&
        taxRates.map(({ taxMicro: subtaxMicro, ...rest }) => ({
          ...rest,
          taxMicro: subtaxMicro,
          tax: formatCurrency({
            currencyCode,
            value: subtaxMicro,
          }),
        })),
      serviceCharges:
        serviceCharges &&
        serviceCharges.map(
          ({ serviceChargeMicro: subserviceChargeMicro, ...rest }) => ({
            ...rest,
            serviceChargeMicro: subserviceChargeMicro,
            serviceCharge: formatCurrency({
              currencyCode,
              value: subserviceChargeMicro,
            }),
          }),
        ),
      discounts:
        orderDiscounts &&
        orderDiscounts.map(item => {
          const { discountMicro, isDiscountAsPoints } = item;
          return {
            ...item,
            discount: formatCurrency({
              currencyCode,
              value: discountMicro,
            }),
            ...(isDiscountAsPoints && { points: microToPoints(discountMicro) }),
          };
        }),
    };
  },
);

export const getOrder = createSelector(
  getRemappedOrder,
  remapItemList,
  remapReceipt,
  (order, orderItems, orderReceipt) => {
    if (!order) {
      return null;
    }
    if (!orderReceipt || !orderItems) {
      return order;
    }
    return {
      ...order,
      orderReceipt,
      orderItems,
    };
  },
);

export const getOrderDeliveryDetails = createSelector(
  getOrder,
  order => order && order.userDeliveryAddressDetail,
);

export const getIsDeliveryOrder = createSelector(
  getOrder,
  order => order && order.merchantFulfillmentType === FULFILLMENT_TYPE.DELIVERY,
);

export const getIsDineInOrder = createSelector(
  getOrder,
  order => order && order.merchantFulfillmentType === FULFILLMENT_TYPE.DINE_IN,
);

export const getIsPickupOrder = createSelector(
  getOrder,
  order => order && order.merchantFulfillmentType === FULFILLMENT_TYPE.IN_STORE,
);

export const getIsInStorePlacement = createSelector(
  getOrder,
  order => order && order.placementType === PLACEMENT_TYPE.IN_STORE,
);

export const getOrderStatus = createSelector(
  getOrder,
  getIsDeliveryOrder,
  (order, isDelivery) => {
    if (!order?.orderStatus) {
      return {};
    }

    if (isDelivery) {
      const switchToDeliveryStatus = [
        ORDER_STATES.PREPARED,
        ORDER_STATES.IN_TRANSIT,
        ORDER_STATES.RECEIVED,
      ];

      if (switchToDeliveryStatus.includes(order.orderStatus.orderStatus)) {
        if (!order.orderDeliveryStatuses) {
          // Fall back to orderStatus if orderDeliveryStatuses is null
          return order.orderStatus;
        }
        const lastItemIndex = order.orderDeliveryStatuses.length - 1;
        if (order.orderDeliveryStatuses.length > 0) {
          if (
            order.orderDeliveryStatuses[lastItemIndex] ===
            ORDER_STATES.DELIVERY_CANCELED
          ) {
            return order.orderStatus;
          }
          return {
            ...order.orderStatus,
            orderStatus:
              order.orderDeliveryStatuses[lastItemIndex].deliveryStatus,
          };
        }
      }
    }

    return order.orderStatus;
  },
);

export const getIsOrderCancellable = createSelector(
  getOrderStatus,
  status => status && (status.cancellable || status.isCancellable),
);

export const getAcceptedTimestamp = createSelector(
  getOrderStatus,
  status => status && status.acceptTimestamp,
);

export const getOrderReadyAtTime = createSelector(
  getOrderStatus,
  status => status && status.orderReadyAtTime,
);

export const getRejectionList = createSelector(remapItemList, itemList =>
  itemList
    .map(item => ({
      ...item,
      itemOptions: item.itemOptions.filter(option => option.wasRejected),
    }))
    .filter(item => item.wasRejected || item.itemOptions.length > 0),
);

const getOrderScheduledForTime = createSelector(
  getOrderStatus,
  status => status && status.scheduledForTime,
);

export const getScheduledDateDaysAhead = createSelector(
  getOrderScheduledForTime,
  getMerchantTimeZone,
  (datetime, timeZone) => {
    if (!datetime) return null;
    return getTzDifferenceInDays({
      endDate: datetime,
      format: TIME_FORMAT.ISO_DATE_TIME,
      timeZone,
    });
  },
);

export const getScheduledForTime = createSelector(
  getOrderScheduledForTime,
  getMerchantTimeZone,
  getScheduledDateDaysAhead,
  (scheduledForTime, timeZone, scheduledDaysAhead) => {
    if (!scheduledForTime) return null;
    const dayObj = parseDateString(
      scheduledForTime,
      TIME_FORMAT.ISO_DATE_TIME,
      timeZone,
    );
    return {
      date: formatDate(dayObj, TIME_FORMAT.DAY_AND_MONTH),
      time: formatDate(dayObj, TIME_FORMAT.TIME),
      isToday: scheduledDaysAhead === 0,
      isTomorrow: scheduledDaysAhead === 1,
    };
  },
);

export const getOrderRejectionNote = createSelector(
  getOrder,
  order => order?.orderRejectionNote,
);

export const getOrderTicketNumber = createSelector(
  getOrder,
  order => order?.orderReceipt?.orderTicketNumber,
);

// Returns true if this order caused the user's stamp card to be completed
export const getIsStampCardCompletedByOrder = createSelector(
  getIsMerchantLoading,
  getOrder,
  getAcceptedTimestamp,
  getUserStampsCollected,
  (isMerchantLoading, order, acceptedTimestamp, userStampsCollected) => {
    // When merchants accept the order the stamps get added to the user's total stamps collected.
    // When a user completes a stamp card the stamp card total resets to 0.
    const stampsEarned = order?.orderReceipt?.loyaltyStampsCount || 0;
    const orderWasAccepted = !!acceptedTimestamp;

    return (
      stampsEarned > userStampsCollected &&
      orderWasAccepted &&
      !isMerchantLoading
    );
  },
);

export const getOrderFulfillmentId = createSelector(
  getOrder,
  order => order?.participatingFulfillmentId,
);

export const getSource = createSelector(
  getOrderRoot,
  orderRoot => orderRoot.source,
);

export const getOrderSource = createSelector(
  getSource,
  source => source.orderSource,
);

export const getOrderSourceId = createSelector(
  getSource,
  source => source.sourceId,
);

export const getOrderSourceOrigin = createSelector(
  getSource,
  source => source.origin || '',
);

export const getOrderSourceSubdomain = createSelector(
  getSource,
  source => source.subdomain,
);

export const getOrderSourceSubdomainId = createSelector(
  getSource,
  source => source.subdomainId,
);

export const getIsMarketplace = createSelector(
  getOrderSource,
  source => source === ORDER_SOURCE.RITUAL_APP,
);

export const getOrderCutleryIncluded = createSelector(
  getRawOrder,
  order => order?.includeCutlery,
);

export const getOrderCutlerySupported = createSelector(
  getRawOrder,
  order => order?.includeCutlery != null,
);

const getActiveOrdersRoot = createSelector(
  getOrderRoot,
  order => order?.activeOrders,
);

export const getActiveOrdersInitialized = createSelector(
  getActiveOrdersRoot,
  activeOrders => activeOrders?.initialized || false,
);

const getUnfilteredActiveOrders = createSelector(
  getActiveOrdersRoot,
  activeOrders => activeOrders?.data?.data || [],
);

const anyCartForMerchantPresent = (orders, merchantId) =>
  orders.some(
    order => order.entityType === CART && order.merchant.id === merchantId,
  );

export const getActiveOrders = createSelector(
  getUnfilteredActiveOrders,
  getFulfillmentId,
  (activeOrders, activeFulfillmentId) =>
    activeOrders.filter(order => {
      if (order.entityType === FULFILLMENT) {
        // Exclude fulfillments that are already on the shown on the page
        if (order.entityId === activeFulfillmentId) {
          return false;
        }

        const fulfillmentState = order.fulfillmentDetails?.fulfillmentState;
        const guestStatus = order?.fulfillmentDetails?.guestStatus;

        // Exclude "READY" fulfillments, since user can follow status from the "ORDER" entity
        if (guestStatus === FULFILLMENT_GUEST_STATUS.READY) {
          return false;
        }

        // Exclude BROWSING fulfillments if user already has cart (so they don't see two entries related to placing the order)
        if (
          guestStatus === FULFILLMENT_GUEST_STATUS.BROWSING &&
          anyCartForMerchantPresent(activeOrders, order.merchant.id)
        ) {
          return false;
        }

        // Exclude unavailable fulfillments unless user is host (too late for guests to order)
        const isUnavailable = [
          FULFILLMENT_STATE.CANCELLED,
          FULFILLMENT_STATE.CLOSED,
        ].includes(fulfillmentState);
        if (isUnavailable && !order.fulfillmentDetails?.isCurrentUserAdmin) {
          return false;
        }
      }

      return true;
    }),
);

export const hasActiveOrders = createSelector(
  getActiveOrders,
  activeOrders => activeOrders.length > 0,
);

export const getIsMyOrdersModalOpen = createSelector(
  getActiveOrdersRoot,
  activeOrders => activeOrders.modalOpen || false,
);

export const showOrderBarInCheckoutFlow = createSelector(
  getActiveOrders,
  activeOrders => {
    // Only show order bar if there are orders or fulfillments
    const anyRelevantEntriesPresent = activeOrders.some(order =>
      [FULFILLMENT, ORDER].includes(order.entityType),
    );
    if (!anyRelevantEntriesPresent) {
      return false;
    }

    return activeOrders.length > 0;
  },
);

const isOrderForCurrentMerchant = (activeOrder, merchantId) => {
  const matchesMerchant = merchantId === activeOrder.merchant.id;
  const isOrder = activeOrder.entityType === ORDER;
  return matchesMerchant && isOrder;
};

export const showOrderBarOnStatusPage = createSelector(
  getActiveOrders,
  getMerchantId,
  (activeOrders, merchantId) => {
    // Do not show order bar if there is just one entry for the current order
    if (activeOrders.length === 1) {
      return !isOrderForCurrentMerchant(activeOrders[0], merchantId);
    }

    return activeOrders.length > 0;
  },
);
