/* eslint-disable no-param-reassign */
import axios from 'axios';
import {
  getKcTokenClientSide,
  getOrFetchAnonymousAccessToken,
} from '@artemis/integrations/auth/utils';
import { logFailedRequest } from '@artemis/utils/log';
import { getAcceptLanguageHeader } from './util';

const createServerApiClient = ({ req, store }) => {
  const baseURL = process.env.RT_INVITES_SERVER_BACKEND_URL;
  const apiClient = axios.create({ baseURL });

  apiClient.interceptors.request.use(
    async config => {
      config.headers['Accept-Language'] = getAcceptLanguageHeader({ req });

      if (req?.accessToken) {
        config.headers.authorization = `Bearer ${req.accessToken}`;
      } else {
        const token = await getOrFetchAnonymousAccessToken({ req, store });
        config.headers.authorization = `Bearer ${token}`;
      }

      return config;
    },
    err => {
      logFailedRequest({ logger: req.logger, err });
    },
  );

  return apiClient;
};

const createBrowserApiClient = ({ store }) => {
  const baseURL = process.env.RT_INVITES_BROWSER_BACKEND_URL;

  const apiClient = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 'Accept-Language': getAcceptLanguageHeader() },
  });

  apiClient.interceptors.request.use(async config => {
    try {
      const kcToken = getKcTokenClientSide();
      const accessToken =
        kcToken || (await getOrFetchAnonymousAccessToken({ store }));

      if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    } catch {
      // intentionally empty
    }
    return config;
  });

  return apiClient;
};

const createInvitesApiClient = ({ isServer, req, res, store }) => {
  if (isServer) {
    return createServerApiClient({ req, res, store });
  }
  return createBrowserApiClient({ store });
};

export const getInviteDetails = async (apiClient, { linkId }) =>
  apiClient.get(`/invites/${linkId}`);

export default createInvitesApiClient;
