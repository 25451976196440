import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { flattenResourceSet } from '@artemis/integrations/contentful/utils';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const resourceSetSlice = createSlice({
  name: 'resourceSet',
  initialState,
  reducers: {
    loadResourceSet: state => {
      state.isLoading = true;
    },
    loadResourceSetSuccess: (state, action) => {
      state.isLoading = false;
      state.data = flattenResourceSet(action.payload);
    },
    loadResourceSetError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.resourceSet);
  },
});

export const { loadResourceSet, loadResourceSetSuccess, loadResourceSetError } =
  resourceSetSlice.actions;

export default resourceSetSlice.reducer;
