export const PaymentGroupsResponse = {
  paymentGroups: [
    {
      name: 'Personal',
      sourceType: 'PERSONAL',
      paymentMethods: [
        {
          name: '',
          paymentMethodIdentifier: {
            entityType: 'PAYMENT_OPTION',
            entityId: 'payment-option-id-1',
            paymentProvider: 'STRIPE',
            paymentOptionType: 'CREDIT_CARD_VAULTED',
          },
          cardDisplayDetails: {
            brand: 'visa',
            country: 'US',
            last4: '4242',
          },
        },
        {
          name: '',
          paymentMethodIdentifier: {
            entityType: 'PAYMENT_OPTION',
            entityId: 'payment-option-id-2',
            paymentProvider: 'STRIPE',
            paymentOptionType: 'CREDIT_CARD_VAULTED',
          },
          cardDisplayDetails: {
            brand: 'mastercard',
            country: 'US',
            last4: '4444',
          },
        },
      ],
    },
    {
      name: 'Ritual, Toronto',
      sourceType: 'CORPORATE',
      paymentMethods: [
        {
          name: '',
          paymentMethodIdentifier: {
            entityType: 'PAYMENT_OPTION',
            entityId: 'payment-option-id-3',
            paymentProvider: 'STRIPE',
            paymentOptionType: 'CREDIT_CARD_VAULTED',
          },
          cardDisplayDetails: {
            brand: 'visa',
            country: 'US',
            last4: '4242',
          },
        },
        {
          name: 'Invoice - Holiday Party',
          paymentMethodIdentifier: {
            entityType: 'ACCOUNT',
            entityId: 'account-id-1',
            paymentProvider: 'NONE',
            paymentOptionType: 'INVOICE',
          },
        },
        {
          name: 'Invoice - Daily Credits',
          paymentMethodIdentifier: {
            entityType: 'ACCOUNT',
            entityId: 'account-id-2',
            paymentProvider: 'NONE',
            paymentOptionType: 'INVOICE',
          },
        },
      ],
    },
    {
      name: 'Ritual, New York City',
      sourceType: 'CORPORATE',
      paymentMethods: [
        {
          name: '',
          paymentMethodIdentifier: {
            entityType: 'PAYMENT_OPTION',
            entityId: 'payment-option-id-4',
            paymentProvider: 'STRIPE',
            paymentOptionType: 'CREDIT_CARD_VAULTED',
          },
          cardDisplayDetails: {
            brand: 'mastercard',
            country: 'US',
            last4: '4444',
          },
        },
        {
          name: 'Invoice - Holiday Party',
          paymentMethodIdentifier: {
            entityType: 'ACCOUNT',
            entityId: 'account-id-3',
            paymentProvider: 'NONE',
            paymentOptionType: 'INVOICE',
          },
        },
        {
          name: 'Invoice - Daily Credits',
          paymentMethodIdentifier: {
            entityType: 'ACCOUNT',
            entityId: 'account-id-4',
            paymentProvider: 'NONE',
            paymentOptionType: 'INVOICE',
          },
        },
      ],
    },
    {
      name: 'Ritual, Berlin',
      sourceType: 'CORPORATE',
      paymentMethods: [],
    },
  ],
};
