/**
 * ResourceSet Sagas
 */

import { put, takeLatest, call } from 'redux-saga/effects';
import contentfulClient from '@artemis/integrations/contentful/client';
import {
  loadResourceSet,
  loadResourceSetError,
  loadResourceSetSuccess,
} from './slice';

export function* loadResourceSetSaga(action) {
  const {
    payload: { name, locale },
  } = action;
  try {
    const data = yield call(contentfulClient.getClient().getEntries, {
      content_type: 'resourceSet',
      'fields.name[in]': name.join(','),
      include: 4, // retrieve nesting up to 4 layers deep
      locale,
    });
    yield put(loadResourceSetSuccess(data));
  } catch (error) {
    yield put(loadResourceSetError({ error }));
  }
}

export default function* resourceSetData() {
  yield takeLatest(loadResourceSet.type, loadResourceSetSaga);
}
