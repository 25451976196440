import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  fulfillmentId: null,
  initialized: false,
  invitation: {
    modalOpen: false,
    loading: false,
    error: null,
    data: null,
  },
  join: {
    modalOpen: false,
    loading: false,
    error: null,
    hasJoined: false,
  },
  find: {
    loading: false,
    error: null,
  },
  details: {
    modalOpen: false,
    loading: false,
    error: null,
    data: null,
  },
  status: {
    loading: false,
    error: null,
    data: null,
  },
  leave: {
    modalOpen: false,
    loading: false,
    error: null,
  },
  create: {
    loading: false,
    error: null,
  },
  update: {
    loading: false,
    error: null,
    paymentSplitModalOpen: false,
    schedulingModalOpen: false,
    deliveryModalOpen: false,
    guestLimitModalOpen: false,
    updatingFulfillmentType: false,
  },
  removed: {
    modalOpen: false,
    loading: false,
    error: null,
  },
  missed: {
    modalOpen: false,
  },
  nonjoinable: {
    modalOpen: false,
  },
  invite: {
    modalOpen: false,
  },
  info: {
    hostModalOpen: false,
    guestModalOpen: false,
  },
  tipDetails: {
    loading: false,
    error: null,
    data: null,
  },
  fulfillmentTypeOptions: {
    loading: false,
    error: null,
    data: null,
  },
  scheduledTimeSlots: {
    loading: false,
    error: null,
    data: null,
  },
  validation: {
    loading: false,
    error: null,
    data: null,
  },
  cancel: {
    modalOpen: false,
    loading: false,
    error: null,
  },
  confirm: {
    loading: false,
    error: null,
  },
  createInvite: {
    loading: false,
    error: null,
  },
};

const groupOrderSlice = createSlice({
  name: 'groupOrder',
  initialState,
  reducers: {
    // Load group order from invite
    loadInviteFulfillment: state => {
      state.invitation.loading = true;
      state.invitation.error = null;
    },
    loadInviteFulfillmentSuccess: (state, action) => {
      state.invitation.loading = false;
      state.invitation.data = action.payload.data;
    },
    loadInviteFulfillmentError: (state, action) => {
      state.invitation.loading = false;
      state.invitation.error = action.payload.error;
    },

    // Join group order
    joinFulfillment: state => {
      state.join.loading = true;
      state.join.error = null;
    },
    joinFulfillmentSuccess: (state, action) => {
      state.join.loading = false;
      state.details.data = action.payload.data;
      state.join.modalOpen = false;
      state.join.hasJoined = true;
    },
    joinFulfillmentError: (state, action) => {
      state.join.loading = false;
      state.join.error = action.payload.error;
      state.join.modalOpen = false;
    },

    // Find group orders for the current user
    findFulfillments: state => {
      state.find.loading = true;
      state.find.error = null;
    },
    findFulfillmentsSuccess: (state, action) => {
      state.find.loading = false;

      const fulfillments = action.payload.data || [];
      const fulfillmentId = fulfillments?.[0]?.fulfillmentId;

      if (fulfillmentId) {
        state.fulfillmentId = fulfillmentId;
        state.details.data = null;
      }

      state.initialized = true;
    },
    findFulfillmentsError: (state, action) => {
      state.find.loading = false;
      state.find.error = action.payload.error;
      state.initialized = true;
    },

    // Load details about a group order
    loadFulfillment: (state, action) => {
      state.fulfillmentId = action.payload.fulfillmentId;
      state.details.loading = true;
      state.details.error = null;
    },
    loadFulfillmentSuccess: (state, action) => {
      state.details.data = action.payload.data;
      state.details.loading = false;
    },
    loadFulfillmentError: (state, action) => {
      state.details.error = action.payload.error;
      state.details.loading = false;
    },

    // Load brief status of a group order (for polling)
    loadFulfillmentStatus: state => {
      state.status.loading = true;
      state.status.error = null;
    },
    loadFulfillmentStatusSuccess: (state, action) => {
      state.status.data = action.payload.data;
      state.status.loading = false;
    },
    loadFulfillmentStatusError: (state, action) => {
      state.status.error = action.payload.error;
      state.status.loading = false;
    },

    beginPollingForFulfillmentUpdates: () => {
      // intentionally empty
    },

    // Leave group order
    leaveFulfillment: state => {
      state.leave.loading = true;
      state.leave.error = null;
    },
    leaveFulfillmentSuccess: state => {
      state.fulfillmentId = null;
      state.details.data = null;
      // leave loading state active while page redirects to menu
    },
    leaveFulfillmentError: (state, action) => {
      state.leave.loading = false;
      state.leave.error = action.payload.error;
    },

    // Create new group order
    createFulfillment: state => {
      state.create.loading = true;
    },
    createFulfillmentSuccess: () => {
      // intentionally empty
    },
    createFulfillmentError: (state, action) => {
      state.create.loading = false;
      state.create.error = action.payload.error;
    },

    // Update group order
    updateFulfillment: (state, action) => {
      state.update.loading = true;

      if (action?.payload?.fulfillment?.fulfillmentType) {
        state.update.updatingFulfillmentType = true;
      }
    },
    updateFulfillmentSuccess: (state, action) => {
      state.update.loading = false;
      state.update.updatingFulfillmentType = false;
      state.details.data = action.payload.data?.fulfillment;
      state.fulfillmentId = action.payload.data?.fulfillment?.fulfillmentId;
      state.validation.data = action.payload.data?.validationErrors;
    },
    updateFulfillmentError: (state, action) => {
      state.update.loading = false;
      state.update.updatingFulfillmentType = false;
      state.update.error = action.payload.error;
    },

    // Load time slots available for group order
    loadScheduledTimeSlots: state => {
      state.scheduledTimeSlots.loading = true;
    },
    loadScheduledTimeSlotsSuccess: (state, action) => {
      state.scheduledTimeSlots.loading = false;
      state.scheduledTimeSlots.data = action.payload.data;
    },
    loadScheduledTimeSlotsError: (state, action) => {
      state.scheduledTimeSlots.loading = false;
      state.scheduledTimeSlots.error = action.payload.error;
    },

    // Load tip options for group order
    loadTipDetails: state => {
      state.tipDetails.loading = true;
      state.tipDetails.error = null;
    },
    loadTipDetailsSuccess: (state, action) => {
      state.tipDetails.data = action.payload.data;
      state.tipDetails.loading = false;
    },
    loadTipDetailsError: (state, action) => {
      state.tipDetails.error = action.payload.error;
      state.tipDetails.loading = false;
    },

    // Load available fulfillment types (pickup/delivery) for group order
    loadFulfillmentTypeOptions: state => {
      state.fulfillmentTypeOptions.loading = true;
      state.fulfillmentTypeOptions.error = null;
    },
    loadFulfillmentTypeOptionsSuccess: (state, action) => {
      state.fulfillmentTypeOptions.data = action.payload.data;
      state.fulfillmentTypeOptions.loading = false;
    },
    loadFulfillmentTypeOptionsError: (state, action) => {
      state.fulfillmentTypeOptions.error = action.payload.error;
      state.fulfillmentTypeOptions.loading = false;
    },

    // Load validation errors for group order
    validateFulfillment: state => {
      state.validation.loading = true;
      state.validation.error = null;
    },
    validateFulfillmentSuccess: (state, action) => {
      state.validation.data = action.payload.data;
      state.validation.loading = false;
    },
    validateFulfillmentError: (state, action) => {
      state.validation.error = action.payload.error;
      state.validation.loading = false;
    },

    // Remove guest from group order
    removeGuest: state => {
      state.removed.loading = true;
      state.removed.error = null;
    },
    removeGuestSuccess: state => {
      state.removed.loading = false;
    },
    removeGuestError: (state, action) => {
      state.removed.error = action.payload.error;
      state.removed.loading = false;
    },

    // Cancel group order
    cancelFulfillment: state => {
      state.cancel.loading = true;
      state.cancel.error = null;
    },
    cancelFulfillmentSuccess: state => {
      state.cancel.loading = false;
      state.fulfillmentId = null;
      state.details.data = null;
    },
    cancelFulfillmentError: (state, action) => {
      state.cancel.loading = false;
      state.cancel.error = action.payload.error;
    },
    setCancelModal: (state, action) => {
      state.cancel.modalOpen = action.payload.isOpen;
    },

    // Pay for group order (as host)
    confirmFulfillment: state => {
      state.confirm.loading = true;
      state.confirm.error = null;
    },
    confirmFulfillmentSuccess: (state, action) => {
      state.confirm.loading = false;
      state.details.data = action.payload.data;
    },
    confirmFulfillmentError: (state, action) => {
      state.confirm.loading = false;
      state.confirm.error = action.payload.error;
    },

    // Create invite for group order (consumer flow only)
    createInvite: state => {
      state.createInvite.loading = true;
      state.createInvite.error = null;
    },
    createInviteSuccess: (state, action) => {
      state.createInvite.loading = false;
      state.details.data = action.payload.data;
    },
    createInviteError: (state, action) => {
      state.createInvite.loading = false;
      state.createInvite.error = action.payload.error;
    },

    // Other modals
    setInvitationModal: (state, action) => {
      state.invitation.modalOpen = action.payload.isOpen;
    },
    setJoinModal: (state, action) => {
      state.join.modalOpen = action.payload.isOpen;
    },
    setDetailsModal: (state, action) => {
      state.details.modalOpen = action.payload.isOpen;
    },
    setLeaveModal: (state, action) => {
      state.leave.modalOpen = action.payload.isOpen;
    },
    setNonjoinableModal: (state, action) => {
      state.nonjoinable.modalOpen = action.payload.isOpen;
    },
    setRemovedFromGroupModal: (state, action) => {
      state.removed.modalOpen = action.payload.isOpen;
    },
    setMissedModal: (state, action) => {
      state.missed.modalOpen = action.payload.isOpen;
    },
    setInviteModal: (state, action) => {
      state.invite.modalOpen = action.payload.isOpen;
    },
    setPaymentSplitModal: (state, action) => {
      state.update.paymentSplitModalOpen = action.payload.isOpen;
    },
    setSchedulingModal: (state, action) => {
      state.update.schedulingModalOpen = action.payload.isOpen;
    },
    setDeliveryModal: (state, action) => {
      state.update.deliveryModalOpen = action.payload.isOpen;
    },
    setGuestLimitModal: (state, action) => {
      state.update.guestLimitModalOpen = action.payload.isOpen;
    },
    setHostInfoModal: (state, action) => {
      state.info.hostModalOpen = action.payload.isOpen;
    },
    setGuestInfoModal: (state, action) => {
      state.info.guestModalOpen = action.payload.isOpen;
    },
  },

  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.groupOrder);
  },
});

export const {
  loadInviteFulfillment,
  loadInviteFulfillmentSuccess,
  loadInviteFulfillmentError,
  joinFulfillment,
  joinFulfillmentSuccess,
  joinFulfillmentError,
  findFulfillments,
  findFulfillmentsSuccess,
  findFulfillmentsError,
  loadFulfillment,
  loadFulfillmentSuccess,
  loadFulfillmentError,
  loadFulfillmentStatus,
  loadFulfillmentStatusSuccess,
  loadFulfillmentStatusError,
  beginPollingForFulfillmentUpdates,
  leaveFulfillment,
  leaveFulfillmentSuccess,
  leaveFulfillmentError,
  createFulfillment,
  createFulfillmentSuccess,
  createFulfillmentError,
  updateFulfillment,
  updateFulfillmentSuccess,
  updateFulfillmentError,
  loadScheduledTimeSlots,
  loadScheduledTimeSlotsSuccess,
  loadScheduledTimeSlotsError,
  loadTipDetails,
  loadTipDetailsSuccess,
  loadTipDetailsError,
  loadFulfillmentTypeOptions,
  loadFulfillmentTypeOptionsSuccess,
  loadFulfillmentTypeOptionsError,
  validateFulfillment,
  validateFulfillmentSuccess,
  validateFulfillmentError,
  removeGuest,
  removeGuestSuccess,
  removeGuestError,
  cancelFulfillment,
  cancelFulfillmentSuccess,
  cancelFulfillmentError,
  setCancelModal,
  confirmFulfillment,
  confirmFulfillmentSuccess,
  confirmFulfillmentError,
  createInvite,
  createInviteSuccess,
  createInviteError,
  setInvitationModal,
  setJoinModal,
  setDetailsModal,
  setLeaveModal,
  setNonjoinableModal,
  setRemovedFromGroupModal,
  setMissedModal,
  setInviteModal,
  setPaymentSplitModal,
  setSchedulingModal,
  setDeliveryModal,
  setGuestLimitModal,
  setHostInfoModal,
  setGuestInfoModal,
} = groupOrderSlice.actions;

export default groupOrderSlice.reducer;
