import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    loadPromoCode: state => {
      state.isLoading = true;
    },
    loadPromoCodeSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadPromoCodeError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.promoCodes);
  },
});

export const { loadPromoCode, loadPromoCodeSuccess, loadPromoCodeError } =
  promoCodesSlice.actions;

export default promoCodesSlice.reducer;
