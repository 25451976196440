import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  organizationId: null,
  config: [],
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setActiveOrganization: (state, action) => {
      state.organizationId = action.payload;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.organization);
  },
});

export const { setActiveOrganization, setConfig } = organizationSlice.actions;

export default organizationSlice.reducer;
