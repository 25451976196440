import { darken } from 'polished';

const palette = {
  common: {
    black: '#222222',
    white: '#ffffff',
  },
  primary: '#09AEE0',
  primaryDark: '#00A6CB',
  secondary: '#4323B0',
  error: '#FFEBEE',
  warning: '#FFF3E0',
  info: '#64b5f6',
  success: '#43A047',
  dark: {
    chip: '#00444d',
    error: '#B71C1C',
    warning: '#E65100',
  },
  loyaltyPlus: '#166CE8',
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
    UI1: '#333333',
    UI5: '#E8E9EE',
    UI6: '#F3F4F7',
    UISystem6: '#DCDFE4',
  },
  red: {
    600: '#E53935',
  },
  text: {
    regular: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: '#7A7D8A',
    },
    constrast: {
      primary: '#ffffff',
      secondary: '#ffffff',
      disabled: '#ffffff',
      hint: '#ffffff',
    },
  },
  background: {
    default: '#fff',
    card: '#fff',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    hoverOpacity: 0.04,
    selected: 'rgba(0, 0, 0, 0.08)',
    selectedOpacity: 0.08,
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
  hover: {
    onLightBg: colour => darken(0.04, colour),
    onDarkBg: colour => darken(0.1, colour),
  },
};

export default palette;
