import axios from 'axios';
import queryString from 'query-string';

const keycloakConfig = process.env.RT_KEYCLOAK_CONFIG;

const getRealmURL = () =>
  `${keycloakConfig['auth-server-url']}/realms/${keycloakConfig.realm}`;

export const getKeycloakProfile = async ({ token }) => {
  const url = `${getRealmURL()}/account`;
  const response = await axios.get(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
};

export const getAnonymousAccessToken = async ({ clientId, clientSecret }) => {
  const url = `${getRealmURL()}/protocol/openid-connect/token`;

  const params = new URLSearchParams();
  params.append(
    'client_id',
    clientId || process.env.RT_KEYCLOAK_ANONYMOUS_CLIENT_ID,
  );
  params.append('grant_type', 'client_credentials');
  params.append(
    'client_secret',
    clientSecret || process.env.RT_KEYCLOAK_ANONYMOUS_CLIENT_SECRET,
  );

  const response = await axios.post(url, params);

  return {
    token: response.data.access_token,
    expiresIn: response.data.expires_in,
  };
};

export const refreshAccessToken = async ({
  refreshToken,
  clientId = keycloakConfig.clientId,
}) => {
  const tokenUrl = `${getRealmURL()}/protocol/openid-connect/token`;
  try {
    const result = await axios.post(
      tokenUrl,
      queryString.stringify({
        refresh_token: refreshToken,
        grant_type: 'refresh_token',
        client_id: clientId,
      }),
      {
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      },
    );
    return result.data.access_token;
  } catch (e) {
    return null;
  }
};
