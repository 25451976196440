import { StubbedHqoSDK } from './stubs';

export const loadSDK = ({ useStubs = false } = {}) => {
  if (useStubs) {
    return StubbedHqoSDK;
  }

  if (window.hqoClientSdk) {
    Promise.resolve(window.hqoClientSdk);
  }

  const scriptUrl = process.env.RT_HQO_SDK_URL;

  const script = document.createElement('script');
  script.src = scriptUrl;
  script.type = 'text/javascript';
  script.async = true;
  const promise = new Promise(resolve => {
    script.onload = () => {
      resolve(window.hqoClientSdk);
    };
  });

  document.body.appendChild(script);

  return promise;
};
