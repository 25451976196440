import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const verifyEmailSlice = createSlice({
  name: 'verifyEmail',
  initialState,
  reducers: {
    loadVerifyEmail: state => {
      state.isLoading = true;
    },
    loadVerifyEmailSuccess: state => {
      state.isLoading = false;
      state.success = true;
    },
    loadVerifyEmailError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loadVerifyEmail, loadVerifyEmailSuccess, loadVerifyEmailError } =
  verifyEmailSlice.actions;

export default verifyEmailSlice.reducer;
