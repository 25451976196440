import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import parser from 'ua-parser-js';
import Cookies from '@artemis/utils/cookies';
import { EMBED_PARAM, OWNER_ID_PARAM } from '@artemis/utils/query/constants';
import find from 'lodash/find';

const EVENT_ACTION_PAGE = 'CLICK_THROUGH_FUNNEL_PAGE';
const EVENT_ACTION_TRACK = 'CLICK_THROUGH_FUNNEL_TRACK';

export const EVENT_PARAM_TYPE_STRING = 'STRING';
export const EVENT_PARAM_TYPE_INTEGER = 'INT64';
export const EVENT_PARAM_TYPE_BOOL = 'BOOL';

export const EVENT_TYPE_CORE_FLOW = 'CORE_FLOW';
export const EVENT_TYPE_INTERACTION = 'INTERACTION';

export const ENTITY_TYPE_CLIENT = 'CLIENT_USER';
const ENTITY_TYPE_EXTERNAL_USER = 'EXTERNAL_USER';

export const DOMAIN_CATEGORY_RITUAL_CORE = 'DOMAIN_CATEGORY_RITUAL_CORE';
export const DOMAIN_CATEGORY_WEB_VITALS = 'DOMAIN_CATEGORY_WEB_VITALS';

export const PROJECT_CATEGORY_RITUAL_CORE = 'PROJECT_CATEGORY_RITUAL_CORE';
export const PROJECT_CATEGORY_WEB_VITALS = 'PROJECT_CATEGORY_WEB_VITALS';
export const PROJECT_CATEGORY_JOIN_TEAM = 'PROJECT_CATEGORY_JOIN_TEAM';
export const PROJECT_CATEGORY_RITUAL_SERVICE_FEES =
  'PROJECT_CATEGORY_RITUAL_SERVICE_FEES';
export const PROJECT_CATEGORY_DOWNLOAD_APP = 'PROJECT_CATEGORY_DOWNLOAD_APP';

export const FEATURE_CATEGORY_RITUAL_CORE = 'FEATURE_CATEGORY_RITUAL_CORE';
export const FEATURE_CATEGORY_WEB_VITALS = 'FEATURE_CATEGORY_WEB_VITALS';
export const FEATURE_CATEGORY_RITUAL_SERVICE_FEES =
  'FEATURE_CATEGORY_RITUAL_SERVICE_FEES';

const getIsEmbedded = () => {
  const params = new URL(document.location).searchParams;
  const embedValue = params.get(EMBED_PARAM);
  return !!embedValue;
};

const getOwnerId = () => {
  const params = new URL(document.location).searchParams;
  const id = params.get(OWNER_ID_PARAM);
  return id || null;
};

const pushEvent = event => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

export const page = eventProperties => {
  pushEvent({
    event: EVENT_ACTION_PAGE,
    eventProperties: { IS_EMBEDDED: getIsEmbedded(), ...eventProperties },
  });
};

export const track = (eventName, eventProperties = {}) => {
  pushEvent({
    event: EVENT_ACTION_TRACK,
    eventProperties: { IS_EMBEDDED: getIsEmbedded(), ...eventProperties },
    eventName,
  });
};

const parseUrls = () => {
  const url = document.location.href;
  const referral = document.referrer;

  return [
    {
      key: 'URL',
      value: url,
      type: EVENT_PARAM_TYPE_STRING,
    },
    {
      key: 'REFERRAL_URL',
      value: referral,
      type: EVENT_PARAM_TYPE_STRING,
    },
  ];
};

const generateTimestamp = () => new Date().getTime().toString();

export const postEvent = event => {
  axios.post(process.env.RT_ANALYTICS_URL, event);
};

const generateDeviceId = cookieName => {
  const deviceId = uuidv4().replace(/-/g, '');
  Cookies.set(cookieName, deviceId, {
    expires: 365,
    domain: process.env.RT_SHARED_COOKIE_DOMAIN,
  });
  return deviceId;
};

export const getEnvPrefix = rtServerEnv =>
  rtServerEnv === 'production' ? '' : rtServerEnv;
export const getCookieIds = () => {
  const envPrefix = getEnvPrefix(process.env.RT_SERVER_ENVIRONMENT);
  const euid = Cookies.get('ritual_externaluserid') || '';
  const sessionId = Cookies.get('ritual_analyticssessionid') || '';
  const deviceId =
    Cookies.get(`ritual${envPrefix}_deviceid`) ||
    generateDeviceId(`ritual${envPrefix}_deviceid`);

  return {
    euid,
    sessionId,
    deviceId,
  };
};

export const getEvent = ({
  eventAction,
  eventParams,
  eventType,
  entityType = ENTITY_TYPE_EXTERNAL_USER,
  analyticsDomainCategory = DOMAIN_CATEGORY_RITUAL_CORE,
  analyticsProjectCategory = PROJECT_CATEGORY_RITUAL_CORE,
  analyticsFeatureCategory = FEATURE_CATEGORY_RITUAL_CORE,
}) => {
  const eventId = uuidv4().replace(/-/g, '');
  const eventTime = generateTimestamp();
  const ua = parser(navigator.userAgent);

  const { euid, sessionId, deviceId } = getCookieIds();

  const urls = parseUrls();
  const isEmbedded = getIsEmbedded();

  const event = {
    batchDeliveryUuid: eventId,
    clientSubmittedTimestamp: eventTime,
    sourceType: 'WEB',
    sourceVersion: '1',
    sourceName: ua.browser.name,
    events: [
      {
        eventId,
        eventDatetime: eventTime,

        entityType,
        entityId: euid,
        entitySessionId: sessionId,

        eventType,
        eventAction,

        analyticsDomainCategory,
        analyticsProjectCategory,
        analyticsFeatureCategory,

        metadata: {
          eventVersion: 1,
          eventSequenceNumber: 1,
          platform: 'RITUAL_ONE',
          deviceId,
          clientVersion: '1',
          isBackground: true,
        },

        eventParams: [
          {
            key: 'PM_IS_EMBEDDED_VIEW',
            value: isEmbedded,
            type: EVENT_PARAM_TYPE_STRING,
          },
          ...eventParams,
          ...urls,
        ],
      },
    ],
  };

  return event;
};

export const logEvent = (
  eventAction,
  eventParams = [],
  eventType = EVENT_TYPE_CORE_FLOW,
  analyticsProjectCategory = PROJECT_CATEGORY_RITUAL_CORE,
  analyticsFeatureCategory = FEATURE_CATEGORY_RITUAL_CORE,
) => {
  const event = getEvent({
    eventAction,
    eventParams,
    eventType,
    analyticsProjectCategory,
    analyticsFeatureCategory,
  });

  const ownerId = getOwnerId();
  if (!find(event.events[0].eventParams, e => e.key === 'OWNER_ID')) {
    event.events[0].eventParams.push({
      key: 'OWNER_ID',
      value: ownerId,
      type: EVENT_PARAM_TYPE_STRING,
    });
  }

  postEvent(event);
};
