import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { IntlProvider, FormattedMessage as F, useIntl } from 'react-intl';
import { getResourceSet } from '@artemis/store/resourceSet/selectors';

export const flattenResourceSet = ({ items }) => {
  const ret = {};
  items.forEach(({ fields: { resources } }) => {
    resources.forEach(({ fields, sys }) => {
      if (fields) {
        if (sys.contentType.sys.id === 'image') {
          ret[fields.entryTitle] = getResponsiveImage(fields);
        } else {
          const { key, value } = fields;
          ret[key] = value;
        }
      }
    });
  });
  return ret;
};

export const WrappedIntlProvider = ({ children }) => (
  <IntlProvider
    locale="en"
    messages={{ none: '' }}
    wrapRichTextChunksInFragment
  >
    {children}
  </IntlProvider>
);

WrappedIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const FormattedMessage = ({
  entry,
  values = { br: <br /> },
  fallbackMessage,
}) => {
  const resources = useSelector(getResourceSet);
  const message = resources[entry];
  if (!message) {
    return fallbackMessage || entry;
  }
  return <F id={entry} defaultMessage={message} values={values} />;
};

export function useFormatMessage({
  entry,
  values = { br: <br /> },
  fallbackMessage,
}) {
  const intl = useIntl();
  const resources = useSelector(getResourceSet);
  const message = resources[entry];
  if (!message) {
    return fallbackMessage || entry;
  }
  return intl.formatMessage({ id: entry, defaultMessage: message }, values);
}

export function useFormatMessageList({ entry, values }) {
  const intl = useIntl();
  const resources = useSelector(getResourceSet);
  const message = resources[entry];
  if (!message) {
    return entry;
  }

  return message
    .split('\n')
    .map((item, index) => (
      <li key={`${entry}-${item}`}>
        {intl.formatMessage(
          { id: `${entry}-${index}`, defaultMessage: item },
          values,
        )}
      </li>
    ));
}

FormattedMessage.propTypes = {
  entry: PropTypes.string.isRequired,
  values: PropTypes.shape({}),
  fallbackMessage: PropTypes.string,
};

const getResponsiveImage = responsiveImage => {
  const {
    imageMobile,
    imageTablet,
    imageDesktop,
    alt = '',
    title = '',
  } = responsiveImage;
  return {
    alt,
    title,
    mobile: getImageURL(imageMobile),
    tablet: getImageURL(imageTablet),
    desktop: getImageURL(imageDesktop),
  };
};

const getImageURL = image => {
  if (!image || !image.fields) {
    return '';
  }
  const { url } = image.fields.file;
  if (url.endsWith('.jpg')) {
    return `${url}?fm=jpg&fl=progressive`;
  }
  return url;
};

export const useImageUrlAlt = id => {
  const resources = useSelector(getResourceSet);
  const image = resources[id];
  if (!image) {
    return { url: id, alt: id };
  }
  const { mobile, alt } = image;

  return { url: mobile, alt };
};
