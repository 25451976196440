import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
  isAnalyticsIdentityLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUser: state => {
      state.isLoading = true;
    },
    loadUserSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadUserError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getAnalyticsIdentity: state => {
      state.isAnalyticsIdentityLoading = true;
    },
    getAnalyticsIdentitySuccess: state => {
      state.isAnalyticsIdentityLoading = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.user);
  },
});

export const {
  loadUser,
  loadUserSuccess,
  loadUserError,
  getAnalyticsIdentity,
  getAnalyticsIdentitySuccess,
} = userSlice.actions;

export default userSlice.reducer;
