import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { setFulfillmentSuccess } from '@artemis/store/fulfillment/slice';
import { setScheduledForTimeSuccess } from '@artemis/store/scheduling/slice';
import { authenticated } from '@artemis/store/auth/slice';

export const initialState = {
  isLoading: false,
  isUpdating: false,
  isUpdatingItem: false,
  isPlacingOrder: false,
  data: null,
  error: null,
  perkError: null,
  note: '',
  isLoadingDeliveryInfo: false,
  deliveryInfo: null,
};

const loadCartReducer = (state, action) => {
  if (action.payload.isUpdate) {
    state.isUpdating = true;
  } else {
    state.isLoading = true;
  }
};

const updateCartReducer = state => {
  state.isUpdating = true;
};

const updateItemReducer = state => {
  state.isUpdatingItem = true;
};

const loadCartSuccessReducer = (state, action) => {
  state.isLoading = false;
  state.data = action.payload;
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    loadCart: loadCartReducer,
    cartLoaded: state => {
      state.isLoading = false;
      state.isUpdating = false;
    },
    loadCartSuccess: loadCartSuccessReducer,
    loadCartError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    addToCart: updateItemReducer,
    updateCart: updateCartReducer,
    updateCartSuccess: (state, action) => {
      state.isUpdating = false;
      state.isUpdatingItem = false;
      state.perkError = null;
      state.data = action.payload;
    },
    updateCartError: (state, action) => {
      state.isUpdating = false;
      state.isUpdatingItem = false;
      state.error = action.payload.error;
    },
    modifyItemInCart: updateItemReducer,
    removeFromCart: updateCartReducer,
    placeOrder: state => {
      state.isPlacingOrder = true;
    },
    placeOrderSuccess: state => {
      state.isPlacingOrder = false;
    },
    placeOrderError: (state, action) => {
      state.isPlacingOrder = false;
      state.error = action.payload.error;
    },
    setTip: updateCartReducer,
    noteChange: (state, action) => {
      state.isLoading = false;
      state.note = action.payload.note;
    },
    addPromo: updateCartReducer,
    removePromo: updateCartReducer,
    toggleCutlery: updateCartReducer,
    cartDeliveryInfoLoading: state => {
      state.isLoadingDeliveryInfo = true;
    },
    cartDeliveryInfoLoaded: (state, action) => {
      state.isLoadingDeliveryInfo = false;
      state.deliveryInfo = action.payload;
    },
    associateFulfillment: () => {
      // intentionally empty; this action is only used by sagas
    },
    perkError: (state, action) => {
      state.isUpdating = false;
      state.isUpdatingItem = false;
      state.perkError = action.payload;
    },
    clearPerkError: state => {
      state.isUpdating = false;
      state.isUpdatingItem = false;
      state.perkError = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(authenticated, loadCartReducer)
      .addMatcher(
        isAnyOf(setScheduledForTimeSuccess, setFulfillmentSuccess),
        loadCartSuccessReducer,
      );
  },
});

export const {
  loadCart,
  cartLoaded,
  loadCartSuccess,
  loadCartError,
  addToCart,
  updateCart,
  updateCartSuccess,
  updateCartError,
  modifyItemInCart,
  removeFromCart,
  placeOrder,
  placeOrderSuccess,
  placeOrderError,
  setTip,
  noteChange,
  addPromo,
  removePromo,
  toggleCutlery,
  cartDeliveryInfoLoading,
  cartDeliveryInfoLoaded,
  associateFulfillment,
  perkError,
  clearPerkError,
} = cartSlice.actions;

export default cartSlice.reducer;
