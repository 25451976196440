import { all } from 'redux-saga/effects';
import resourceSetData from './resourceSet/sagas';
import menuData from './menu/sagas';
import merchantGroup from './merchantGroup/sagas';
import merchantData from './merchant/sagas';
import merchantPreviewData from './merchantPreview/sagas';
import cart from './cart/sagas';
import fulfillment from './fulfillment/sagas';
import order from './order/sagas';
import { getPaymentOptionsData } from './paymentOptions/sagas';
import ipData from './ipData/sagas';
import user from './user/sagas';
import delivery from './delivery/sagas';
import deliveryAddresses from './deliveryAddresses/sagas';
import setScheduledForTime from './scheduling/sagas';
import points from './points/sagas';
import embed from './embed/sagas';
import workspaceData from './workspace/sagas';
import catalog from './catalog/sagas';
import incentives from './incentives/sagas';
import groupOrder from './groupOrder/sagas';
import invite from './invite/sagas';
import promoCodes from './promoCodes/sagas';
import verifyEmail from './verifyEmail/sagas';
import teams from './teams/sagas';

function* rootSaga() {
  yield all([
    cart(),
    menuData(),
    resourceSetData(),
    merchantGroup(),
    merchantData(),
    merchantPreviewData(),
    fulfillment(),
    order(),
    getPaymentOptionsData(),
    ipData(),
    user(),
    delivery(),
    deliveryAddresses(),
    setScheduledForTime(),
    points(),
    embed(),
    workspaceData(),
    catalog(),
    incentives(),
    groupOrder(),
    invite(),
    promoCodes(),
    verifyEmail(),
    teams(),
  ]);
}

export default rootSaga;
