import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isLoading: false,
  isModalOpen: false,
  error: null,
  isToggling: false,
};

const fulfillmentSlice = createSlice({
  name: 'fulfillment',
  initialState,
  reducers: {
    setFulfillment: (state, action) => {
      const { isToggling = false } = action.payload;
      if (isToggling) {
        state.isToggling = true;
      } else {
        state.isLoading = true;
      }
    },
    setFulfillmentSuccess: state => {
      state.isLoading = false;
      state.isToggling = false;
      state.isModalOpen = false;
      state.error = null;
    },
    setFulfillmentError: (state, action) => {
      state.isLoading = false;
      state.isToggling = false;
      state.error = action.payload;
    },
    openModal: state => {
      state.isModalOpen = true;
    },
    closeModal: state => {
      state.isModalOpen = false;
      state.error = null;
    },
    clearFulfillmentError: state => {
      state.error = null;
    },
  },
});

export const {
  setFulfillment,
  setFulfillmentSuccess,
  setFulfillmentError,
  openModal,
  closeModal,
  clearFulfillmentError,
} = fulfillmentSlice.actions;

export default fulfillmentSlice.reducer;
