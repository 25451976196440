import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const MSEC_PER_SEC = 1000;
const SEC_PER_MIN = 60;
export const MSEC_PER_MIN = MSEC_PER_SEC * SEC_PER_MIN;

export function getMinutes(timestamp) {
  return new Date(timestamp).getUTCMinutes();
}

export function getDifferenceInMinutes(time1, time2) {
  const diff = Math.abs(new Date(time1) - new Date(time2));
  return Math.floor(diff / MSEC_PER_MIN);
}

export function getHoursAndMinutes(timestamp) {
  return new Date(timestamp).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
}

/**
 * Converts milliseconds to absolute 12-hour time (ignores timezones)
 * @param {*} ms Milliseconds
 * @returns 12-hour time (h:mm A)
 */
export function millisToFormattedTime(ms) {
  dayjs.extend(utc);
  return dayjs.utc(ms).format('h:mm A'); // e.g. 11:30 AM
}

export function millisToNearestXMinutes(ms, x) {
  return x === 0 ? 0 : Math.round(ms / MSEC_PER_MIN / x) * x;
}

/**
 * Day.js Utilities
 */

export const FORMAT = {
  YMD: 'YYYY-MM-DD',
  ISO_DATE_TIME: 'YYYY-MM-DDTHH:mm:ss', // 2018-08-16T20:02:00
  DATE_AND_TIME: 'll, LT', // Aug 16, 2018, 8:02 PM
  DAY_MONTH_AND_TIME: 'MMM D, LT', // Aug 16, 8:02 PM
  DAY_AND_MONTH: 'MMM D', // Aug 16
  DATE: 'ddd, MMM D', // Thu, Aug 16
  TIME: 'LT', // 8:02 PM
  DAY_OF_WEEK_AND_TIME: 'ddd, LT', // Thurs, 8:02 PM
  DAY_OF_WEEK_AND_TIME_WITHOUT_COMMA: 'ddd LT', // Thurs 8:02 PM
  DAY_OF_WEEK_DATE_AND_TIME: 'ddd, MMM D, LT', // Thurs, Aug 16, 8:02 PM
};

function parseCustomFormatString(inputString, customFormat) {
  dayjs.extend(customParseFormat);
  return dayjs(inputString, customFormat);
}

function parseCustomFormatStringTimeZone(inputString, customFormat, timeZone) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(customParseFormat);
  const date = dayjs.tz(inputString, customFormat, timeZone);
  // Fallback to local time zone if parsing with time zone fails
  if (!date.isValid()) {
    return parseCustomFormatString(inputString, customFormat);
  }
  return date;
}

export function formatDate(date, format) {
  if (!date || !date.isValid()) return undefined;
  dayjs.extend(localizedFormat);
  return date.format(format);
}

/** Parse a date string given its format, with an optional time zone
 * @param {string} dateString String representing a date
 * @param {string} format Valid format
 * @param {string} timeZone (Optional) Valid time zone
 * @return Parsed date object, or undefined if unable to parse
 */
export function parseDateString(dateString, format, timeZone) {
  let date;
  if (timeZone) {
    date = parseCustomFormatStringTimeZone(dateString, format, timeZone);
  } else {
    date = parseCustomFormatString(dateString, format);
  }
  if (!date || !date.isValid()) return undefined;
  return date;
}

/**
 * Parses a UTC date string, for example: "2022-03-14T03:14:15Z"
 * @param {string} dateString String representing a UTC date
 *  @param {string} timeZone Valid time zone
 * @returns Formatted date string in the provided time zone
 */
export function parseUTC(dateString, timeZone) {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs(dateString).tz(timeZone);
}

/**
 * Formats a parsed date as an ISO 8601 UTC string, for example: "2022-03-14T03:14:15Z"
 * @param {dayjs} parsed dayjs date in any timezone
 * @returns the given date formatted as an ISO 8601 UTC string
 */
export function formatAsUTC(date) {
  dayjs.extend(timezone);
  return date.tz('Etc/UTC').format();
}

/**
 * Parses a UTC date string and formats it for display in the desired time zone.
 * Useful for displaying UTC dates provided by server APIs. For example, given
 * "2022-03-14T03:14:15Z" it can return "Mar 13, 11:14 PM"
 * @param {string} dateString String representing a UTC date
 * @param {string} timeZone Valid time zone
 * @param {string} format Valid format
 * @returns Formatted date string in the provided time zone
 */
export function parseAndFormatUTC(dateString, timeZone, format) {
  dayjs.extend(localizedFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  return dayjs.utc(dateString).tz(timeZone).format(format);
}

export function parseTimestamp(timestamp) {
  return dayjs(timestamp);
}

/**
 * Get the number of calendar days difference between an end date and an optional start date
 * @param {object} dates The date range and format
 * @param {string} dates.endDate The end date of the range, later than the startDate/current date for a positive difference
 * @param {string} dates.startDate (Optional) The start date of the range. If undefined, the current date
 * @param {string} dates.format The format to use to parse the date strings
 * @param {string} dates.timeZone (Optional) Valid time zone
 * @returns {number} The number of calendar days between the dates. The difference between today and tomorrow is 1. This can be negative if endDate is after startDate
 */
export function getTzDifferenceInDays({
  endDate,
  startDate = dayjs(),
  format,
  timeZone,
}) {
  const end = parseDateString(endDate, format, timeZone)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  const start = parseDateString(startDate, format, timeZone)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  return end.diff(start, 'day');
}

export function getMinutesUntilTime(targetTime) {
  return dayjs(targetTime).diff(dayjs(), 'minute', true);
}

export function formatMinutesAndSecondsUntilTime(targetTime) {
  dayjs.extend(utc);
  const currentTime = dayjs();
  const differenceMs = dayjs(targetTime).diff(currentTime, 'milliseconds');
  return dayjs.utc(Math.max(0, differenceMs)).format('m:ss');
}
