import { createSelector } from 'reselect';

import { getCurrencyCode } from '@artemis/store/merchant/selectors';
import {
  getActiveCurrencyCreditsMicro,
  getUserPoints,
} from '@artemis/store/user/selectors';

import { formatCurrency, MICRO_UNIT } from '@artemis/utils/currency-format';

const getCreditsByCurrency = credits =>
  (credits || []).reduce(
    (byCurrency, credit) => ({
      ...byCurrency,
      [credit.currency]: credit.value * MICRO_UNIT,
    }),
    {},
  );

const getPointsRoot = state => state.points;

const getRedeemAllPreviewCreditsByCurrency = createSelector(
  getPointsRoot,
  points => getCreditsByCurrency(points.redeemAllPreview?.data?.credits),
);

export const getTotalRedeemableCredits = createSelector(
  getRedeemAllPreviewCreditsByCurrency,
  getActiveCurrencyCreditsMicro,
  getCurrencyCode,
  (redeemAllPreviewCreditsByCurrency, currentCreditsMicro, currencyCode) => {
    const redeemAllPreviewCreditsMicro =
      redeemAllPreviewCreditsByCurrency[currencyCode] || 0;

    const redeemedCreditsMicro =
      redeemAllPreviewCreditsMicro - currentCreditsMicro;

    return formatCurrency({
      currencyCode,
      value: redeemedCreditsMicro > 0 ? redeemedCreditsMicro : 0,
    });
  },
);

const getRedeemedCreditsPreviewMicro = createSelector(
  getPointsRoot,
  getActiveCurrencyCreditsMicro,
  getCurrencyCode,
  (points, currentCreditsMicro, currencyCode) => {
    const redeemPreviewCreditsByCurrency = getCreditsByCurrency(
      points.redeemModal?.redeemPreview?.data?.credits,
    );

    const redeemPreviewCreditsMicro =
      redeemPreviewCreditsByCurrency[currencyCode] || 0;

    return Math.max(0, redeemPreviewCreditsMicro - currentCreditsMicro);
  },
);

export const getRedeemedCreditsPreview = createSelector(
  getCurrencyCode,
  getRedeemedCreditsPreviewMicro,
  (currencyCode, creditsMicro) =>
    formatCurrency({
      currencyCode,
      value: creditsMicro,
    }),
);

export const getTotalCreditsAfterRedeemPreview = createSelector(
  getCurrencyCode,
  getPointsRoot,
  (currencyCode, points) => {
    const redeemPreviewCreditsByCurrency = getCreditsByCurrency(
      points.redeemModal?.redeemPreview?.data?.credits,
    );

    return formatCurrency({
      currencyCode,
      value: redeemPreviewCreditsByCurrency[currencyCode] || 0,
    });
  },
);

export const getIsRedeemModalOpen = createSelector(
  getPointsRoot,
  points => points.redeemModal.open,
);

export const getIsRedeeming = createSelector(
  getPointsRoot,
  points => points.redeem.isLoading,
);

export const getPointsToRedeem = createSelector(
  getPointsRoot,
  points => points.redeemModal.pointsToRedeem || 0,
);

// TODO: get this data from API response (WT-1687)
export const getPointsIncrement = createSelector(
  getCurrencyCode,
  currencyCode => {
    if (['GBP', 'EUR'].includes(currencyCode)) {
      return 5000;
    }
    return 1000;
  },
);

// Users can only redeem points in increments of 1000 or 5000 (depending on currency)
// For example if a user has 1234 points and the active currency is CAD they can redeem up to 1000.
export const getMaxRedeemablePoints = createSelector(
  getUserPoints,
  getPointsIncrement,
  (points, increment) => {
    const nearestLowerIncrement = Math.floor(points / increment);
    return nearestLowerIncrement * increment || 0;
  },
);

export const getCanUserRedeemPoints = createSelector(
  getUserPoints,
  getPointsIncrement,
  (points, increment) => points >= increment,
);
