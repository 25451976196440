import { createSelector } from 'reselect';
import {
  getMerchantAvailable,
  getMerchantTimeZone,
  getMerchantSupportsScheduling,
  getHasDeliveryMinimumOrderValue,
  getDeliveryMinimumOrderValue,
} from '@artemis/store/merchant/selectors';
import { formatCurrency, microToPoints } from '@artemis/utils/currency-format';
import {
  formatDate,
  getTzDifferenceInDays,
  parseDateString,
  FORMAT as TIME_FORMAT,
  millisToNearestXMinutes,
  parseAndFormatUTC,
  FORMAT,
} from '@artemis/utils/time-format';
import { FULFILLMENT_TYPE } from '@artemis/utils/constants';
import {
  getFulfillmentScheduledTime,
  getFulfillmentFullDeliveryAddress,
  getFulfillmentDetails,
  getFulfillmentCartScheduledTime,
  getFulfillmentDeliveryAddressLine1,
  getIsGroupOrder,
} from '@artemis/store/groupOrder/selectors';

const getCartRoot = state => state.cart;

export const getRawCart = createSelector(
  getCartRoot,
  cart => cart && cart.data,
);

export const getCurrencyCode = createSelector(
  getRawCart,
  cart => (cart && cart.currencyCode) || 'DOLLAR',
);

export const getCartItems = createSelector(getRawCart, cart => {
  if (!cart || !cart.cartItems) {
    return {};
  }
  /* eslint-disable no-param-reassign */
  return cart.cartItems.reduce((obj, item) => {
    obj[item.menuItemId] = item;
    return obj;
  }, {});
  /* eslint-enable no-param-reassign */
});

const remapCartItems = createSelector(
  getRawCart,
  getCurrencyCode,
  (cart, currencyCode) => {
    if (!cart || !cart.cartItems) {
      return null;
    }
    return cart.cartItems.map(
      ({
        priceMicro: itemPriceMicro,
        cartItemOptions,
        perk,
        ...itemProperties
      }) => ({
        ...itemProperties,
        priceMicro: itemPriceMicro,
        price: formatCurrency({
          currencyCode,
          value: itemPriceMicro,
        }),
        cartItemOptions: cartItemOptions.map(
          ({ priceMicro: optionPriceMicro, ...optionProperties }) => ({
            ...optionProperties,
            priceMicro: optionPriceMicro,
            price: formatCurrency({
              currencyCode,
              value: optionPriceMicro,
            }),
          }),
        ),
        ...(perk
          ? {
              perk: {
                ...perk,
                originalPrice: formatCurrency({
                  value: perk.originalPriceMicro,
                  currencyCode,
                }),
                savings: formatCurrency({
                  value: perk.savingsMicro,
                  currencyCode,
                }),

                discountedPrice: formatCurrency({
                  value: perk.discountedPriceMicro,
                  currencyCode,
                }),
              },
            }
          : {}),
      }),
    );
  },
);

const remapFinancialInfo = createSelector(
  getRawCart,
  getCurrencyCode,
  (cart, currencyCode) => {
    if (!cart || !cart.financialInfo) {
      return null;
    }
    const {
      tipMicro,
      subtotalMicro,
      deliveryChargeMicro,
      taxMicro,
      serviceChargeMicro,
      creditMicro,
      totalPayableMicro,
      taxRates,
      serviceCharges,
      cartDiscounts,
      deliveryMinimumOrderValueMicro,
      freeDeliveryOrderValueMicro,
      taxAndServiceChargeMicro,
      pickupMinCartItemCount,
      pickupMaxCartItemCount,
      pickupMinimumOrderValueMicro,
      pickupMaximumOrderValueMicro,
    } = cart.financialInfo;

    return {
      ...cart.financialInfo,
      tip: formatCurrency({
        currencyCode,
        value: tipMicro,
      }),
      subtotal: formatCurrency({
        currencyCode,
        value: subtotalMicro,
      }),
      deliveryCharge: formatCurrency({
        currencyCode,
        value: deliveryChargeMicro,
      }),
      tax: formatCurrency({
        currencyCode,
        value: taxMicro,
      }),
      serviceCharge: formatCurrency({
        currencyCode,
        value: serviceChargeMicro,
      }),
      credit: formatCurrency({
        currencyCode,
        value: creditMicro,
      }),
      totalPayable: formatCurrency({
        currencyCode,
        value: totalPayableMicro,
      }),
      deliveryMinimumOrderValue: formatCurrency({
        currencyCode,
        value: deliveryMinimumOrderValueMicro,
      }),
      pickupMinCartItemCount,
      pickupMaxCartItemCount,
      pickupMinimumOrderValue: formatCurrency({
        currencyCode,
        value: pickupMinimumOrderValueMicro,
      }),
      pickupMaximumOrderValue: formatCurrency({
        currencyCode,
        value: pickupMaximumOrderValueMicro,
      }),
      freeDeliveryOrderValue: formatCurrency({
        currencyCode,
        value: freeDeliveryOrderValueMicro,
      }),
      taxAndServiceCharge: formatCurrency({
        currencyCode,
        value: taxAndServiceChargeMicro,
      }),
      taxRates:
        taxRates &&
        taxRates.map(({ taxMicro: subTaxMicro, ...rest }) => ({
          ...rest,
          taxMicro: subTaxMicro,
          tax: formatCurrency({
            currencyCode,
            value: subTaxMicro,
          }),
        })),
      serviceCharges:
        serviceCharges &&
        serviceCharges.map(
          ({ serviceChargeMicro: subServiceChargeMicro, ...rest }) => ({
            ...rest,
            serviceChargeMicro: subServiceChargeMicro,
            serviceCharge: formatCurrency({
              currencyCode,
              value: subServiceChargeMicro,
            }),
          }),
        ),
      discounts:
        cartDiscounts &&
        cartDiscounts.map(item => {
          const { discountMicro, isDiscountAsPoints } = item;
          return {
            ...item,
            discount: formatCurrency({
              currencyCode,
              value: discountMicro,
            }),
            ...(isDiscountAsPoints && { points: microToPoints(discountMicro) }),
          };
        }),
    };
  },
);

export const getTotalPayableMicro = createSelector(getRawCart, cart => {
  if (!cart || !cart.financialInfo) {
    return null;
  }
  return cart.financialInfo.totalPayableMicro;
});

export const getTaxMicro = createSelector(
  getRawCart,
  cart => cart?.financialInfo?.taxMicro || null,
);

export const getSubtotalMicro = createSelector(
  getRawCart,
  cart => cart?.financialInfo?.subtotalMicro || null,
);

export const getCart = createSelector(
  getRawCart,
  remapFinancialInfo,
  remapCartItems,
  (cart, financialInfo, cartItems) => {
    if (!cart) {
      return null;
    }
    if (!financialInfo) {
      return cart;
    }
    return {
      ...cart,
      cartItems,
      financialInfo,
    };
  },
);

export const getCartNote = createSelector(
  getCartRoot,
  cart => cart && cart.note,
);

export const getCartError = createSelector(
  getCartRoot,
  cart => cart && cart.error,
);

export const getCartUpdating = createSelector(
  getCartRoot,
  cart => cart && cart.isUpdating,
);

export const getItemUpdating = createSelector(
  getCartRoot,
  cart => cart && cart.isUpdatingItem,
);

export const getSelectedTipOptionId = createSelector(
  getCart,
  cart => cart && cart.selectedTipOptionId,
);

export const getItemsInCart = createSelector(
  getCart,
  cart =>
    (cart &&
      cart.cartItems &&
      cart.cartItems.reduce(
        (previous, current) => previous + current.quantity,
        0,
      )) ||
    0,
);

export const getCartHasItems = createSelector(
  getItemsInCart,
  numItems => !!numItems,
);

export const getCartLoading = createSelector(
  getCartRoot,
  cart => cart && cart.isLoading,
);

export const getCartChecksum = createSelector(
  getCart,
  cart => cart && cart.cartChecksum,
);

export const getCartDeliveryChargeMicro = createSelector(
  getCart,
  cart => cart && cart.financialInfo && cart.financialInfo.deliveryChargeMicro,
);

export const getCartSubtotalMicro = createSelector(
  getCart,
  cart => cart && cart.financialInfo && cart.financialInfo.itemSumMicro,
);

export const getCartSubtotal = createSelector(
  getCartSubtotalMicro,
  getCurrencyCode,
  (value, currencyCode) => {
    if (value == null || !currencyCode) {
      return '';
    }
    return formatCurrency({
      value,
      currencyCode,
    });
  },
);

export const getIsPickupCart = createSelector(
  getCart,
  cart =>
    cart &&
    (cart.merchantFulfillmentType === FULFILLMENT_TYPE.IN_STORE ||
      cart.fulfillmentType === FULFILLMENT_TYPE.IN_STORE),
);

export const getIsDeliveryCart = createSelector(
  getCart,
  getFulfillmentDetails,
  (cart, fulfillment) => {
    // Use fulfillment type from group order (fulfillment) if present
    if (fulfillment) {
      return fulfillment.fulfillmentType === FULFILLMENT_TYPE.DELIVERY;
    }

    return (
      cart &&
      (cart.merchantFulfillmentType === FULFILLMENT_TYPE.DELIVERY ||
        cart.fulfillmentType === FULFILLMENT_TYPE.DELIVERY)
    );
  },
);

export const getIsDineInCart = createSelector(
  getCart,
  cart =>
    cart &&
    (cart.merchantFulfillmentType === FULFILLMENT_TYPE.DINE_IN ||
      cart.fulfillmentType === FULFILLMENT_TYPE.DINE_IN),
);

export const getCartFulfillmentType = createSelector(
  getCart,
  cart => cart && (cart.merchantFulfillmentType || cart.fulfillmentType),
);

export const getCartPickupFulfillmentEstimate = createSelector(
  getCart,
  getIsGroupOrder,
  (cart, isGroupOrder) => {
    if (isGroupOrder) {
      return null;
    }

    return cart && cart.pickupFulfillmentEstimate;
  },
);

export const getDeliveryAddressDetail = createSelector(
  getCart,
  getIsDeliveryCart,
  (cart, isDeliveryOrder) =>
    isDeliveryOrder && cart?.userDeliveryAddressDetail
      ? cart.userDeliveryAddressDetail
      : null,
);

export const getDeliveryAddressLine1 = createSelector(
  getCart,
  getIsDeliveryCart,
  getFulfillmentDeliveryAddressLine1,
  (cart, isDeliveryOrder, fulfillmentDeliveryAddress) => {
    // Show delivery address from fulfillment instead of cart if fulfillment is present
    if (fulfillmentDeliveryAddress) {
      return fulfillmentDeliveryAddress;
    }

    return isDeliveryOrder
      ? cart.userDeliveryAddressDetail &&
          cart.userDeliveryAddressDetail.userDeliveryAddressExtraInfoDetail &&
          cart.userDeliveryAddressDetail.userDeliveryAddressExtraInfoDetail
            .addressLine1
      : '';
  },
);

export const getFullDeliveryAddress = createSelector(
  getCart,
  getIsDeliveryCart,
  getFulfillmentFullDeliveryAddress,
  (cart, isDeliveryOrder, fulfillmentDeliveryAddress) => {
    // Show delivery address from fulfillment instead of cart if fulfillment is present
    if (fulfillmentDeliveryAddress) {
      return fulfillmentDeliveryAddress;
    }

    if (isDeliveryOrder) {
      if (
        cart?.userDeliveryAddressDetail?.userDeliveryAddressExtraInfoDetail
          ?.addressLine2
      ) {
        return `${cart.userDeliveryAddressDetail.userDeliveryAddressExtraInfoDetail.addressLine1}, ${cart.userDeliveryAddressDetail.userDeliveryAddressExtraInfoDetail.addressLine2}`;
      }
      return cart?.userDeliveryAddressDetail?.userDeliveryAddressExtraInfoDetail
        ?.addressLine1;
    }
    return '';
  },
);

export const getDeliveryMapLink = createSelector(
  getDeliveryAddressDetail,
  address =>
    address
      ? `https://maps.google.com/?q=${address.lat},${address.lng}&ll=${address.lat},${address.lng}`
      : '',
);

export const getUserDeliveryAddressId = createSelector(
  getRawCart,
  getIsDeliveryCart,
  (cart, isDeliveryOrder) =>
    isDeliveryOrder && cart?.userDeliveryAddressId
      ? cart.userDeliveryAddressId
      : null,
);

export const getPromo = createSelector(
  getCart,
  cart =>
    cart &&
    cart.cartCheckoutCodes &&
    cart.cartCheckoutCodes.length &&
    cart.cartCheckoutCodes[0],
);

export const getMinimumDeliveryOrderValueMicro = createSelector(
  remapFinancialInfo,
  financialInfo =>
    financialInfo && financialInfo.deliveryMinimumOrderValueMicro,
);

export const getMinimumPickupOrderValueMicro = createSelector(
  remapFinancialInfo,
  financialInfo => financialInfo && financialInfo.pickupMinimumOrderValueMicro,
);

export const getMaximumPickupOrderValueMicro = createSelector(
  remapFinancialInfo,
  financialInfo => financialInfo && financialInfo.pickupMaximumOrderValueMicro,
);

export const getDiscountTotalMicro = createSelector(
  remapFinancialInfo,
  financialInfo =>
    financialInfo?.discounts?.reduce(
      (acc, curr) => (curr ? acc + (curr?.discountMicro || 0) : acc),
      0,
    ) || 0,
);

export const getCartOrderValue = createSelector(
  remapFinancialInfo,
  financialInfo => financialInfo && financialInfo.subtotalMicro,
);

export const getMeetsMinimumDeliveryOrderValue = createSelector(
  remapFinancialInfo,
  getIsDeliveryCart,
  getCartOrderValue,
  (financialInfo, isDeliveryCart, cartOrderValue) =>
    !isDeliveryCart ||
    (financialInfo &&
      cartOrderValue >= financialInfo.deliveryMinimumOrderValueMicro),
);

export const getMeetsPickupOrderValueThreshold = createSelector(
  remapFinancialInfo,
  getIsDeliveryCart,
  getCartOrderValue,
  (financialInfo, isDeliveryCart, cartOrderValue) => {
    if (isDeliveryCart) {
      return true;
    }

    if (!financialInfo) {
      return false;
    }

    const higherThanMinimum =
      !financialInfo.pickupMinimumOrderValueMicro ||
      cartOrderValue >= financialInfo.pickupMinimumOrderValueMicro;

    const lowerThanMaximum =
      !financialInfo.pickupMaximumOrderValueMicro ||
      cartOrderValue <= financialInfo.pickupMaximumOrderValueMicro;

    return higherThanMinimum && lowerThanMaximum;
  },
);

export const getPickupMinCartItemCount = createSelector(
  getIsDeliveryCart,
  remapFinancialInfo,
  (isDelivery, financialInfo) =>
    isDelivery ? 0 : financialInfo?.pickupMinCartItemCount ?? 0,
);

export const getPickupMaxCartItemCount = createSelector(
  getIsDeliveryCart,
  remapFinancialInfo,
  (isDelivery, financialInfo) =>
    isDelivery ? 0 : financialInfo?.pickupMaxCartItemCount ?? 0,
);

export const getMeetsPickupItemCountCriteria = createSelector(
  getItemsInCart,
  getPickupMinCartItemCount,
  getPickupMaxCartItemCount,
  (numCartItems, pickupMinCartItemCount, pickupMaxCartItemCount) => {
    if (pickupMinCartItemCount > 0 && pickupMaxCartItemCount > 0) {
      return (
        numCartItems >= pickupMinCartItemCount &&
        numCartItems <= pickupMaxCartItemCount
      );
    }
    if (pickupMinCartItemCount > 0) {
      return numCartItems >= pickupMinCartItemCount;
    }
    if (pickupMaxCartItemCount > 0) {
      return numCartItems <= pickupMaxCartItemCount;
    }
    return true; // no item count restrictions
  },
);

export const getHasMinimumOrderValue = createSelector(
  getIsDeliveryCart,
  remapFinancialInfo,
  getHasDeliveryMinimumOrderValue,
  (isDeliveryCart, financialInfo, hasDeliveryMinimumOrderValue) => {
    if (isDeliveryCart) {
      return hasDeliveryMinimumOrderValue;
    }

    return !!financialInfo?.pickupMinimumOrderValueMicro;
  },
);

export const getHasMaximumOrderValue = createSelector(
  getIsDeliveryCart,
  remapFinancialInfo,
  (isDeliveryCart, financialInfo) =>
    isDeliveryCart ? false : !!financialInfo?.pickupMaximumOrderValueMicro,
);

export const getMinimumDeliveryOrderValue = createSelector(
  remapFinancialInfo,
  financialInfo => financialInfo && financialInfo.deliveryMinimumOrderValue,
);

export const getMinimumOrderValue = createSelector(
  getIsDeliveryCart,
  remapFinancialInfo,
  getDeliveryMinimumOrderValue,
  (isDeliveryCart, financialInfo, deliveryMinimumOrderValue) =>
    isDeliveryCart
      ? deliveryMinimumOrderValue
      : financialInfo?.pickupMinimumOrderValue,
);

export const getMaximumOrderValue = createSelector(
  getIsDeliveryCart,
  remapFinancialInfo,
  (isDeliveryCart, financialInfo) =>
    isDeliveryCart ? null : financialInfo?.pickupMaximumOrderValue,
);

export const getFreeOrderValueMicro = createSelector(
  remapFinancialInfo,
  financialInfo => financialInfo && financialInfo.freeDeliveryOrderValueMicro,
);

export const getMeetsFreeOrderValue = createSelector(
  remapFinancialInfo,
  getIsDeliveryCart,
  (financialInfo, isDeliveryCart) =>
    !isDeliveryCart || (financialInfo && !financialInfo.deliveryChargeMicro),
);

export const getFreeOrderValue = createSelector(
  remapFinancialInfo,
  financialInfo => financialInfo && financialInfo.freeDeliveryOrderValue,
);

export const getRemainingFreeOrderValue = createSelector(
  remapFinancialInfo,
  getCurrencyCode,
  (financialInfo, currencyCode) =>
    financialInfo &&
    formatCurrency({
      value:
        financialInfo.freeDeliveryOrderValueMicro - financialInfo.subtotalMicro,
      currencyCode,
    }),
);

export const getDiscounts = createSelector(
  remapFinancialInfo,
  financialInfo => financialInfo && financialInfo.discounts,
);

export const getCartScheduledForTime = createSelector(
  getCart,
  cart => cart && cart.scheduledForTime,
);

export const getScheduledDateTime = createSelector(
  getCartScheduledForTime,
  getFulfillmentScheduledTime,
  getMerchantTimeZone,
  (cartScheduledTime, fulfillmentScheduledTime, merchantTimeZone) => {
    if (fulfillmentScheduledTime) {
      return parseAndFormatUTC(
        fulfillmentScheduledTime,
        merchantTimeZone,
        FORMAT.ISO_DATE_TIME,
      );
    }

    if (cartScheduledTime) {
      return cartScheduledTime;
    }

    return null;
  },
);

export const getScheduledDateDaysAhead = createSelector(
  getCartScheduledForTime,
  getMerchantTimeZone,
  (datetime, timeZone) => {
    if (!datetime) return null;
    return getTzDifferenceInDays({
      endDate: datetime,
      format: TIME_FORMAT.ISO_DATE_TIME,
      timeZone,
    });
  },
);

export const getScheduledForTime = createSelector(
  getCartScheduledForTime,
  getMerchantTimeZone,
  getScheduledDateDaysAhead,
  getFulfillmentCartScheduledTime,
  (
    scheduledForTime,
    timeZone,
    scheduledDaysAhead,
    fulfillmentScheduledTime,
  ) => {
    // Show scheduled time from fulfillment instead of cart if fulfillment is present
    if (fulfillmentScheduledTime) {
      return fulfillmentScheduledTime;
    }

    if (!scheduledForTime) return null;
    const dayObj = parseDateString(
      scheduledForTime,
      TIME_FORMAT.ISO_DATE_TIME,
      timeZone,
    );
    return {
      date: formatDate(dayObj, TIME_FORMAT.DAY_MONTH_AND_TIME),
      isToday: scheduledDaysAhead === 0,
      isTomorrow: scheduledDaysAhead === 1,
    };
  },
);

export const getCanOrderNow = createSelector(
  getIsDeliveryCart,
  getMeetsPickupItemCountCriteria,
  getMeetsMinimumDeliveryOrderValue,
  getMeetsPickupOrderValueThreshold,
  getMerchantAvailable,
  getMerchantSupportsScheduling,
  getIsGroupOrder,
  (
    isDelivery,
    meetsPickupItemCountCriteria,
    meetsMinimumDeliveryOrderValue,
    meetsPickupOrderValueThreshold,
    isAvailable,
    supportsScheduling,
    isGroupOrder,
  ) => {
    if (isGroupOrder) {
      return true;
    }

    if (!isAvailable && !supportsScheduling) {
      return false;
    }

    if (isDelivery && meetsMinimumDeliveryOrderValue) {
      return true;
    }

    if (
      !isDelivery &&
      meetsPickupOrderValueThreshold &&
      meetsPickupItemCountCriteria
    ) {
      return true;
    }

    return false;
  },
);

export const getCanViewMobileCart = createSelector(
  getMerchantAvailable,
  getMerchantSupportsScheduling,
  getIsGroupOrder,
  (isAvailable, supportsScheduling, isGroupOrder) =>
    isGroupOrder || isAvailable || supportsScheduling,
);

export const getRequiresScheduling = createSelector(
  getScheduledForTime,
  getMerchantAvailable,
  getMerchantSupportsScheduling,
  (_, orderLaterOnlyEnabled) => orderLaterOnlyEnabled,
  (
    scheduledForTime,
    isAvailable,
    supportsScheduling,
    orderLaterOnlyEnabled,
  ) => {
    if (!supportsScheduling) return false;
    return (
      (!isAvailable && !scheduledForTime) ||
      (orderLaterOnlyEnabled && !scheduledForTime)
    );
  },
);

// Returns `true` if the items includes cutlery and false otherwise
export const getCutleryIncluded = createSelector(
  getRawCart,
  cart => cart?.includeCutlery,
);

// Returns `true` if the cart items supports cutlery and false otherwise
export const getCutlerySupported = createSelector(
  getRawCart,
  cart => cart?.includeCutlery != null,
);

export const getCartDeliveryInfo = createSelector(
  getCartRoot,
  cart => cart && cart.deliveryInfo,
);

export const getCartEstimatedDeliveryTimeRangeMinutes = createSelector(
  getCartDeliveryInfo,
  deliveryInfo => {
    if (!deliveryInfo || !deliveryInfo.estimatedDeliveryTimeRange) {
      return undefined;
    }
    const { minimum, maximum } = deliveryInfo.estimatedDeliveryTimeRange;
    if (!minimum || !maximum) {
      return undefined;
    }
    return {
      min: millisToNearestXMinutes(minimum, 5),
      max: millisToNearestXMinutes(maximum, 5),
    };
  },
);

// Returns fulfillmentId that was set on the cart (via the "PATCH /v1/cart/ID" request)
export const getCartFulfillmentId = createSelector(
  getRawCart,
  cart => cart?.fulfillmentId,
);

export const getPlacingOrder = createSelector(
  getCartRoot,
  cart => cart && cart.isPlacingOrder,
);

export const getPerkError = createSelector(
  getCartRoot,
  cart => cart?.perkError,
);
