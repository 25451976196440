import { put, takeLatest, call, getContext, select } from 'redux-saga/effects';
import {
  getGuestOrder,
  cancelGuestOrder,
  getOrder,
  cancelOrder as patchCancelOrder,
  getOrderWithToken,
  cancelOrderWithToken,
  continueOrder as patchContinueOrder,
  continueOrderWithToken,
} from '@artemis/api/athena';
import { getActiveOrders as fetchActiveOrders } from '@artemis/api/contentGateway';
import { ORDER_IDENTIFIER_TYPES } from '@artemis/utils/query/constants';
import {
  loadOrderError,
  loadOrderSuccess,
  cancelOrderError,
  continueOrderError,
  loadActiveOrdersSuccess,
  loadActiveOrdersError,
  activeOrdersInitialized,
  loadOrder,
  cancelOrder,
  continueOrder,
  loadActiveOrders,
} from './slice';
import { getActiveOrders, getOrderStatus } from './selectors';

export function* loadOrderSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const activeOrders = yield select(getActiveOrders);
    const { orderStatus: prevStatus } = yield select(getOrderStatus);
    let nextStatus;
    if (payload.orderIdentifierType === ORDER_IDENTIFIER_TYPES.ID) {
      const { data } = yield call(getOrder, apiClient, payload);
      yield put(loadOrderSuccess(data.order));
      nextStatus = data.order.orderStatus.orderStatus;
    } else if (payload.orderIdentifierType === ORDER_IDENTIFIER_TYPES.TOKEN) {
      const { data } = yield call(getOrderWithToken, apiClient, payload);
      yield put(loadOrderSuccess(data.order));
      nextStatus = data.order.orderStatus.orderStatus;
    } else {
      const { data } = yield call(getGuestOrder, apiClient, payload);
      yield put(loadOrderSuccess(data.guestOrder));
    }

    // My Orders Modal only shows when more than one order
    if (activeOrders.length > 1 && prevStatus !== nextStatus) {
      yield put(loadActiveOrders());
    }
  } catch (err) {
    yield put(
      loadOrderError(err?.response?.data?.error || { errorType: 'UNKNOWN' }),
    );
  }
}

export function* cancelOrderSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const activeOrders = yield select(getActiveOrders);
    if (payload.orderIdentifierType === ORDER_IDENTIFIER_TYPES.ID) {
      const { data } = yield call(patchCancelOrder, apiClient, payload);
      yield put(loadOrderSuccess(data.order));
    } else if (payload.orderIdentifierType === ORDER_IDENTIFIER_TYPES.TOKEN) {
      const { data } = yield call(cancelOrderWithToken, apiClient, payload);
      yield put(loadOrderSuccess(data.order));
    } else {
      const { data } = yield call(cancelGuestOrder, apiClient, payload);
      yield put(loadOrderSuccess(data.guestOrder));
    }

    // My Orders Modal only shows when more than one order
    if (activeOrders.length > 1) {
      yield put(loadActiveOrders());
    }
  } catch (err) {
    yield put(cancelOrderError(err));
  }
}

// TODO(IE-2549): remove this saga and the associated redux actions/reducers
export function* continueOrderSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    if (payload.orderIdentifierType === ORDER_IDENTIFIER_TYPES.ID) {
      yield call(patchContinueOrder, apiClient, payload);
      const { data } = yield call(getOrder, apiClient, payload);
      yield put(loadOrderSuccess(data.order));
    } else if (payload.orderIdentifierType === ORDER_IDENTIFIER_TYPES.TOKEN) {
      yield call(continueOrderWithToken, apiClient, payload);
      const { data } = yield call(getOrderWithToken, apiClient, payload);
      yield put(loadOrderSuccess(data.order));
    } else {
      throw new Error(
        `continue not supported for order type: ${payload.orderIdentifierType}`,
      );
    }
  } catch (err) {
    yield put(continueOrderError(err));
  }
}

export function* loadActiveOrdersSaga() {
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(fetchActiveOrders, apiClient);
    yield put(loadActiveOrdersSuccess({ data }));
  } catch (err) {
    yield put(loadActiveOrdersError(err));
  } finally {
    yield put(activeOrdersInitialized());
  }
}

export default function* orderData() {
  yield takeLatest(loadOrder.type, loadOrderSaga);
  yield takeLatest(cancelOrder.type, cancelOrderSaga);
  yield takeLatest(continueOrder.type, continueOrderSaga);
  yield takeLatest(loadActiveOrders.type, loadActiveOrdersSaga);
}
