import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { ORDER_SOURCE } from '@artemis/utils/constants';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
  cancelError: null,
  continueError: null,
  source: {
    orderSource: ORDER_SOURCE.RITUAL_ONE,
    sourceId: undefined,
    origin: undefined,
  },
  // active carts and orders to show in the "order bar"
  activeOrders: {
    data: null,
    error: null,
    initialized: false,
    modalOpen: false,
  },
};

const updateOrderReducer = state => {
  state.isLoading = true;
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    loadOrder: updateOrderReducer,
    cancelOrder: updateOrderReducer,
    continueOrder: updateOrderReducer,
    loadOrderSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadOrderError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    cancelOrderError: (state, action) => {
      state.isLoading = false;
      state.cancelError = action.payload;
    },
    continueOrderError: (state, action) => {
      state.isLoading = false;
      state.continueError = action.payload;
    },
    setSource: (state, action) => {
      state.source.orderSource = action.payload.orderSource;
      state.source.sourceId = action.payload.sourceId;
      state.source.subdomain = action.payload.subdomain;
      state.source.subdomainId = action.payload.subdomainId;
      state.source.origin = action.payload.origin;
    },
    loadActiveOrders: () => {
      // intentionally empty
    },
    loadActiveOrdersSuccess: (state, action) => {
      state.activeOrders.data = action.payload.data;
      state.activeOrders.error = null;
    },
    loadActiveOrdersError: (state, action) => {
      state.activeOrders.error = action.payload.error;
    },
    setMyOrdersModal: (state, action) => {
      state.activeOrders.modalOpen = action.payload.isOpen;
    },
    activeOrdersInitialized: state => {
      state.activeOrders.initialized = true;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.order);
  },
});

export const {
  loadOrder,
  cancelOrder,
  continueOrder,
  loadOrderSuccess,
  loadOrderError,
  cancelOrderError,
  continueOrderError,
  setSource,
  loadActiveOrders,
  loadActiveOrdersSuccess,
  loadActiveOrdersError,
  setMyOrdersModal,
  activeOrdersInitialized,
} = orderSlice.actions;

export default orderSlice.reducer;
