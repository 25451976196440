import { css } from 'styled-components';

const fontWeightRegular = 400;
const fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';

const overflowEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const lineClamp = lines => css`
  /* Don't add padding when using this */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
`;

export default {
  htmlFontSize: 16,
  fontFamily,
  silka: {
    regular: `silkaregular, ${fontFamily}`,
    medium: `silkamedium, ${fontFamily}`,
    semibold: `silkasemibold, ${fontFamily}`,
    bold: `silkabold, ${fontFamily}`,
    black: `silkablack, ${fontFamily}`,
  },
  poppins: `Poppins, ${fontFamily}`,
  fontWeightLight: 300,
  fontWeightRegular,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  h1: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '2.125rem',
    lineHeight: 1.1,
  },
  h2: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '2rem',
    lineHeight: 1.2,
  },
  h3: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1.75rem',
    lineHeight: 1.2,
  },
  h4: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1.5rem',
    lineHeight: 1.28,
  },
  h5: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1.375rem',
    lineHeight: 1.41,
  },
  h6: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1.25rem',
    lineHeight: 1.4,
  },
  subtitle1: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1rem',
    lineHeight: 1.75,
  },
  subtitle2: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '0.875rem',
    lineHeight: 1.57,
  },
  bodyLarge: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1.25rem',
    lineHeight: 1.4,
  },
  body: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  bodySmall: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  button: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '1rem',
    lineHeight: 1.4,
  },
  caption: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '0.75rem',
    lineHeight: 1.5,
  },
  overhead: {
    fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: '0.625rem',
    lineHeight: 1.5,
  },
  overflowEllipsis,
  lineClamp,
};
