import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import {
  getAllIncentives,
  getAllIncentivesForCart,
  updateCartIncentives,
} from '@artemis/api/athena';
import { loadCart } from '@artemis/store/cart/slice';
import { addToast } from '@artemis/store/toasts/slice';
import {
  loadIncentives,
  loadIncentivesError,
  loadIncentivesSuccess,
  updateIncentives,
  updateIncentivesSuccess,
  updateIncentivesError,
  toggleListModal,
} from './slice';

export function* loadIncentivesSaga({ payload }) {
  try {
    const { merchantId, cartCheckoutCode, forCart } = payload;

    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(
      forCart ? getAllIncentivesForCart : getAllIncentives,
      apiClient,
      { merchantId, ...(cartCheckoutCode && { cartCheckoutCode }) },
    );

    yield put(loadIncentivesSuccess(data));
  } catch (err) {
    yield put(loadIncentivesError(err));
  }
}

export function* updateIncentivesSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(updateCartIncentives, apiClient, payload);
    yield put(updateIncentivesSuccess(data));

    // Reload cart to get updated incentives in cart object
    yield put(loadCart({ merchantId: payload.merchantId, isUpdate: true }));

    // Close incentives modal after saving successfully
    yield put(toggleListModal({ isOpen: false }));
  } catch (err) {
    yield put(updateIncentivesError(err));
    yield put(addToast({ toast: { error: { message: 'errors.tryAgain' } } }));
  }
}

export default function* incentives() {
  yield takeLatest(loadIncentives.type, loadIncentivesSaga);
  yield takeLatest(updateIncentives.type, updateIncentivesSaga);
}
