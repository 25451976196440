import { put, takeLatest, call, getContext, select } from 'redux-saga/effects';
import {
  addDeliveryAddress,
  deleteDeliveryAddress,
  fetchDeliveryAddresses,
} from '@artemis/api/athena';
import { setFulfillment } from '@artemis/store/fulfillment/slice';
import { updateFulfillment } from '@artemis/store/groupOrder/slice';
import { getFulfillmentId } from '@artemis/store/groupOrder/selectors';
import { merchantDeliveryInfoLoading } from '@artemis/store/merchant/slice';
import { addToast } from '@artemis/store/toasts/slice';
import { FULFILLMENT_TYPE } from '@artemis/utils/constants';
import {
  loadDeliveryAddresses,
  loadDeliveryAddressesError,
  loadDeliveryAddressesSuccess,
  removeDeliveryAddress,
  createDeliveryAddress,
  updateDeliveryAddressesError,
  updateDeliveryAddressesSuccess,
} from './slice';
import { getDeliveryAddresses } from './selectors';

export function* loadDeliveryAddressesSaga({
  payload: shouldLoad = true,
} = {}) {
  if (shouldLoad) {
    try {
      const apiClient = yield getContext('athenaApiClient');
      const { data } = yield call(fetchDeliveryAddresses, apiClient);
      yield put(loadDeliveryAddressesSuccess(data));
    } catch (err) {
      yield put(loadDeliveryAddressesError(err));
      yield put(addToast({ toast: { error: { message: 'errors.tryAgain' } } }));
    }
  } else {
    yield put(loadDeliveryAddressesSuccess(null));
  }
}

export function* removeDeliveryAddressSaga({ payload: addressId }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    yield call(deleteDeliveryAddress, apiClient, { addressId });

    yield put(updateDeliveryAddressesSuccess());
  } catch (err) {
    yield put(
      updateDeliveryAddressesError(
        err?.response?.data || { error: { errorBody: { reason: 'UNKNOWN' } } },
      ),
    );
  }
}

export function* createDeliveryAddressSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { deliveryAddressDetail, merchantId } = payload;
    const { data } = yield call(
      addDeliveryAddress,
      apiClient,
      deliveryAddressDetail,
    );

    // If this is the user's first delivery address, we need to refetch
    // the merchant's delivery info for the merchant
    const deliveryAddresses = yield select(getDeliveryAddresses);
    if (deliveryAddresses.length === 0) {
      yield put(merchantDeliveryInfoLoading({ merchantId }));
    }

    yield put(updateDeliveryAddressesSuccess());

    if (payload.forCart) {
      const { deliveryAddressId } = data.address;
      yield put(
        setFulfillment({
          merchantId,
          merchantFulfillmentType: FULFILLMENT_TYPE.DELIVERY,
          userDeliveryAddressId: deliveryAddressId,
        }),
      );
    }

    if (payload.forGroupOrder) {
      const { deliveryAddressId } = data.address;
      const fulfillmentId = yield select(getFulfillmentId);
      yield put(
        updateFulfillment({
          fulfillmentId,
          fulfillment: {
            fulfillmentType: FULFILLMENT_TYPE.DELIVERY,
            deliveryDetails: {
              locationId: deliveryAddressId,
            },
          },
        }),
      );
    }
  } catch (err) {
    yield put(
      updateDeliveryAddressesError(
        err?.response?.data || { error: { errorBody: { reason: 'UNKNOWN' } } },
      ),
    );
  }
}

export default function* deliveryAddressesData() {
  yield takeLatest(loadDeliveryAddresses.type, loadDeliveryAddressesSaga);
  yield takeLatest(removeDeliveryAddress.type, removeDeliveryAddressSaga);
  yield takeLatest(
    updateDeliveryAddressesSuccess.type,
    loadDeliveryAddressesSaga,
  );
  yield takeLatest(createDeliveryAddress.type, createDeliveryAddressSaga);
}
