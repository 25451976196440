export default {
  shadow0: '0px 2px 4px rgba(8, 35, 48, 0.24)',
  shadow1: '0px 4px 8px rgba(8, 35, 48, 0.24)',
  shadow2: '0px 8px 16px rgba(8, 35, 48, 0.2)',
  shadow3: '0px 16px 24px rgba(8, 35, 48, 0.16)',
  shadow4: '0px 24px 32px rgba(8, 35, 48, 0.12)',
  shadow5: '0px 2px 10px rgba(0, 0, 0, 0.15)',
  shadow6: '0px 6px 18px 0px rgba(219,222,229,0.4)',
  shadowN1: '0px 1px 8px rgba(0, 0, 0, 0.08)',
};
