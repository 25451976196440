import { createClient } from 'contentful';

const client = {
  client: null,
  getClient: () => {
    if (client.client) {
      return client.client;
    }
    return client.createClient();
  },
  createClient: () => {
    client.client = createClient({
      space: process.env.RT_CONTENTFUL_SPACE,
      environment: process.env.RT_CONTENTFUL_ENV,
      accessToken: process.env.RT_CONTENTFUL_TOKEN,
      host: process.env.RT_CONTENTFUL_HOST,
    });
    return client.client;
  },
};

export default client;
