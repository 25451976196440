/** ----------------------------------------------------------------------------------
 * Next.js Path Parameters
 * Some of these may also be used as query parameters */

/** The second path param that represents the merchant */
export const MID_PARAM = 'mid';

export const WORKSPACE_ID_PARAM = 'workspaceId';

export const CATALOG_ID_PARAM = 'catalogId';

/** Path param for merchant group ID */
export const MERCHANT_GROUP_PARAM = 'group';

/** Marketplace page filter merchants by city */
export const FILTER_CITY_PARAM = 'city';

/** Marketplace page filter merchants by cuisine */
export const FILTER_CUISINE_PARAM = 'cuisine';

/** Marketplace page filter merchants by group ordering enabled */
export const FILTER_CATERING_PARAM = 'catering';

export const FULFILLMENT_ID_PARAM = 'fulfillmentId';

/** ----------------------------------------------------------------------------------
 * Embeded Query Parameters
 * How it works: https://ritualco.atlassian.net/l/cp/PoEzy85u
 * Testing locally: https://ritualco.atlassian.net/l/cp/u0q0jKKA */

/** Configures Keycloak with HqO when `embed=hqo` or Ritual SDK if another defined value  */
export const EMBED_PARAM = 'embed';

/** Hides sign out links for HqO */
export const HQO_LINKOUT_PARAM = 'hqoLinkout';

/** Uses stubbed implementation of JS Bridge / SDK for local testing */
export const EMBED_LOCAL_TEST = 'embedLocalTest';

/** ----------------------------------------------------------------------------------
 * Query Parameter Driven Display Modes
 * Docs: https://ritualco.atlassian.net/l/cp/Ku1sCK8W */

/** Sets the dine-in input value */
export const DINE_IN_DESCRIPTION_PARAM = 'did';

/** Sets the dine-in input label */
export const DINE_IN_TYPE_PARAM = 'edid';
export const DINE_IN_TYPES = {
  /** "Your room number" */
  ROOM: 'room',
  /** "Your table number" */
  TABLE: 'table',
  // Custom values set the input label entirely
};

/** Disables pickup order option */
export const HIDE_PICKUP_PARAM = 'hidePickup';

/** Attempt to set fulfillment type to delivery upon page load */
export const FORCE_DELIVERY_PARAM = 'forceDelivery';

/**
 * Used in conjunction with dine-in params to display Pickup fulfillment option
 * instead of Dine-in while in dine-in mode
 */
export const PICKUP_PARAM = 'pickup';

/** Requires order to be scheduled */
export const ORDER_LATER_PARAM = 'orderLater';

/** Hides the order bar (just for debugging/testing) */
export const SUPPRESS_ORDER_BAR_PARAM = 'suppressOrderBar';

/** ----------------------------------------------------------------------------------
 * Branch.io Deep Link Redirection Query Parameters
 * Docs: https://help.branch.io/using-branch/docs/creating-a-deep-link#section-redirections */

export const BRANCH_ANDROID_PARAM = '$android_url';

export const BRANCH_IOS_PARAM = '$ios_url';
/** ---------------------------------------------------------------------------------- */

/** Query param used in group ordering to load the invite */
export const INVITE_LINK_ID_PARAM = 'invite_id';

export const MENU_DEBUG_PARAM = 'debugreview';

export const MENU_DRAFT_ID_PARAM = 'menudraftid';

/** Query param that persists an action to perform after login */
export const ON_LOG_IN_PARAM = 'onLogIn';
/** Values for `ON_LOG_IN_PARAM` */
export const ON_LOG_IN_VALUES = {
  /** Redirects to create group order page */
  CREATE_GROUP: 'createGroup',
};

/** Query param that causes the group order invite modal to open in the group order status page */
export const GROUP_ORDER_SHARE_PARAM = 'share';

/** Query param that causes the group order invite modal to open in the menu page */
export const MENU_GROUP_ORDER_INVITE_PARAM = 'goShare';

/** Query param that indicates that the UI should show info about a feature */
export const FEATURE_INFO_PARAM = 'learn';
export const FEATURE_INFO_VALUES = {
  /** Opens group order host info modal */
  GROUP_ORDERING: 'groupOrdering',
};

/** Query param that indicates a user is already physically in the store */
export const IN_STORE_PARAM = 'inStore';

/**
 * Query param that indicates a user has already seen the in store ordering express service modal
 * This stops the ExpressServiceModal from showing up on the menu page
 */
export const HIDE_EXPRESS_SERVICE_PARAM = 'hideExpressServiceModal';

/**
 * Query param that indicates the "Download the app" modal should not be shown
 * This is used with some QR code ordering assets
 */
export const HIDE_DOWNLOAD_APP_PARAM = 'hideDownloadApp';

/**
 * Query param that indicates that the UI should hide the dine-in toggle.
 * This only impacts the UI when `IN_STORE_PARAM` is also present
 */
export const HIDE_DINE_IN_TOGGLE_PARAM = 'hideDineInToggle';

/** Query param to disable login at checkout (excludes HqO) */
export const CHECKOUT_LOGIN_PARAM = 'checkoutLogin';

/**
 * The building portal admin owner unique identifier. The owner is an administrator
 * that cannot be removed or altered by other portal admins.
 */
export const OWNER_ID_PARAM = 'ownerId';

/** Query param that controls whether to display a specific item on the menu page */
export const MENU_ITEM_ID_QUERY_PARAM = 'item';

/** Query param that indicates the user's entry point */
export const ORIGIN_PARAM = 'origin';
export const ORIGIN_VALUES = {
  /** User entered through the mobile app */
  APP: 'app',
};

/** Query param that allows testing GO consumer flow on menu page when creating GO */
export const GO_CONSUMER_FLOW_PARAM = 'goConsumerFlow';

/**
 * Uses stubbed implementation of Stripe Payment Request for local testing
 * of express checkout
 */
export const EXPRESS_LOCAL_TEST = 'expressLocalTest';

/** Pass this promo code to keycloak to associate it with user signup */
export const PROMO_PARAM = 'promo';
export const PROMO_VALUES = {
  GET_RITUAL: 'GET-RITUAL',
};

export const PROMO_CODE_PARAM = 'promoCode';

export const EMAIL_CODE_PARAM = 'emailCode';

/** List page location google place id */
export const PLACE_ID_PARAM = 'placeId';

/** Marketplace/list page filter merchants by search query */
export const FILTER_SEARCH_PARAM = 'q';

/** Marketplace/list page filter merchants that are open now */
export const FILTER_OPEN_PARAM = 'on';

/** Marketplace/list page filter merchants that offer pickup */
export const FILTER_PICKUP_PARAM = 'op';

/** Marketplace/list page filter merchants that offer delivery */
export const FILTER_DELIVERY_PARAM = 'od';

/** Marketplace/list page filter merchants by latitude */
export const FILTER_LATITUDE_PARAM = 'lat';

/** Marketplace/list page filter merchants by longitude */
export const FILTER_LONGITUDE_PARAM = 'lon';

/** Marketplace page filter top merchants only */
export const FILTER_TOP_PARAM = 'top';

/** Query param for merchant group ID used to refer back to the group page */
export const MERCHANT_GROUP_REFERER_PARAM = 'r';

/** Theme override */
export const THEME_JSON_PARAM = 'themeJSON';

export const LANGUAGE_PARAM = 'lang';

export const CLAIM_PARAM = 'claim';

/** Code key used at checkout, can be mapped to a code */
export const CHECKOUT_CODE_PARAM = 'p';

/** Show "Back" link on terms/privacy pages in PM apps */
export const BACK_PARAM = 'back';

/** Stores the previous url to redirect back to */
export const BACK_URL_PARAM = 'backUrl';

/** Show "Back" link on status page to go back to the menu/checkout pages */
export const FROM_PARAM = 'from';
export const FROM_VALUES = {
  MENU: 'menu',
  CHECKOUT: 'checkout',
};

/** List type used to display different sections in the list page */
export const LIST_TYPE_PARAM = 'listType';
export const LIST_TYPE_VALUES = {
  BUILDING: 'building',
  NEARBY: 'nearby',
};

/** Used when joining team is successful. The team name is passed as the value */
export const JOIN_TEAM_SUCCESS_PARAM = 'teamJoinSuccess';

/** Query param that holds the order ID, can be of different types */
export const ORDER_IDENTIFIER_PARAM = 'token';

/** Indicates the type of order ID */
export const ORDER_IDENTIFIER_TYPE_PARAM = 'orderIdentifierType';
export const ORDER_IDENTIFIER_TYPES = {
  ID: 'ID',
  TOKEN: 'TOKEN',
  GUEST_TOKEN: 'GUEST_TOKEN',
};

/** Indicates which platform the user verified their team in */
export const VERIFICATION_SOURCE_TYPE_PARAM = 'sourceType';
export const VERIFICATION_SOURCE_TYPES = {
  RITUAL_WEB: 'RITUAL_WEB',
  RITUAL_APP: 'RITUAL_APP',
  ORGANIZATION: 'ORGANIZATION', // PM app or PM subdomain
};

/** External query param to track user across platforms */
export const ENTITY_ID_PARAM = 'entity_id';

/** Switches fulfillment to delivery with the address set */
export const DELIVERY_ADDRESS_ID_PARAM = 'deliveryAddressId';

export const REFERRER_PARAM = 'referrer';

export const PREVIEW_ADDRESS_PARAM = 'address';
export const PREVIEW_MERCHANT_NAME_PARAM = 'merchantName';
export const PREVIEW_MERCHANT_PREVIEW_ID_PARAM = 'merchantPreviewId';
export const PREVIEW_COUNTRY_CODE_PARAM = 'countryCode';

/**
 * A generic identifier that could refer to a promo, perk, or promotion campaign
 * This ID is set as a cookie and passed to all Athena requests
 * */
export const PROMO_OFFER_ID_PARAM = 'offer';
