/**
 * HqO SDK Documentation:
 * https://drive.google.com/file/d/1DMWkC9XCiQADP_LLkJ7sk29agJDyaOyy/view?usp=sharing
 */
import { loadSDK } from './sdk';

let instance = null;

const getInstance = SDK =>
  new SDK({
    app_uuid: process.env.RT_HQO_DEVELOPER_UUID,
    develop: process.env.RT_ENVIRONMENT === 'development',
  });

const handlers = {
  onInit: () => {},
  getPaymentMethods: () => {},
  tokenizeCard: () => {},
  showNativePayment: () => {},
  getStorage: () => {},
};

export const init = () =>
  new Promise(resolve => {
    handlers.onInit = resolve;
    instance.init();
  });

export const getPaymentMethods = () =>
  /*
    Example response:
    {
      cards: [
        {
          card_last_four: '6782',
          card_brand: 'VISA',
          token_id: 'HQO_CARD_ID', // needs to be tokenized with tokenizeCard call
        },
      ],
      apple_pay: true,
      google_pay: false,
    }
  */
  new Promise(resolve => {
    handlers.getPaymentMethods = resolve;
    instance.payments.getPaymentMethods();
  });

export const tokenizePayment = ({ paymentId }) =>
  /*
    Example response:
    {
      card_id: 'HQO_CARD_ID',
      token: 'STRIPE_PAYMENT_METHOD_ID',
    }
  */
  new Promise(resolve => {
    handlers.tokenizeCard = resolve;
    instance.payments.tokenizeCard(paymentId);
  }).then(result => result?.token);

export const getApplePayAuthorization = ({
  currencyCode,
  total,
  subtotal,
  tax,
} = {}) =>
  /*
    Example response:
    {
      success: true,
      id: 'HQO_VAULTED_APPLE_PAY_ID',
    }
  */
  new Promise(resolve => {
    handlers.showNativePayment = resolve;
    instance.payments.showNativePayment('APPLE-VAULTED', {
      currency: currencyCode,
      authorization_total: total,
      subtotal,
      tax,
    });
  });

export const getStorage = ({ key }) =>
  new Promise(resolve => {
    handlers.getStorage = resolve;
    instance.storage.get(key);
  });

export const setStorage = ({ key, value }) => {
  instance.storage.set(key, value);
  return Promise.resolve();
};

export const setupClient = async ({ useStubs = false } = {}) => {
  const SDK = await loadSDK({ useStubs });

  instance = getInstance(SDK);

  instance.on('initResponse', data => handlers.onInit(data));
  instance.on('getPaymentMethodsResponse', data =>
    handlers.getPaymentMethods(data),
  );
  instance.on('tokenizeCardResponse', data => handlers.tokenizeCard(data));
  instance.on('showNativePaymentResponse', data =>
    handlers.showNativePayment(data),
  );
  instance.on('getStorageResponse', data => handlers.getStorage(data));
};
