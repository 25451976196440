import { EMBED_INTEGRATION_TYPE } from '@artemis/integrations/embed/constants';

export const getIntegrationTypeFromEmbedValue = value => {
  if (
    typeof value === 'string' &&
    value?.toUpperCase() === EMBED_INTEGRATION_TYPE.HQO
  ) {
    return EMBED_INTEGRATION_TYPE.HQO;
  }

  return EMBED_INTEGRATION_TYPE.DEFAULT;
};
