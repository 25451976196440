import pino from 'pino';

const getCloudProjectId = () => process.env.GOOGLE_CLOUD_PROJECT || '';

export const getTrace = ({ req }) => {
  const traceHeader = req.headers['x-cloud-trace-context'] || '';
  const traceId = traceHeader ? traceHeader.split('/')[0] : '';
  const projectId = getCloudProjectId();

  if (!traceId || !projectId) {
    return {};
  }

  return {
    'logging.googleapis.com/trace': `projects/${projectId}/traces/${traceId}`,
  };
};

const severityMap = {
  error: 'ERROR',
  warn: 'WARNING',
  info: 'INFO',
  debug: 'DEBUG',
};

const rootLogger = pino({
  messageKey: 'message',
  formatters: {
    level(label) {
      return { severity: severityMap[label] || 'INFO' };
    },
  },
});

export const getLogger = ({ req }) =>
  rootLogger.child({
    ...getTrace({ req }),
  });

export const logFailedRequest = ({ logger, err }) => {
  if (!logger || !err || !err.request || !err.response) {
    return;
  }

  try {
    const { request, response, message } = err;
    const responseTraceId = response.headers?.['x-b3-traceid'];

    // See: https://cloud.google.com/logging/docs/structured-logging#special-payload-fields
    logger.error({
      message,
      responseTraceId,
      httpRequest: {
        requestMethod: request.method,
        requestUrl: `${request.protocol}//${request.host}${request.path}`,
        status: response.status,
      },
    });
  } catch (e) {
    console.error('error logging failed request: ', e); // eslint-disable-line no-console
  }
};
