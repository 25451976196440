/**
 * Cart Sagas
 */

import { put, takeLatest, call, getContext, select } from 'redux-saga/effects';
import {
  fetchCart,
  fetchCartDeliveryInfo,
  addToCart as postCartItem,
  removeFromCart as deleteCartItem,
  modifyItem,
  placeOrder as postOrder,
  placeGuestOrder,
  setTip as updateCartTip,
  addPromo as updateCartPromo,
  removePromo as removeCartPromo,
  convertCart,
  patchCart,
} from '@artemis/api/athena';
import { authenticated } from '@artemis/store/auth/slice';
import { notifyPaymentComplete } from '@artemis/integrations/embed/webviewHooks';
import { EMBEDDED_PAYMENT_METHODS } from '@artemis/integrations/embed/webviewHooks/constants';
import { getIsEmbedded } from '@artemis/store/embed/selectors';
import { setLocalSelections } from '@artemis/store/localSelections/slice';
import { loadMenu } from '@artemis/store/menu/slice';
import { getActiveMenuItemObject } from '@artemis/store/menu/selectors';
import { getMerchantId } from '@artemis/store/merchant/selectors';
import { loadIncentives } from '@artemis/store/incentives/slice';
import { getCookieIds } from '@artemis/utils/analytics';
import {
  FULFILLMENT_TYPE,
  PAYMENT_OPTION_TYPE,
} from '@artemis/utils/constants';
import { handleStripePaymentChallenge } from '@artemis/utils/payment';
import { getCheckoutCodeFromQuery } from '@artemis/utils/promo';
import {
  ORDER_IDENTIFIER_TYPES,
  ORDER_IDENTIFIER_TYPE_PARAM,
} from '@artemis/utils/query/constants';
import {
  loadCart,
  cartLoaded,
  loadCartSuccess,
  loadCartError,
  addToCart,
  updateCartSuccess,
  updateCartError,
  modifyItemInCart,
  removeFromCart,
  placeOrder,
  placeOrderSuccess,
  placeOrderError,
  setTip,
  addPromo,
  removePromo,
  toggleCutlery,
  cartDeliveryInfoLoading,
  cartDeliveryInfoLoaded,
  associateFulfillment,
  perkError,
} from './slice';

export function* loadCartSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchCart, apiClient, payload);
    if (payload.isUpdate) {
      yield put(updateCartSuccess(data));
    } else {
      yield put(loadCartSuccess(data));
    }
    // To avoid stale menu data, we need to reload
    // the menu if the cart is scheduled for later
    if (data.scheduledForTime) {
      yield put(loadMenu());
    }
    if (
      data.fulfillmentType === FULFILLMENT_TYPE.DELIVERY &&
      !data.scheduledForTime
    ) {
      yield put(cartDeliveryInfoLoading(payload));
    }
  } catch (error) {
    if (payload.isUpdate) {
      yield put(updateCartError({ error }));
    } else {
      yield put(loadCartError({ error }));
    }
  }
}

export function* loadCartDeliveryInfoSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchCartDeliveryInfo, apiClient, payload);
    yield put(cartDeliveryInfoLoaded(data));
  } catch {
    yield put(cartDeliveryInfoLoaded(null));
  }
}

export function* loadConvertedCartSaga({ payload }) {
  const merchantId = yield select(getMerchantId);
  // Do not attempt if there is no merchant ID (e.g. non-merchant pages)
  if (merchantId) {
    try {
      const apiClient = yield getContext('athenaApiClient');
      // Attempt to convert the guest cart to a user cart
      yield call(convertCart, apiClient, { merchantId });
    } catch {
      // Fail silently - if the cart conversion fails, the user will simply
      // be given a fresh cart
    }
    // Fetch the user's cart regardless if cart conversion fails or succeeds
    yield call(loadCartSaga, { payload: { merchantId } });

    // Reload incentives for the updated cart
    const cartCheckoutCode = getCheckoutCodeFromQuery(payload?.query || {});
    yield put(loadIncentives({ merchantId, forCart: true, cartCheckoutCode }));
  } else {
    // Prevent any cart-related loading states from lingering
    yield put(cartLoaded());
  }
}

export function* addToCartSaga({
  payload: {
    merchantId,
    item,
    removeConflictingPerks,
    isGuestUser,
    isLocalSelectionsEnabled,
  },
}) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(postCartItem, apiClient, {
      merchantId,
      item: { ...item, removeConflictingPerks },
    });
    yield put(updateCartSuccess(data));

    if (isGuestUser && isLocalSelectionsEnabled) {
      yield put(
        setLocalSelections({
          merchantId,
          itemId: item.menuItemId,
          optionIds: item.cartItemOptions.map(option => option.id),
        }),
      );
    }
  } catch (error) {
    if (error?.response?.data?.error?.errorType === 'INVALID_CART_ITEM_PERK') {
      const { perk } = yield select(getActiveMenuItemObject);
      const { displayHint } = perk;
      yield put(perkError({ error, merchantId, item, displayHint }));
    } else {
      yield put(updateCartError({ error }));
    }
  }
}

export function* removeFromCartSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(deleteCartItem, apiClient, payload);
    yield put(updateCartSuccess(data));
  } catch (error) {
    yield put(updateCartError({ error }));
  }
}

export function* modifyItemInCartSaga({
  payload: {
    merchantId,
    cartItemId,
    item,
    removeConflictingPerks,
    isGuestUser,
    isLocalSelectionsEnabled,
  },
}) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(modifyItem, apiClient, {
      merchantId,
      cartItemId,
      item: { ...item, removeConflictingPerks },
    });
    yield put(updateCartSuccess(data));

    if (isGuestUser && isLocalSelectionsEnabled) {
      yield put(
        setLocalSelections({
          merchantId,
          itemId: item.menuItemId,
          optionIds: item.cartItemOptions.map(option => option.id),
        }),
      );
    }
  } catch (error) {
    if (error?.response?.data?.error?.errorType === 'INVALID_CART_ITEM_PERK') {
      const { perk } = yield select(getActiveMenuItemObject);
      const { displayHint } = perk;
      yield put(
        perkError({ error, merchantId, cartItemId, item, displayHint }),
      );
    } else {
      yield put(updateCartError({ error }));
    }
  }
}

export const handleOrderChallenge = async (placeOrderChallenge, payload) => {
  const {
    stripeChallenge: { clientSecret, apiKey },
  } = placeOrderChallenge;

  const { stripeId } = await handleStripePaymentChallenge({
    apiKey,
    clientSecret,
  });

  const challengeAnswer = {
    stripeChallengeAnswer: {
      stripeId,
    },
    challengeMetadataReplay: placeOrderChallenge.challengeMetadata,
  };
  return {
    ...payload,
    order: {
      ...payload.order,
      challengeAnswer,
    },
  };
};

export function* placeOrderSaga({ payload }) {
  const isEmbedded = yield select(getIsEmbedded);
  const paymentOptionType =
    payload?.order?.paymentMethod?.paymentMethodIdentifier?.paymentOptionType;
  const appendQuery = (query, idType) => {
    const params = `${ORDER_IDENTIFIER_TYPE_PARAM}=${idType}`;
    if (query) {
      return `${query}&${params}`;
    }
    return `?${params}`;
  };

  try {
    const apiClient = yield getContext('athenaApiClient');

    if (payload.authenticated) {
      const modifiedPayload = {
        ...payload,
        order: {
          ...payload.order,
          subscribedToMerchantMarketingEmails:
            payload.order?.guestProfile?.subscribedToEmail || false,
          guestProfile: null,
          externalId: getCookieIds().euid,
        },
      };

      const { data } = yield call(postOrder, apiClient, modifiedPayload);
      let { orderId } = data;
      // replay order with challenge answer if challenge is retuned in response
      if (data.placeOrderChallenge) {
        const payloadWithChallengeAnswer = yield call(
          handleOrderChallenge,
          data.placeOrderChallenge,
          modifiedPayload,
        );
        const response = yield call(
          postOrder,
          apiClient,
          payloadWithChallengeAnswer,
        );
        orderId = response.data.orderId;
      }

      // For embedded Apple Pay, call JavaScript hook to notify of payment result
      if (isEmbedded && paymentOptionType === PAYMENT_OPTION_TYPE.APPLE_PAY) {
        yield call(notifyPaymentComplete, {
          type: EMBEDDED_PAYMENT_METHODS.APPLE_PAY,
          success: true,
        });
      }

      // Goes to order status page
      const query = appendQuery(payload.query, ORDER_IDENTIFIER_TYPES.ID);
      window.location = `${payload.url}/status/${orderId}${query}`;
    } else {
      const modifiedPayload = {
        ...payload,
      };
      const { data } = yield call(placeGuestOrder, apiClient, modifiedPayload);
      let { guestOrderToken } = data;
      if (data.placeOrderChallenge) {
        const payloadWithChallengeAnswer = yield call(
          handleOrderChallenge,
          data.placeOrderChallenge,
          payload,
        );
        const response = yield call(
          placeGuestOrder,
          apiClient,
          payloadWithChallengeAnswer,
        );
        guestOrderToken = response.data.guestOrderToken;
      }

      const query = appendQuery(
        payload.query,
        ORDER_IDENTIFIER_TYPES.GUEST_TOKEN,
      );
      window.location = `${payload.url}/status/${guestOrderToken}${query}`;
    }
    yield put(placeOrderSuccess());
  } catch (error) {
    if (payload.handleCardError) payload.handleCardError(error);

    // For embedded Apple Pay, call JavaScript hook to notify of failed payment
    if (isEmbedded && paymentOptionType === PAYMENT_OPTION_TYPE.APPLE_PAY) {
      yield call(notifyPaymentComplete, {
        type: EMBEDDED_PAYMENT_METHODS.APPLE_PAY,
        success: false,
      });
    }

    yield put(placeOrderError({ error }));
  }
}

export function* setTipSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(updateCartTip, apiClient, payload);
    yield put(updateCartSuccess(data));
  } catch (error) {
    yield put(updateCartError({ error }));
  }
}

export function* addPromoSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(updateCartPromo, apiClient, payload);
    yield put(updateCartSuccess(data));
  } catch (error) {
    yield put(updateCartError({ error }));
  }
}

export function* removePromoSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(removeCartPromo, apiClient, payload);
    yield put(updateCartSuccess(data));
  } catch (error) {
    yield put(updateCartError({ error }));
  }
}

export function* updateCartSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(patchCart, apiClient, payload);
    yield put(updateCartSuccess(data));
  } catch (error) {
    yield put(updateCartError({ error }));
  }
}

export default function* cart() {
  yield takeLatest(loadCart.type, loadCartSaga);
  yield takeLatest(addToCart.type, addToCartSaga);
  yield takeLatest(removeFromCart.type, removeFromCartSaga);
  yield takeLatest(modifyItemInCart.type, modifyItemInCartSaga);
  yield takeLatest(placeOrder.type, placeOrderSaga);
  yield takeLatest(setTip.type, setTipSaga);
  yield takeLatest(addPromo.type, addPromoSaga);
  yield takeLatest(removePromo.type, removePromoSaga);
  yield takeLatest(toggleCutlery.type, updateCartSaga);
  yield takeLatest(associateFulfillment.type, updateCartSaga);
  yield takeLatest(cartDeliveryInfoLoading.type, loadCartDeliveryInfoSaga);
  yield takeLatest(authenticated.type, loadConvertedCartSaga);
}
