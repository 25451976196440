export const TeamSearchResults = {
  results: [
    {
      teamId: '1',
      name: 'Ritual Technologies Inc',
      secured: true,
      industry: 'Technology',
    },
    {
      teamId: '2',
      name: 'Company A',
      secured: false,
      industry: 'Industry',
    },
    {
      teamId: '3',
      name: 'Company B',
      secured: true,
      industry: 'Industry',
    },
    {
      teamId: '4',
      name: 'Company C',
      secured: true,
      industry: 'Industry',
    },
    {
      teamId: '5',
      name: 'Company D',
      secured: true,
      industry: 'Industry',
    },
    {
      teamId: '6',
      name: 'Company E',
      secured: true,
      industry: 'Industry',
    },
    {
      teamId: '7',
      name: 'Company F',
      secured: true,
      industry: 'Industry',
    },
    {
      teamId: '8',
      name: 'Company G',
      secured: true,
      industry: 'Industry',
    },
    {
      teamId: '9',
      name: 'Company H',
      secured: true,
      industry: 'Industry',
    },
    {
      teamId: '10',
      name: 'Company I',
      secured: true,
      industry: 'Industry',
    },
  ],
};

export const TeamDetails = {
  teamDetails: {
    teamId: '301068230',
    name: 'Ritual Technologies Inc',
    secured: true,
    industry: 'Finance',
  },
  locations: [
    {
      locationId: '004328fbaece4b14870e47f73ab2c974',
      latLon: {
        latitude: 43.6467228,
        longitude: -79.3951572,
      },
      googlePlaceId: 'ChIJpQthnkCuEmsREGKGz3sn_Es',
      displayName: '82 Peter Street',
      extendedDisplayName: '82 Peter Street, Toronto, ON M5G 2A1, Canada',
      memberCount: 20,
      secured: true,
      floors: [
        {
          floorId: '7655136499053709001',
          name: '1st Floor',
          secured: true,
          memberCount: 4,
          dropZones: [
            {
              dropZoneId: '6253025107957599000',
              name: 'Kitchen',
            },
          ],
        },
        {
          floorId: '7655136499053709002',
          name: '2nd Floor',
          secured: true,
          memberCount: 5,
          dropZones: [
            {
              dropZoneId: '6253025107957599000',
              name: 'Kitchen',
            },
          ],
        },
      ],
    },
    {
      locationId: '004328fbaece4b14870e47f73ab2c975',
      latLon: {
        latitude: 43.6467228,
        longitude: -79.3951572,
      },
      googlePlaceId: 'ChIJpQthnkCuEmsREGKGz3sn_Es',
      displayName: '83 Peter Street',
      extendedDisplayName: '83 Peter Street, Toronto, ON M5G 2A1, Canada',
      memberCount: 123,
      secured: false,
      floors: [
        {
          floorId: '7655136499053709000',
          name: '1st Floor',
          secured: true,
          memberCount: 50,
          dropZones: [
            {
              dropZoneId: '6253025107957599000',
              name: 'Kitchen',
            },
          ],
        },
        {
          floorId: '7655136499053709002',
          name: '2nd Floor',
          secured: false,
          memberCount: 25,
          dropZones: [
            {
              dropZoneId: '6253025107957599000',
              name: 'Kitchen',
            },
          ],
        },
      ],
    },
  ],
  userMembership: {
    status: 'NOT_JOINED',
    emailVerified: false,
  },
  userSettings: {
    workEmail: 'clintonmorrison@ritual.co',
    locationId: '004328fbaece4b14870e47f73ab2c974',
    floorId: '7655136499053709001',
    dropZoneId: '6253025107957599000',
  },
};

export const EmailVerifiedUnjoinedTeam = {
  ...TeamDetails,
  userMembership: {
    status: 'NOT_JOINED',
    emailVerified: true,
  },
};

export const JoinedTeam = {
  ...TeamDetails,
  userMembership: {
    status: 'MEMBER',
    emailVerified: true,
  },
};

export const EmailVerificationStatusUnverified = {
  emailVerificationStatus: 'PENDING',
  teamStatus: 'JOINABLE',
  associatedTeamDetails: {
    teamId: '1',
    name: 'Ritual',
    secured: true,
  },
};

export const EmailVerificationStatusVerified = {
  emailVerificationStatus: 'VERIFIED',
  teamStatus: 'JOINABLE',
  associatedTeamDetails: {
    teamId: '1',
    name: 'Ritual',
    secured: true,
  },
};
