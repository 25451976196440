import { isSupported } from 'firebase/remote-config';
import { getApp, getApps, initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: process.env.RT_FIREBASE_API_KEY,
  authDomain: process.env.RT_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.RT_FIREBASE_DB_URL,
  projectId: process.env.RT_FIREBASE_PROJECT_ID,
  storageBucket: process.env.RT_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.RT_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.RT_FIREBASE_APP_ID,
  measurementId: process.env.RT_FIREBASE_MEASUREMENT_ID,
};

const firebase = {
  app: null,
  isEnabled: false,
  getApp: async () => {
    if (firebase.app) {
      return firebase.app;
    }
    return firebase.createApp();
  },
  createApp: async () => {
    const firebaseApp =
      getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);

    firebase.isEnabled = await isSupported();
    firebase.app = firebaseApp;
    return firebase.app;
  },
  getIsEnabled: () => firebase.isEnabled,
};

export default firebase;
export {
  fetchAndActivate,
  getBoolean,
  getRemoteConfig,
  getString,
  getNumber,
} from 'firebase/remote-config';
