export const KEYCLOAK_TOKEN_COOKIE = 'kcToken';
export const KEYCLOAK_ID_TOKEN_COOKIE = 'kcIdToken';
export const KEYCLOAK_REFRESH_TOKEN_COOKIE = 'kcRefreshToken';
export const KEYCLOAK_ANONYMOUS_TOKEN_COOKIE = 'kcAnonymousToken';

// Most browsers have a max cookie size of 4096 bytes
// and ignore requests to set cookies that are larger
//
// When the cookie size is near 4096 bytes we also sometimes see
// a "Request Header Or Cookie Too Large" error on requests to artemis.
export const MAX_TOKEN_COOKIE_SIZE = 3072;

export const QUERY_AUTH_REDIRECTED = 'redirectedFromAuth';
