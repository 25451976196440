import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  groups: {
    isLoading: false,
    data: null,
    error: null,
    initialized: false,
  },
};

const paymentOptionsSlice = createSlice({
  name: 'paymentOptions',
  initialState,
  reducers: {
    loadPaymentGroups: state => {
      state.groups.isLoading = true;
    },
    loadPaymentGroupsSuccess: (state, action) => {
      state.groups.isLoading = false;
      state.groups.data = action.payload;
      state.groups.initialized = true;
    },
    loadPaymentGroupsError: (state, action) => {
      state.groups.isLoading = false;
      state.groups.error = action.payload;
      state.groups.initialized = true;
    },
  },
});

export const {
  loadPaymentGroups,
  loadPaymentGroupsSuccess,
  loadPaymentGroupsError,
} = paymentOptionsSlice.actions;

export default paymentOptionsSlice.reducer;
