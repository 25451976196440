import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { getPaymentGroups } from '@artemis/api/contentGateway';
import {
  loadPaymentGroups,
  loadPaymentGroupsError,
  loadPaymentGroupsSuccess,
} from './slice';

export function* loadPaymentGroupsSaga() {
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(getPaymentGroups, apiClient);
    yield put(loadPaymentGroupsSuccess(data));
  } catch (err) {
    yield put(loadPaymentGroupsError(err));
  }
}

export function* getPaymentOptionsData() {
  yield takeLatest(loadPaymentGroups.type, loadPaymentGroupsSaga);
}
