import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { getIntegrationTypeFromEmbedValue } from './utils';

export const initialState = {
  isEmbedded: false,
  integrationType: '',
  paymentMethods: {
    loading: false,
    loadingSupportedMethods: false,
    supportedMethods: [],
    storedCards: [],
    initialized: false,
  },
  payment: {
    loading: false,
  },
  hqo: {},
};

const embedSlice = createSlice({
  name: 'embed',
  initialState,
  reducers: {
    setEmbedded: (state, action) => {
      state.isEmbedded = true;
      state.integrationType = getIntegrationTypeFromEmbedValue(action.payload);
    },
    loadPaymentMethods: () => {
      // intentionally empty; this action is only used by sagas
    },
    loadPaymentMethodsError: state => {
      state.paymentMethods.loading = false;
      state.paymentMethods.loadingSupportedMethods = false;
      state.paymentMethods.initialized = true;
    },
    loadPaymentMethodsSuccess: (state, action) => {
      const { payload } = action;
      state.paymentMethods.supportedMethods = payload.supportedPaymentMethods;
      state.paymentMethods.storedCards = payload.storedCards;
      state.paymentMethods.loading = false;
      state.paymentMethods.loadingSupportedMethods = false;
      state.paymentMethods.initialized = true;
    },
    setPaymentMethodsLoading: (state, action) => {
      state.paymentMethods.loading = action.payload;
    },
    setSupportedPaymentMethodsLoading: (state, action) => {
      state.paymentMethods.loadingSupportedMethods = action.payload;
    },
    setPaymentLoading: (state, action) => {
      state.payment.loading = action.payload;
    },
    setHqOInitData: (state, action) => {
      state.hqo.initData = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.embed);
  },
});

export const {
  setEmbedded,
  loadPaymentMethods,
  loadPaymentMethodsError,
  loadPaymentMethodsSuccess,
  setPaymentMethodsLoading,
  setSupportedPaymentMethodsLoading,
  setPaymentLoading,
  setHqOInitData,
} = embedSlice.actions;

export default embedSlice.reducer;
