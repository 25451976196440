import { createSelector } from 'reselect';
import { formatCurrency } from '@artemis/utils/currency-format';
import { getCurrencyCode } from '@artemis/store/merchant/selectors';

const RITUAL_EMAIL_DOMAIN = '@ritual.co';

const getUserRoot = state => state.user;

export const getUser = createSelector(getUserRoot, user => user?.data);
export const getUserEmail = createSelector(getUser, user => user?.email);
export const getUserName = createSelector(getUser, user => user?.firstName);
export const getUserPoints = createSelector(getUser, user => user?.points);
export const getUserTeamInfo = createSelector(getUser, user => user?.teamInfo);
export const getUserTeamDefaultTeamId = createSelector(
  getUserTeamInfo,
  teamInfo => teamInfo?.defaultTeamId || null,
);
export const getIsUserLoaded = createSelector(getUser, user => !!user);

export const getUserLastInitial = createSelector(
  getUser,
  user => user?.lastName?.slice(0, 1) || '',
);

export const getUserExternalId = createSelector(
  getUser,
  user => user?.externalId,
);

export const getActiveCurrencyCreditsMicro = createSelector(
  getUser,
  getCurrencyCode,
  (user, currencyCode) => user?.creditMap?.[currencyCode] || 0,
);

export const getActiveCurrencyCredits = createSelector(
  getCurrencyCode,
  getActiveCurrencyCreditsMicro,
  (currencyCode, creditsMicro) =>
    formatCurrency({
      currencyCode,
      value: creditsMicro,
    }),
);

export const hasActiveCurrencyCredits = createSelector(
  getUser,
  getCurrencyCode,
  (user, currencyCode) => !!user?.creditMap?.[currencyCode],
);

export const getIsInternalRitualUser = createSelector(getUserEmail, email =>
  (email || '').endsWith(RITUAL_EMAIL_DOMAIN),
);

export const getIsAnalyticsIdentityLoading = createSelector(
  getUser,
  user => user?.isAnalyticsIdentityLoading || false,
);
