/**
 *
 * ResourceSet Selectors
 *
 */

import { createSelector } from 'reselect';

const getResourceSetRoot = state => state.resourceSet;

export const getResourceSet = createSelector(
  getResourceSetRoot,
  resourceSet => resourceSet.data || {},
);

export const getDeliveryHomeIconResource = createSelector(
  getResourceSet,
  resourceSet => resourceSet?.['deliveryHome.svg.img']?.mobile || '',
);

export const getDeliveryMerchantIconResource = createSelector(
  getResourceSet,
  resourceSet => resourceSet?.['deliveryMerchant.svg.img']?.mobile || '',
);

export const getDeliveryCourierIconResource = createSelector(
  getResourceSet,
  resourceSet => resourceSet?.['deliveryCourier.svg.img']?.mobile || '',
);
