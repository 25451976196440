import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { fetchUser, fetchAnalyticsIdentity } from '@artemis/api/athena';
import { leaveTeamSuccess } from '@artemis/store/teams/slice';
import {
  loadUser,
  loadUserError,
  loadUserSuccess,
  getAnalyticsIdentity,
  getAnalyticsIdentitySuccess,
} from './slice';

export function* loadUserSaga() {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const res = yield call(fetchUser, apiClient);
    yield put(loadUserSuccess(res.data));
  } catch (err) {
    yield put(loadUserError(err));
  }
}

export function* loadAnalyticsIdentity() {
  try {
    const apiClient = yield getContext('athenaApiClient');
    yield call(fetchAnalyticsIdentity, apiClient);
  } catch (err) {
    // The endpoint will give back 404, we just need its set-cookie response header
    // Intentionally left empty
  }
  yield put(getAnalyticsIdentitySuccess());
}

export default function* userData() {
  yield takeLatest(loadUser.type, loadUserSaga);
  yield takeLatest(leaveTeamSuccess.type, loadUserSaga);
  yield takeLatest(getAnalyticsIdentity.type, loadAnalyticsIdentity);
}
