import { css } from 'styled-components';
import rtColors from '@ritualco/ritual-frontend-components/build/constants/styles/rtColors';
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import scrollShadows from './scrollShadows';
import { isDesktop, isTablet, breakpoints } from './breakpoints';
import utils from './utils';
import zIndices from './zIndices';

export default {
  isDesktop,
  isTablet,
  breakpoints,
  palette,
  rtColors,
  shadows,
  typography,
  noScrollbars: css`
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  `,
  scrollShadows,
  utils,
  zIndices,
};
