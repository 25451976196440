import axios from 'axios';

const IpData = async (ipAddress = '') => {
  const key = process.env.RT_IP_DATA_KEY;
  try {
    const { data, status } = await axios.get(
      `https://api.ipdata.co/${ipAddress}?api-key=${key}`,
    );
    if (status === 403) {
      throw new Error('Forbidden');
    }
    return data;
  } catch (e) {
    // you have to use ngrok to develop this locally
    // run with
    //        yarn dev:tunnel
    // console.error(
    //   `Unable to get data for ip address ${ipAddress}; error: ${
    //     e.data.message
    //   }`,
    // );
    return null;
  }
};

export default IpData;
