import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RemoteConfigContext from './context';
import firebase, {
  fetchAndActivate,
  getBoolean as getFirebaseBoolean,
  getRemoteConfig,
  getString as getFirebaseString,
  getNumber as getFirebaseNumber,
} from './firebase';

// https://ritualco.atlassian.net/wiki/spaces/HW/pages/1627684926/Firebase+Remote+Config
const defaultConfig = {
  r1_auth_providers: '["google", "facebook", "email", "apple", "phone"]',
  r1_enable_checkout_login: 'false',
  rw_enable_sift: 'false',
  rw_group_order_payment_groups_enabled: 'true',
  rw_sticky_place_order_button_org_allowlist: '["7166475256250253558"]', // https://ritualco.ritual.co
  rw_group_order_pm_consumer_flow_enabled: 'false',
  rw_food_fest_at_work_landing_page_enabled: 'true',
  rw_service_fee_info_modal: 'false',
  rw_mobile_menu_checkout_enabled: 'true',
  rw_mobile_menu_checkout_orgs_enabled: 'false',
  rw_download_app_enabled: 'false',
  rw_enable_verify_team_cta: 'false',
  rw_dinein_toggle_enabled: 'false',
  rw_local_selections_expiry_days: 90,
  rw_local_selections_enabled: 'false',
  rw_menu_calories_enabled: 'false',
  rw_menu_option_quantity_enabled: 'false',
  rw_menuiq_banner_enabled: 'false',
};

const useRemoteConfigValue = () => {
  const [remoteConfig, setRemoteConfig] = useState();
  const [configLoaded, setConfigLoaded] = useState(false);
  useEffect(() => {
    const initialize = async () => {
      const firebaseApp = await firebase.getApp();
      if (firebase.getIsEnabled()) {
        const remoteConfigInstance = getRemoteConfig(firebaseApp);
        remoteConfigInstance.settings.minimumFetchIntervalMillis =
          process.env.RT_FIREBASE_REFRESH_INTERVAL;
        remoteConfigInstance.defaultConfig = defaultConfig;
        fetchAndActivate(remoteConfigInstance).then(() =>
          setConfigLoaded(true),
        );
        setRemoteConfig(remoteConfigInstance);
      } else {
        setConfigLoaded(true);
      }
    };
    initialize();
  }, []);
  const getBoolean = key =>
    remoteConfig && configLoaded
      ? getFirebaseBoolean(remoteConfig, key)
      : defaultConfig[key] === 'true';
  const getString = key =>
    remoteConfig && configLoaded
      ? getFirebaseString(remoteConfig, key)
      : defaultConfig[key];
  const getNumber = key =>
    remoteConfig && configLoaded
      ? getFirebaseNumber(remoteConfig, key)
      : defaultConfig[key];
  const getJSON = key => {
    try {
      const str = getString(key);
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  };

  return {
    configLoaded,
    getBoolean,
    getString,
    getNumber,
    getJSON,
  };
};

const Provider = ({ children }) => {
  const remoteConfig = useRemoteConfigValue();
  return (
    <RemoteConfigContext.Provider value={remoteConfig}>
      {children}
    </RemoteConfigContext.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

export const useRemoteConfig = () => useContext(RemoteConfigContext);

export default Provider;
