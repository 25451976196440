import { SHARE_MODE } from '@artemis/containers/GroupOrder/constants';
import {
  FULFILLMENT_FLOW_TYPE,
  FULFILLMENT_GUEST_STATUS,
  FULFILLMENT_STATE,
  FULFILLMENT_TYPE,
} from '@artemis/utils/constants';
import { formatCurrency } from '@artemis/utils/currency-format';
import {
  FORMAT,
  formatAsUTC,
  formatDate,
  millisToFormattedTime,
  parseAndFormatUTC,
  parseDateString,
  parseUTC,
} from '@artemis/utils/time-format';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { getCurrencyCode, getMerchantTimeZone } from '../merchant/selectors';
import { getUserExternalId } from '../user/selectors';

const getGroupOrderRoot = state => state.groupOrder;

export const getFulfillmentId = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder?.fulfillmentId,
);
export const getIsInitialized = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder?.initialized,
);

const getInvitationRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.invitation,
);

export const getInviteFulfillment = createSelector(
  getInvitationRoot,
  invitation => invitation.data,
);

export const getFulfillmentInvitationGroupDisplay = createSelector(
  getInviteFulfillment,
  details => details?.groupDisplay || {},
);

export const getIsInvitationModalOpen = createSelector(
  getInvitationRoot,
  invitation => invitation.modalOpen,
);

export const getCurrentUserAlreadyJoined = createSelector(
  getInviteFulfillment,
  getUserExternalId,
  (invitation, currentUserId) =>
    invitation?.guests?.some(guest => guest.externalUserId === currentUserId),
);

const getJoinRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.join,
);

export const getIsJoinFulfillmentLoading = createSelector(
  getJoinRoot,
  join => join.loading,
);

export const getIsJoinModalOpen = createSelector(
  getJoinRoot,
  join => join.modalOpen,
);

export const getHasJoinedFulfillment = createSelector(
  getJoinRoot,
  join => join.hasJoined,
);

export const getJoinError = createSelector(getJoinRoot, join => join.error);

const getFindRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.find,
);

export const getIsFindLoading = createSelector(
  getFindRoot,
  find => find.loading,
);

const getDetailsRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.details,
);

export const getFulfillmentDetails = createSelector(
  getDetailsRoot,
  details => details.data,
);

export const getIsGroupOrder = createSelector(
  getFulfillmentId,
  getFulfillmentDetails,
  (fulfillmentId, details) => !!fulfillmentId && !!details,
);

export const getFulfillmentTotalPayableMicro = createSelector(
  getFulfillmentDetails,
  details => details?.costBreakdown?.totalMicro || 0,
);

export const getFulfillmentChecksum = createSelector(
  getFulfillmentDetails,
  details => details?.checksum,
);

export const getFulfillmentGuestLimit = createSelector(
  getFulfillmentDetails,
  details => details?.guestMax,
);

export const getJoinUri = createSelector(
  getFulfillmentDetails,
  details => details?.joinUri,
);

export const getIsCurrentUserAdmin = createSelector(
  getFulfillmentDetails,
  details => details?.isCurrentUserAdmin,
);

export const getFulfillmentGuests = createSelector(
  getFulfillmentDetails,
  getUserExternalId,
  getIsCurrentUserAdmin,
  (details, id, isAdmin) =>
    details?.guests.map(guest => ({
      ...guest,
      // Only host user will uses this attribute on the UI
      isHost: isAdmin && guest.externalUserId === id,
    })) || [],
);

export const getReadyGuests = createSelector(getFulfillmentGuests, guests =>
  guests.filter(guest => guest.status !== FULFILLMENT_GUEST_STATUS.BROWSING),
);

export const getIsFulfillmentLoaded = createSelector(
  getFulfillmentDetails,
  details => details !== null,
);

export const getIsCurrentUserGuest = createSelector(
  getFulfillmentGuests,
  getUserExternalId,
  (guests, currentUserId) =>
    guests.some(guest => guest.externalUserId === currentUserId),
);

export const getFulfillmentMerchantId = createSelector(
  getFulfillmentDetails,
  details => {
    if (details?.merchantIds) {
      return details.merchantIds[0];
    }
    return null;
  },
);

export const getFulfillmentType = createSelector(
  getFulfillmentDetails,
  details => details?.fulfillmentType,
);

export const getOrderCount = createSelector(
  getFulfillmentGuests,
  guests => guests.length,
);

export const getFulfillmentPaymentSplit = createSelector(
  getFulfillmentDetails,
  details => details?.paymentSplit || null,
);

export const getFulfillmentSelectedTipId = createSelector(
  getFulfillmentDetails,
  details => details?.tip?.selectedTipOptionId,
);

export const getFulfillmentCustomTipAmount = createSelector(
  getFulfillmentDetails,
  details => details?.tip?.customTipAmountMicro,
);

export const getFulfillmentOrderId = createSelector(
  getFulfillmentDetails,
  details => details?.orderId || '',
);

export const getFulfillmentCompleteTime = createSelector(
  getFulfillmentDetails,
  details => details?.completeTime,
);

export const getFulfillmentScheduledTime = createSelector(
  getFulfillmentDetails,
  details => {
    if (
      !details?.scheduledDetails?.scheduled ||
      !details?.scheduledDetails?.scheduledForTime
    ) {
      return null;
    }

    return details.scheduledDetails.scheduledForTime;
  },
);

const getParsedScheduledTime = createSelector(
  getFulfillmentDetails,
  getMerchantTimeZone,
  (details, timeZone) => {
    if (
      !timeZone ||
      !details?.scheduledDetails?.scheduled ||
      !details?.scheduledDetails?.scheduledForTime ||
      !timeZone
    ) {
      return null;
    }

    return parseUTC(details.scheduledDetails.scheduledForTime, timeZone);
  },
);

// The scheduled time for the fulfillment, formatted as an ISO string in the merchant's timezone
export const getFulfillmentMerchantScheduledTime = createSelector(
  getParsedScheduledTime,
  scheduledTime => scheduledTime?.format(FORMAT.ISO_DATE_TIME),
);

// Returns the fulfillment scheduled time in the same format as cart/selectors.js#getScheduledForTime
export const getFulfillmentCartScheduledTime = createSelector(
  getParsedScheduledTime,
  getMerchantTimeZone,
  (parsedDate, timeZone) => {
    if (!parsedDate) {
      return null;
    }

    const targetDay = parsedDate.startOf('day');
    const currentDay = dayjs().startOf('day').tz(timeZone);
    const daysAhead = targetDay.diff(currentDay, 'day');

    return {
      date: formatDate(parsedDate, FORMAT.DAY_MONTH_AND_TIME),
      fullDate: formatDate(parsedDate, FORMAT.DAY_OF_WEEK_DATE_AND_TIME),
      isToday: daysAhead === 0,
      isTomorrow: daysAhead === 1,
    };
  },
);

export const getFulfillmentState = createSelector(
  getFulfillmentDetails,
  details => details?.fulfillmentState,
);

export const isFulfillmentCancellable = createSelector(
  getFulfillmentState,
  state => [FULFILLMENT_STATE.CREATED, FULFILLMENT_STATE.OPEN].includes(state),
);

export const getFulfillmentShareSettings = createSelector(
  getFulfillmentDetails,
  details => details?.shareSettings || {},
);

export const getFulfillmentShareMode = createSelector(
  getFulfillmentShareSettings,
  shareSettings => shareSettings?.shareMode || SHARE_MODE.LINK_ONLY,
);

export const getSupportedShareModes = createSelector(
  getFulfillmentShareSettings,
  shareSettings => shareSettings?.supportedShareModes || [],
);

// Returns the fulfillment scheduled time in the same format as order/selectors.js#getScheduledForTime
export const getFulfillmentOrderScheduledTime = createSelector(
  getParsedScheduledTime,
  getMerchantTimeZone,
  (parsedDate, timeZone) => {
    if (!parsedDate) {
      return null;
    }

    const targetDay = parsedDate.startOf('day');
    const currentDay = dayjs().startOf('day').tz(timeZone);
    const daysAhead = targetDay.diff(currentDay, 'day');

    return {
      date: formatDate(parsedDate, FORMAT.DAY_AND_MONTH),
      time: formatDate(parsedDate, FORMAT.TIME),
      isToday: daysAhead === 0,
      isTomorrow: daysAhead === 1,
    };
  },
);

export const getFulfillmentLocation = createSelector(
  getFulfillmentDetails,
  details => {
    const fulfillmentType = details?.fulfillmentType;
    const location = details?.deliveryDetails?.location;
    return fulfillmentType === FULFILLMENT_TYPE.DELIVERY && !!location
      ? location
      : null;
  },
);

export const getFulfillmentDeliveryAddressLine1 = createSelector(
  getFulfillmentLocation,
  location => location?.address.addressLine1 || null,
);

export const getFulfillmentFullDeliveryAddress = createSelector(
  getFulfillmentLocation,
  location =>
    [location?.address.addressLine1, location?.address.addressLine2]
      .filter(line => !!line)
      .join(', '),
);

export const getFulfillmentFullDeliveryAddressWithDivisions = createSelector(
  getFulfillmentLocation,
  getFulfillmentFullDeliveryAddress,
  (location, address) =>
    [address, location?.address.city, location?.address.province]
      .filter(division => !!division)
      .join(', '),
);

export const getFulfillmentDeliveryInstructions = createSelector(
  getFulfillmentDetails,
  details => details?.deliveryDetails?.deliveryInstructions,
);

export const getFulfillmentDeliveryLocationId = createSelector(
  getFulfillmentDetails,
  details => details?.deliveryDetails?.locationId,
);

const getDeliveryPricing = createSelector(
  getFulfillmentDetails,
  details => details?.deliveryPricing,
);

export const getFulfillmentDeliveryFee = createSelector(
  getCurrencyCode,
  getDeliveryPricing,
  (currencyCode, deliveryPricing) =>
    formatCurrency({
      value: deliveryPricing?.deliveryChargeMicro || 0,
      isMicro: true,
      currencyCode,
    }),
);

export const getFullfillmentDeliveryFeeMicro = createSelector(
  getDeliveryPricing,
  deliveryPricing => deliveryPricing?.deliveryChargeMicro,
);

export const getFulfillmentMinimumDeliveryOrder = createSelector(
  getCurrencyCode,
  getDeliveryPricing,
  (currencyCode, deliveryPricing) =>
    formatCurrency({
      value: deliveryPricing?.deliveryMinimumOrderValueMicro || 0,
      isMicro: true,
      currencyCode,
    }),
);

export const getFulfillmentMinimumDeliveryOrderMicro = createSelector(
  getDeliveryPricing,
  deliveryPricing => deliveryPricing?.deliveryMinimumOrderValueMicro,
);

export const getFulfillmentLoading = createSelector(
  getDetailsRoot,
  details => details.loading,
);

export const getIsDetailsModalOpen = createSelector(
  getDetailsRoot,
  details => details.modalOpen,
);

const getStatusRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.status,
);

export const getFulfillmentStatus = createSelector(
  getStatusRoot,
  status => status.data,
);

export const getUserRemovedFromFulfillment = createSelector(
  getStatusRoot,
  status => status?.error?.response?.status === 403,
);

export const isDetailsLoading = createSelector(
  getDetailsRoot,
  detailsRoot => detailsRoot?.loading,
);

export const getGroupDisplay = createSelector(
  getFulfillmentDetails,
  details => details?.groupDisplay,
);

export const getOrderByTime = createSelector(
  getFulfillmentDetails,
  details => details?.orderByTime || '',
);

const getLeaveRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.leave,
);

export const getIsLeaveFulfillmentLoading = createSelector(
  getLeaveRoot,
  leave => leave.loading,
);

export const getIsLeaveModalOpen = createSelector(
  getLeaveRoot,
  leave => leave.modalOpen,
);

const getCreateRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.create,
);

export const getIsCreateFulfillmentLoading = createSelector(
  getCreateRoot,
  create => create.loading,
);

const getUpdateRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.update,
);

export const getIsUpdateFulfillmentLoading = createSelector(
  getUpdateRoot,
  update => update.loading,
);

export const getUpdatingFulfillmentType = createSelector(
  getUpdateRoot,
  update => update.updatingFulfillmentType,
);

export const getIsOrderSubmitted = createSelector(
  getFulfillmentDetails,
  details =>
    [FULFILLMENT_STATE.CLOSED, FULFILLMENT_STATE.EXPIRED].includes(
      details?.fulfillmentState,
    ),
);

const getRemovedRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.removed,
);

export const getIsRemovedFromGroupModalOpen = createSelector(
  getRemovedRoot,
  removed => removed.modalOpen,
);

export const getRemoveGuestLoading = createSelector(
  getRemovedRoot,
  removed => removed.loading,
);

const getMissedRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.missed,
);

export const getIsMissedModalOpen = createSelector(
  getMissedRoot,
  missed => missed.modalOpen,
);

const getNonjoinableRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.nonjoinable,
);

export const getIsNonjoinableModalOpen = createSelector(
  getNonjoinableRoot,
  nonjoinable => nonjoinable.modalOpen,
);

const getInviteRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.invite,
);

export const getIsInviteModalOpen = createSelector(
  getInviteRoot,
  invite => invite.modalOpen,
);

export const getIsPaymentSplitModalOpen = createSelector(
  getUpdateRoot,
  update => update.paymentSplitModalOpen,
);

export const getIsSchedulingModalOpen = createSelector(
  getUpdateRoot,
  update => update.schedulingModalOpen,
);

export const getIsDeliveryModalOpen = createSelector(
  getUpdateRoot,
  update => update.deliveryModalOpen,
);

export const getIsGuestLimitModalOpen = createSelector(
  getUpdateRoot,
  update => update.guestLimitModalOpen,
);

const getTipDetailsRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.tipDetails,
);

export const getTipDetailsLoading = createSelector(
  getTipDetailsRoot,
  tipDetails => tipDetails.loading,
);

export const getTipDetailsLoaded = createSelector(
  getTipDetailsRoot,
  tipDetails => !!tipDetails?.data,
);

export const getTipDetails = createSelector(
  getTipDetailsRoot,
  tipDetails => tipDetails?.data || {},
);
export const getTipOptions = createSelector(
  getTipDetailsRoot,
  tipDetails => tipDetails?.data?.options || [],
);

export const getZeroTipOptionId = createSelector(
  getTipDetails,
  tipDetails => tipDetails?.zeroTipOptionId,
);

export const getDefaultTipOptionId = createSelector(
  getTipDetails,
  getZeroTipOptionId,
  (tipDetails, zeroTipOptionId) =>
    tipDetails?.defaultTipOption?.optionId || zeroTipOptionId,
);

const getFulfillmentTypeOptionsRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.fulfillmentTypeOptions,
);

export const getFulfillmentTypeOptionsLoading = createSelector(
  getFulfillmentTypeOptionsRoot,
  typeOptionsRoot => typeOptionsRoot.loading,
);

const getFulfillmentTypeOptions = createSelector(
  getFulfillmentTypeOptionsRoot,
  typeOptions => typeOptions.data || [],
);

export const getFulfillmentSupportsPickup = createSelector(
  getFulfillmentTypeOptions,
  options => options.includes(FULFILLMENT_TYPE.IN_STORE),
);

export const getFulfillmentSupportsDelivery = createSelector(
  getFulfillmentTypeOptions,
  options => options.includes(FULFILLMENT_TYPE.DELIVERY),
);

export const getFulfillmentTypesLoaded = createSelector(
  getFulfillmentTypeOptions,
  options => options.length > 0,
);

const getScheduledTimeSlotsRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.scheduledTimeSlots,
);

const getRawScheduledTimeSlots = createSelector(
  getScheduledTimeSlotsRoot,
  timeSlots => timeSlots.data || [],
);

export const getFulfillmentScheduledTimeSlots = createSelector(
  getRawScheduledTimeSlots,
  timeSlots => {
    // based on merchant.js#getMerchantScheduledOrderTimeSlots
    if (!timeSlots || !timeSlots.length) return {};
    const dateTimeMappings = timeSlots.reduce(
      (
        acc,
        { date, firstAvailableTime, lastAvailableTime, intervalMillis },
      ) => {
        const day = parseAndFormatUTC(date, 'Etc/UTC', 'YYYY-MM-DD');

        const formattedTimeSlots = acc[day] || [];
        let formattedTime;
        for (
          let i = firstAvailableTime;
          i <= lastAvailableTime;
          i += intervalMillis
        ) {
          formattedTime = millisToFormattedTime(i);
          formattedTimeSlots.push(formattedTime);
        }
        return {
          ...acc,
          [day]: formattedTimeSlots,
        };
      },
      {},
    );
    return dateTimeMappings;
  },
);

export const getFirstAvailableTimeslot = createSelector(
  getFulfillmentScheduledTimeSlots,
  getMerchantTimeZone,
  (timeSlots, merchantTimeZone) => {
    const dates = Object.keys(timeSlots);
    if (dates.length === 0) {
      return null;
    }

    const [date] = dates;
    const time = timeSlots[date]?.[0];

    if (!date || !time) {
      return null;
    }

    const parsedDate = parseDateString(
      `${date} ${time}`,
      `${FORMAT.YMD} ${FORMAT.TIME}`,
      merchantTimeZone,
    );

    return formatAsUTC(parsedDate);
  },
);

const getValidationRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.validation,
);

export const getValidationLoading = createSelector(
  getValidationRoot,
  validation => validation.loading,
);

export const getValidationErrors = createSelector(
  getValidationRoot,
  validation => validation.data || [],
);

export const getIsFulfillmentValid = createSelector(
  getValidationErrors,
  validationErrors => validationErrors.length === 0,
);

export const getFulfillmentDeliveryErrors = createSelector(
  getValidationErrors,
  errors => errors.filter(error => error.parameter === 'deliveryLocation'),
);

export const getFulfillmentScheduledTimeErrors = createSelector(
  getValidationErrors,
  errors => errors.filter(error => error.parameter === 'scheduledForTime'),
);

const getCancelRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.cancel,
);

export const getCancelLoading = createSelector(
  getCancelRoot,
  cancel => cancel.loading,
);

export const getCostBreakdown = createSelector(
  getFulfillmentDetails,
  details => details?.costBreakdown || null,
);

const getHostExternalId = createSelector(
  getFulfillmentDetails,
  details => details?.hostId,
);

export const getIsCurrentUserHost = createSelector(
  getHostExternalId,
  getUserExternalId,
  (hostId, currentUserId) => hostId === currentUserId,
);

const getFulfillmentFlowType = createSelector(
  getFulfillmentDetails,
  details => details?.fulfillmentFlowType,
);

export const getIsConsumerFlow = createSelector(
  getFulfillmentFlowType,
  flowType => flowType === FULFILLMENT_FLOW_TYPE.HOST_ALWAYS_GUEST,
);

export const getIsAdminFlow = createSelector(
  getFulfillmentFlowType,
  flowType => flowType === FULFILLMENT_FLOW_TYPE.ADMIN_OPTIONAL_GUEST,
);

export const getIsConsumerFlowHost = createSelector(
  getIsConsumerFlow,
  getIsCurrentUserHost,
  (isConsumerFlow, isHost) => isConsumerFlow && isHost,
);

export const getIsCancelFulfillmentModalOpen = createSelector(
  getCancelRoot,
  cancel => cancel.modalOpen,
);

const getConfirmRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.confirm,
);

export const getConfirmLoading = createSelector(
  getConfirmRoot,
  confirm => confirm.loading,
);

export const getConfirmError = createSelector(
  getConfirmRoot,
  confirm => confirm.error,
);

const getInfoRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.info,
);

export const getIsHostInfoModalOpen = createSelector(
  getInfoRoot,
  info => info.hostModalOpen,
);

export const getIsGuestInfoModalOpen = createSelector(
  getInfoRoot,
  info => info.guestModalOpen,
);

const getCreateInviteRoot = createSelector(
  getGroupOrderRoot,
  groupOrder => groupOrder.createInvite,
);

export const getCreateInviteLoading = createSelector(
  getCreateInviteRoot,
  createInvite => createInvite.loading,
);

export const getCreateInviteError = createSelector(
  getCreateInviteRoot,
  createInvite => createInvite.error,
);
