const HOOK_TIMEOUT_MS = 2000;

const HOOKS = {
  GET_TOKEN: 'getRitualAccessToken',
  REFRESH_TOKEN: 'refreshRitualAccessToken',
  GET_PAYMENT_METHODS: 'getRitualSupportedPaymentMethods',
  GET_PAYMENT: 'getRitualPayment',
  PAYMENT_COMPLETE: 'ritualPaymentComplete',
  SEND_EVENT: 'sendRitualEvent',
  RITUAL_BRIDGE: 'ritualBridge', // Ritual mobile marketplace app
};

const CALLBACKS = {
  TOKEN_OBTAINED: 'onRitualGetAccessToken',
  TOKEN_REFRESHED: 'onRitualAccessTokenRefreshed',
  PAYMENT_METHODS_OBTAINED: 'onRitualGetSupportedPaymentMethods',
  PAYMENT_READY: 'onRitualPaymentReady',
  PAYMENT_COMPLETE_RESULT: 'onRitualPaymentComplete',
};

const embeddedIntegration = {
  [CALLBACKS.TOKEN_OBTAINED]: () => {},
  [CALLBACKS.TOKEN_REFRESHED]: () => {},
  [CALLBACKS.PAYMENT_METHODS_OBTAINED]: () => {},
  [CALLBACKS.PAYMENT_READY]: () => {},
  [CALLBACKS.PAYMENT_COMPLETE_RESULT]: () => {},
};

const androidHookExists = name => typeof window?.Android?.[name] === 'function';

const androidInvokeHook = (name, ...args) =>
  Promise.resolve(window.Android[name](...args));

const iosHookExists = name =>
  typeof window?.webkit?.messageHandlers?.[name]?.postMessage === 'function';

const iosInvokeHook = (name, msg) =>
  window.webkit.messageHandlers[name].postMessage(msg);

const invokeHookWithCallback = (hookName, callbackName) =>
  new Promise(resolve => {
    // The native app will invoke `window.embeddedIntegration[callbackName]` with the result
    // after the hook is called.
    embeddedIntegration[callbackName] = resolve;

    if (androidHookExists(hookName)) {
      androidInvokeHook(hookName);
    } else if (iosHookExists(hookName)) {
      iosInvokeHook(hookName);
    } else {
      resolve(null);
    }
  });

/**
 * Return the original promise if it resolves before the timeout, otherwise return the default response
 * @param {Promise<any>} originalPromise
 * @param {number} timeoutMs
 * @param {any} defaultResponse
 * @returns {Promise<any>}
 */
const fallbackToDefaultAfterTimeout = (
  originalPromise,
  timeoutMs,
  defaultResponse,
) => {
  const fallbackPromise = new Promise(resolve =>
    setTimeout(() => resolve(defaultResponse), timeoutMs),
  );

  return Promise.race([originalPromise, fallbackPromise]);
};

/**
 * Adds placeholder callback functions on the window object for the native app to call
 * after certain hooks are invoked
 */
export const installGlobalCallbacks = () => {
  window.embeddedIntegration = embeddedIntegration;
};

/**
 * Requests current access token from the native app
 * @returns {Promise<String>} access token
 */
export const getAccessToken = () =>
  invokeHookWithCallback(HOOKS.GET_TOKEN, CALLBACKS.TOKEN_OBTAINED);

/**
 * Requests a refreshed access token from the native app (after current token expires)
 * @returns {Promise<String>} access token
 */
export const refreshAccessToken = () =>
  invokeHookWithCallback(HOOKS.REFRESH_TOKEN, CALLBACKS.TOKEN_REFRESHED);

/**
 * Checks which special embedded payment options are supported
 * Valid options: APPLE_PAY, GOOGLE_PAY, IN_APP_STORED_PAYMENT
 *
 * @returns {Promise<String[]>}
 */
export const getSupportedPaymentMethods = () => {
  const loadPaymentMethodsPromise = invokeHookWithCallback(
    HOOKS.GET_PAYMENT_METHODS,
    CALLBACKS.PAYMENT_METHODS_OBTAINED,
  );

  return fallbackToDefaultAfterTimeout(
    loadPaymentMethodsPromise,
    HOOK_TIMEOUT_MS,
    null,
  );
};

/**
 * Requests a Stripe payment method ID for payment from native app
 * @returns {Promise<String>} stripe token (payment method ID)
 */
export const getPayment = ({
  type,
  countryCode,
  currency,
  total,
  subtotal,
  tax,
}) =>
  new Promise(resolve => {
    embeddedIntegration[CALLBACKS.PAYMENT_READY] = resolve;

    if (androidHookExists(HOOKS.GET_PAYMENT)) {
      androidInvokeHook(
        HOOKS.GET_PAYMENT,
        JSON.stringify({
          type,
          countryCode,
          currency,
          total,
          subtotal,
          tax,
        }),
      );
    } else if (iosHookExists(HOOKS.GET_PAYMENT)) {
      iosInvokeHook(HOOKS.GET_PAYMENT, {
        type,
        countryCode,
        currency,
        total,
        subtotal,
        tax,
      });
    } else {
      resolve(null);
    }
  });

/**
 * Notifies native app when embedded payment is complete.
 * This should be called after the user tries to place an order.
 *
 * "success" should be true if the order is placed successfully and the payment was captured.
 *
 * @returns {Promise<boolean>} true if the payment was completed successfully
 */
export const notifyPaymentComplete = ({ type, success }) =>
  new Promise(resolve => {
    embeddedIntegration[CALLBACKS.PAYMENT_COMPLETE_RESULT] = resolve;

    if (androidHookExists(HOOKS.PAYMENT_COMPLETE)) {
      androidInvokeHook(
        HOOKS.PAYMENT_COMPLETE,
        JSON.stringify({ type, success }),
      );
    } else if (iosHookExists(HOOKS.PAYMENT_COMPLETE)) {
      iosInvokeHook(HOOKS.PAYMENT_COMPLETE, { type, success });
    } else {
      resolve(null);
    }
  });

/**
 * Notifies the native app about an event that happened in the webview.
 *
 * Supported events:
 * - WEBVIEW_CLOSE: user tapped close button in webview
 *
 * @returns {void}
 */
export const sendEvent = ({ eventName }) => {
  if (androidHookExists(HOOKS.SEND_EVENT)) {
    androidInvokeHook(HOOKS.SEND_EVENT, eventName);
  } else if (iosHookExists(HOOKS.SEND_EVENT)) {
    iosInvokeHook(HOOKS.SEND_EVENT, { eventName });
  }
};

/**
 * Returns true if webview hooks for sending an event are present.
 * This is used to check if the PM app supports the close button.
 *
 * @returns bool
 */
export const isSendEventSupported = () =>
  androidHookExists(HOOKS.SEND_EVENT) || iosHookExists(HOOKS.SEND_EVENT);

/**
 * Sends a payload to the exposed Ritual bridge when in webview for mobile app to handle
 *
 * @returns {void}
 */
export const sendToRitualBridge = payload => {
  if (window?.ritualBridge) {
    // Android
    window.ritualBridge.postMessage(JSON.stringify(payload));
  } else if (window?.webkit?.messageHandlers?.ritualBridge) {
    // iOS
    window.webkit.messageHandlers.ritualBridge.postMessage(payload);
  }
};

/**
 * Returns true if the Ritual bridge present.
 * This is used to check if we are in the Ritual mobile marketplace app
 *
 * @returns bool
 */
export const isRitualBridgeSupported = () => {
  if (typeof window !== 'undefined') {
    return !!(
      window?.ritualBridge || window?.webkit?.messageHandlers?.ritualBridge
    );
  }
  return false;
};
