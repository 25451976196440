import {
  JOIN_ERROR_CODE,
  VERIFICATION_ERROR_CODE,
} from '@artemis/containers/Teams/constants';
import { ERROR_MODAL } from '@artemis/utils/constants';

export const genKey = () =>
  [...Array(30)].map(() => Math.random().toString(36)[2]).join('');

export const getMessageForErrorCode = error => {
  const code = error?.response?.data?.errors?.[0]?.code;

  switch (code) {
    case VERIFICATION_ERROR_CODE.EMAIL_DOES_NOT_MATCH_TEAM:
      return 'teams.connectEmail.errors.email_does_not_match_team';
    case JOIN_ERROR_CODE.EMAIL_VERIFICATION_REQUIRED:
      return 'teams.join.errors.email_verification_required';
    case JOIN_ERROR_CODE.USER_NOT_WHITELISTED:
      return 'teams.join.errors.user_not_whitelisted';
    default:
      return null;
  }
};

export const getModalType = error => {
  const { errorType, errorBody } = error;
  switch (errorType) {
    case 'PROMOTION_ELIGIBILITY_ERROR':
      return ERROR_MODAL.INELIGIBLE_PROMOTION;
    case 'ORDER_CREATION':
      if (errorBody?.errorReason === 'ITEM_NOT_FOR_SALE_AT_THIS_TIME') {
        return ERROR_MODAL.UNAVAILABLE_SCHEDULED_ORDER_ITEM;
      }
      if (
        errorBody?.errorReason ===
        'SCHEDULED_ORDER_ITEM_NOT_READY_BY_SCHEDULED_TIME'
      ) {
        return ERROR_MODAL.SCHEDULED_ORDER_ITEM_PREP_TIME;
      }
      if (errorBody?.errorReason === 'SCHEDULED_TIME_MERCHANT_NOT_AVAILABLE') {
        return ERROR_MODAL.INVALID_SCHEDULED_FOR_TIME;
      }
      if (errorBody?.errorReason === 'INCENTIVES_INVALID') {
        return ERROR_MODAL.INCENTIVES_INVALID;
      }
      if (errorBody?.errorReason === 'GROUP_ORDER_APP_ONLY') {
        return ERROR_MODAL.GROUP_ORDER_APP_ONLY;
      }

      return null;
    default:
      return null;
  }
};
