/* istanbul ignore file */

/**
 * This is based on the default server token persistor:
 * https://github.com/react-keycloak/react-keycloak/blob/master/packages/ssr/src/persistors/server.ts
 *
 * It does not encode the tokens before setting them as cookies to decrease the size of the cookies.
 */
import Cookie from 'js-cookie';
import {
  KEYCLOAK_TOKEN_COOKIE,
  KEYCLOAK_ID_TOKEN_COOKIE,
  MAX_TOKEN_COOKIE_SIZE,
  KEYCLOAK_REFRESH_TOKEN_COOKIE,
} from '@artemis/integrations/auth/constants';
import {
  getSessionItem,
  removeSessionItem,
  SESSION_KEY,
  setSessionItem,
} from '@artemis/utils/sessionStorage';

const isServer = () => typeof window === 'undefined';

function setCookie(name, val) {
  return Cookie.set(name, val);
}

function getCookie(name, cookies = {}) {
  return isServer() ? cookies[name] : Cookie.get(name);
}

function removeCookie(name) {
  Cookie.remove(name);
}

function canUseSessionStorage() {
  return typeof window !== 'undefined' && window.sessionStorage;
}

export const SSRCookies = cookies => ({
  setTokens: ({ idToken, token, refreshToken }) => {
    if (token) {
      if (token.length > MAX_TOKEN_COOKIE_SIZE && canUseSessionStorage()) {
        removeCookie(KEYCLOAK_TOKEN_COOKIE);
        setSessionItem(SESSION_KEY.KEYCLOAK_TOKEN, token, true);
      } else {
        setCookie(KEYCLOAK_TOKEN_COOKIE, token);
      }
    }

    if (idToken) {
      setCookie(KEYCLOAK_ID_TOKEN_COOKIE, idToken);
    }

    if (refreshToken) {
      setCookie(KEYCLOAK_REFRESH_TOKEN_COOKIE, refreshToken);
    }
  },
  getTokens: () => {
    let tknStr = getCookie(KEYCLOAK_TOKEN_COOKIE, cookies);
    if (!tknStr && canUseSessionStorage()) {
      tknStr = getSessionItem(SESSION_KEY.KEYCLOAK_TOKEN, true);
    }

    const idTknStr = getCookie(KEYCLOAK_ID_TOKEN_COOKIE, cookies);
    const refreshTokenStr = getCookie(KEYCLOAK_REFRESH_TOKEN_COOKIE, cookies);

    return {
      idToken: idTknStr || '',
      token: tknStr || '',
      refreshToken: refreshTokenStr || '',
    };
  },
  resetTokens: () => {
    removeCookie(KEYCLOAK_TOKEN_COOKIE);
    removeCookie(KEYCLOAK_ID_TOKEN_COOKIE);
    removeCookie(KEYCLOAK_REFRESH_TOKEN_COOKIE);

    if (canUseSessionStorage()) {
      removeSessionItem(SESSION_KEY.KEYCLOAK_TOKEN);
    }
  },
});
