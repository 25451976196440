import * as Sentry from '@sentry/node';

export const initializeSentry = () => {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.RT_ENVIRONMENT,
  });
};

export const logErrorInSentry = error => {
  if (process.env.RT_ENVIRONMENT !== 'development') {
    Sentry.captureException(error);
  }
};
