/* eslint-disable no-param-reassign */
import axios from 'axios';

import {
  getKcTokenClientSide,
  getOrFetchAnonymousAccessToken,
} from '@artemis/integrations/auth/utils';
import { logFailedRequest } from '@artemis/utils/log';
import { getAcceptLanguageHeader } from './util';

const createServerApiClient = ({ req, store }) => {
  const baseURL = `${process.env.RT_ORGSPACES_SERVER_BACKEND_URL}`;
  const apiClient = axios.create({ baseURL });

  apiClient.interceptors.request.use(async config => {
    config.headers['Accept-Language'] = getAcceptLanguageHeader({ req });
    const tokenRequired = !config.noTokenRequired;

    if (tokenRequired) {
      if (req?.accessToken) {
        config.headers.authorization = `Bearer ${req.accessToken}`;
      } else {
        const token = await getOrFetchAnonymousAccessToken({ req, store });
        config.headers.authorization = `Bearer ${token}`;
      }
    }

    return config;
  });

  apiClient.interceptors.response.use(
    () => {},
    err => {
      logFailedRequest({ logger: req.logger, err });
    },
  );

  return apiClient;
};

const createBrowserApiClient = ({ store }) => {
  const baseURL = `${process.env.RT_ORGSPACES_BROWSER_BACKEND_URL}`;
  const apiClient = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 'Accept-Language': getAcceptLanguageHeader() },
  });

  apiClient.interceptors.request.use(async config => {
    const tokenRequired = !config.noTokenRequired;

    if (tokenRequired) {
      const kcToken = getKcTokenClientSide();
      const accessToken =
        kcToken || (await getOrFetchAnonymousAccessToken({ store }));

      if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  });

  return apiClient;
};

export const createOrgspacesApiClient = ({
  isServer,
  req,
  res,
  store,
} = {}) => {
  if (isServer) {
    return createServerApiClient({ req, res, store });
  }
  return createBrowserApiClient({ store });
};

export const getPublicConfig = async (apiClient, { subdomain }) =>
  apiClient.get(`/organizations/configs/public`, {
    params: { subdomain },
    noTokenRequired: true,
  });

export default createOrgspacesApiClient;
