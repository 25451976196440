import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  authenticatedOnServer: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: {
      prepare: (externalId, query = {}) => ({
        payload: { externalId, query },
      }),
      reducer: () => {
        // intentionally empty; this action is only used by sagas and other reducers
      },
    },

    setAuthenticatedOnServer: (state, action) => {
      state.authenticatedOnServer = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.auth);
  },
});

export const { authenticated, setAuthenticatedOnServer } = authSlice.actions;

export default authSlice.reducer;
