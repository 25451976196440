import { createSelector } from 'reselect';

const getDeliveryAddressesRoot = state => state.deliveryAddresses;

const generatePoliticalRegions = ({ city, country, province, postalCode }) =>
  [city, province, postalCode, country]
    .filter(item => item !== undefined)
    .join(', ');

export const getDeliveryAddresses = createSelector(
  getDeliveryAddressesRoot,
  deliveryAddressesRoot => {
    const addresses =
      deliveryAddressesRoot?.data?.addresses?.map(address => ({
        ...address,
        sortDate: new Date(address.lastUsedAt || address.createdAt),
      })) || [];

    return addresses.sort((address1, address2) =>
      address1.sortDate > address2.sortDate ? -1 : 1,
    );
  },
);

export const getFormattedDeliveryAddresses = createSelector(
  getDeliveryAddresses,
  deliveryAddresses =>
    deliveryAddresses.map(
      ({
        deliveryAddressId,
        deliveryAddressDetail: {
          userDeliveryAddressExtraInfoDetail: {
            addressLine1,
            addressLine2,
            ...details
          },
        },
      }) => ({
        id: deliveryAddressId,
        streetAndUnitAddress:
          addressLine1 + (addressLine2 ? `, ${addressLine2}` : ''),
        politicalRegions: generatePoliticalRegions(details),
      }),
    ),
);

/**
 * Returns the most recently used or created delivery address
 */
export const getMostRecentDeliveryAddress = createSelector(
  getDeliveryAddresses,
  deliveryAddresses => {
    if (deliveryAddresses.length === 0) return undefined;
    return deliveryAddresses[0];
  },
);

export const getDeliveryAddressesError = createSelector(
  getDeliveryAddressesRoot,
  deliveryAddressesRoot => deliveryAddressesRoot?.error,
);

export const getDeliveryAddressesErrorBody = createSelector(
  getDeliveryAddressesError,
  error => error?.error?.errorBody,
);

export const getDeliveryAddressesInitialized = createSelector(
  getDeliveryAddressesRoot,
  deliveryAddressesRoot => deliveryAddressesRoot?.isInitialized,
);

export const getDeliveryAddressesLoading = createSelector(
  getDeliveryAddressesRoot,
  deliveryAddressesRoot => deliveryAddressesRoot?.isLoading,
);

export const getDeliveryAddressesUpdating = createSelector(
  getDeliveryAddressesRoot,
  deliveryAddressesRoot => deliveryAddressesRoot?.isUpdating,
);
