import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  loading: false,
  error: null,
  data: null,
};

const inviteSlice = createSlice({
  name: 'invite',
  initialState,
  reducers: {
    loadInvitation: state => {
      state.loading = true;
      state.error = null;
    },
    loadInvitationSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
    },
    loadInvitationError: (state, action) => {
      state.loading = false;
      state.error = action.payload.error;
    },
    clearInvitation: state => {
      state.loading = false;
      state.error = null;
      state.data = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.invite);
  },
});

export const {
  loadInvitation,
  loadInvitationSuccess,
  loadInvitationError,
  clearInvitation,
} = inviteSlice.actions;

export default inviteSlice.reducer;
