import { getInviteDetails } from '@artemis/api/invites';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
  loadInvitation,
  loadInvitationSuccess,
  loadInvitationError,
} from './slice';

export function* loadInvitationSaga({ payload }) {
  try {
    const { linkId } = payload;
    const apiClient = yield getContext('invitesApiClient');
    const { data } = yield call(getInviteDetails, apiClient, { linkId });
    yield put(loadInvitationSuccess({ data }));
  } catch (err) {
    yield put(loadInvitationError({ error: err }));
  }
}

export default function* inviteData() {
  yield takeLatest(loadInvitation.type, loadInvitationSaga);
}
