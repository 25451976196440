/* eslint-disable no-param-reassign */
import axios from 'axios';
import { serialize } from 'cookie';
import { addCookiesToResponse } from '@artemis/utils/cookie';

import {
  getKcTokenClientSide,
  getOrFetchAnonymousAccessToken,
} from '@artemis/integrations/auth/utils';
import { logFailedRequest } from '@artemis/utils/log';
import { PAYMENT_PROVIDER } from '@artemis/utils/constants';
import { getAcceptLanguageHeader } from './util';
import { ActiveOrders } from './mockResponses/activeOrders';
import { PaymentGroupsResponse } from './mockResponses/paymentGroups';
import {
  EmailVerificationStatusUnverified,
  EmailVerificationStatusVerified,
  JoinedTeam,
  TeamDetails,
  TeamSearchResults,
} from './mockResponses/teams';

const DEFAULT_DISTANCE = 5000;

const createServerApiClient = ({ req, res, store }) => {
  let baseURL = `${process.env.RT_CONTENT_GATEWAY_SERVER_BACKEND_URL}`;
  if (
    ['development', 'sandbox', 'qa'].includes(process.env.RT_ENVIRONMENT) &&
    req
  ) {
    const params = new URL(req.url, baseURL).searchParams;
    baseURL = params.server_rcg_url || baseURL;
  }

  const apiClient = axios.create({ baseURL });

  apiClient.interceptors.request.use(async config => {
    config.headers['Accept-Language'] = getAcceptLanguageHeader({ req });
    if (req?.accessToken) {
      config.headers.authorization = `Bearer ${req.accessToken}`;
    } else {
      const token = await getOrFetchAnonymousAccessToken({ req, store });
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  });

  apiClient.interceptors.response.use(
    apiResponse => {
      if (!res.headersSent) {
        if (!apiResponse.headers['set-cookie']) {
          apiResponse.headers['set-cookie'] = [];
        }
        apiResponse.headers['set-cookie'].push(
          serialize('rt-lang', req.rtLang, {
            path: '/',
            domain: process.env.RT_SHARED_COOKIE_DOMAIN,
          }),
        );
        addCookiesToResponse({
          res,
          cookies: apiResponse.headers['set-cookie'],
        });
      }
      return apiResponse;
    },
    err => {
      logFailedRequest({ logger: req.logger, err });
    },
  );

  return apiClient;
};

const createBrowserApiClient = ({ store }) => {
  let baseURL = `${process.env.RT_CONTENT_GATEWAY_BROWSER_BACKEND_URL}`;
  if (['development', 'sandbox', 'qa'].includes(process.env.RT_ENVIRONMENT)) {
    const searchParams = new URLSearchParams(window.location.search);
    baseURL = searchParams.get('server_rcg_url') || baseURL;
  }
  const apiClient = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 'Accept-Language': getAcceptLanguageHeader() },
  });

  apiClient.interceptors.request.use(async config => {
    try {
      const kcToken = getKcTokenClientSide();
      const accessToken =
        kcToken || (await getOrFetchAnonymousAccessToken({ store }));

      if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    } catch {
      // intentionally empty
    }
    return config;
  });

  return apiClient;
};

export const createContentGatewayApiClient = ({
  isServer,
  req,
  res,
  store,
} = {}) => {
  if (isServer) {
    return createServerApiClient({ req, res, store });
  }
  return createBrowserApiClient({ store });
};

export const getWorkspace = async (apiClient, { workspaceId }) =>
  apiClient.get(`/workspaces/${workspaceId}`);

export const getNearbyMerchants = async (
  apiClient,
  {
    // required
    latitude,
    longitude,

    // optional
    maxDistance = DEFAULT_DISTANCE,
    isOpenNow,
    isAvailableNow,
    hasDelivery,
    hasPickup,
    hasDineIn,
    hasGroupOrdering,
    isTopMerchant,
    notInCatalog,
    limit,
    nextPageToken,
    query,
    externalUserId,
  },
) =>
  apiClient.get(`/merchants/nearby`, {
    params: {
      latitude,
      longitude,
      maxDistance,
      isOpenNow,
      isAvailableNow,
      hasDelivery,
      hasPickup,
      hasDineIn,
      hasGroupOrdering,
      isTopMerchant,
      notInCatalog,
      limit,
      nextPageToken,
      query,
      externalUserId,
    },
  });

export const getCatalogMerchants = async (
  apiClient,
  {
    // required
    catalogId,

    // optional
    isOpenNow,
    isAvailableNow,
    hasDelivery,
    hasPickup,
    hasDineIn,
    limit,
    nextPageToken,
    latitude,
    longitude,
    query,
  },
) =>
  apiClient.get(`/merchants/catalog`, {
    params: {
      catalogId,
      isOpenNow,
      isAvailableNow,
      hasDelivery,
      hasPickup,
      hasDineIn,
      limit,
      nextPageToken,
      latitude,
      longitude,
      query,
    },
  });

export const getCatalogDetails = async (apiClient, { catalogId }) =>
  apiClient.get(`/catalogs/${catalogId}`);

export const getCategories = async (
  apiClient,
  { latLon: { latitude, longitude }, limit, maxDistance = DEFAULT_DISTANCE },
) =>
  apiClient.get(`/categories/nearby`, {
    params: {
      latitude,
      longitude,
      limit,
      maxDistance,
    },
  });

export const getActiveOrders = async (
  apiClient,
  { maxMinutesAfterComplete = 60, useMock = false } = {},
) => {
  if (useMock) {
    return { data: ActiveOrders };
  }

  return apiClient.get('/orders/active', {
    params: { maxMinutesAfterComplete },
  });
};

export const getPaymentGroups = async (apiClient, { useMock = false } = {}) => {
  if (useMock) {
    return { data: PaymentGroupsResponse };
  }

  const paymentProviders = [
    PAYMENT_PROVIDER.STRIPE,
    PAYMENT_PROVIDER.RITUAL,
  ].join(',');

  return apiClient.get('/payments/groups', {
    params: { paymentProviders },
  });
};

export const verifyWorkspaceEmail = async (
  apiClient,
  { emailCode, promoCode, useMock = false },
) => {
  if (useMock) {
    return {};
  }
  return apiClient.post(`/workspaces/verify-email`, {
    emailCode,
    promoCode,
  });
};

const mockResponseWithDelay = (data, delay = 1000) =>
  new Promise(resolve => setTimeout(() => resolve({ data }), delay));

export const searchTeams = async (
  apiClient,
  { name, limit = 10, useMock = false },
) => {
  if (useMock) {
    return mockResponseWithDelay(TeamSearchResults);
  }

  return apiClient.get('/teams/search', {
    params: { name, limit },
  });
};

export const getTeamDetails = async (
  apiClient,
  { teamId, useMock = false },
) => {
  if (useMock) {
    return mockResponseWithDelay(TeamDetails);
  }

  return apiClient.get(`/teams/${teamId}`);
};

export const joinTeam = async (
  apiClient,
  { teamId, locationId, floorId, useMock = false },
) => {
  if (useMock) {
    return mockResponseWithDelay(JoinedTeam);
  }

  return apiClient.post(`/teams/${teamId}/join`, {
    locationId,
    floorId,
  });
};

export const leaveTeam = async (apiClient, { teamId, useMock = false }) => {
  if (useMock) {
    return mockResponseWithDelay({});
  }

  return apiClient.post(`/teams/${teamId}/leave`);
};

export const addTeamLocation = async (
  apiClient,
  { teamId, googlePlaceId, workEmail, useMock = false },
) => {
  if (useMock) {
    return mockResponseWithDelay({
      ...TeamDetails.locations[0],
      secured: false,
    });
  }

  return apiClient.post(`/teams/${teamId}/locations`, {
    googlePlaceId,
    workEmail,
  });
};

export const sendVerificationEmail = async (
  apiClient,
  { teamId, sourceType, subdomain, email, promoCode, useMock = false },
) => {
  if (useMock) {
    return mockResponseWithDelay(EmailVerificationStatusUnverified);
  }

  return apiClient.post(`/teams/email-verification/send`, {
    source: {
      sourceType,
      subdomain,
    },
    email,
    promoCode,
    teamId,
  });
};

export const checkEmailVerificationStatus = async (
  apiClient,
  { workEmail, useMock = false },
) => {
  if (useMock) {
    return mockResponseWithDelay(EmailVerificationStatusVerified);
  }

  return apiClient.post(`/teams/email-verification/status-check`, {
    workEmail,
  });
};

export const createTeam = async (
  apiClient,
  { name, googlePlaceId, workEmail, useMock = false },
) => {
  if (useMock) {
    return mockResponseWithDelay(TeamDetails);
  }

  return apiClient.post(`/teams`, {
    teamDetails: {
      name,
    },
    locations: [{ googlePlaceId }],
    userSettings: { workEmail },
  });
};

export default createContentGatewayApiClient;
