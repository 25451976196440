/**
 * Merchant Preview Sagas
 */
import { put, takeLatest, call, all, getContext } from 'redux-saga/effects';
import { fetchMerchantPreview } from '@artemis/api/athena';
import { loadMenuSuccess } from '@artemis/store/menu/slice';
import { loadCartSuccess } from '@artemis/store/cart/slice';
import { loadMerchantSuccess } from '@artemis/store/merchant/slice';
import {
  loadMerchantPreview,
  loadMerchantPreviewError,
  loadMerchantPreviewSuccess,
} from './slice';

export function* loadMerchantPreviewSaga({
  payload: { merchantPreviewId, countryCode },
}) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(
      fetchMerchantPreview,
      apiClient,
      merchantPreviewId,
      countryCode,
    );
    const { merchantData, theme } = data;
    const menuData = data.menu;
    const cartData = data.exampleCart;
    merchantData.supportsDelivery = true;
    theme.headerImageUrl = theme.headerImageUrl.replace('=s667', '');
    yield all([
      put(loadMerchantPreviewSuccess()),
      put(loadMerchantSuccess({ merchantData, theme })),
      put(loadMenuSuccess(menuData)),
      put(loadCartSuccess(cartData)),
    ]);
  } catch (err) {
    yield put(loadMerchantPreviewError(err));
  }
}

export default function* merchantPreviewData() {
  yield takeLatest(loadMerchantPreview.type, loadMerchantPreviewSaga);
}
