import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const merchantGroupSlice = createSlice({
  name: 'merchantGroup',
  initialState,
  reducers: {
    loadMerchantGroup: state => {
      state.isLoading = true;
    },
    loadMerchantGroupSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadMerchantGroupError: {
      prepare: error => ({ payload: { error } }),
      reducer: (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.merchantGroup);
  },
});

export const {
  loadMerchantGroup,
  loadMerchantGroupSuccess,
  loadMerchantGroupError,
} = merchantGroupSlice.actions;

export default merchantGroupSlice.reducer;
