/**
 *
 * Merchant Selectors
 *
 */

import { createSelector } from 'reselect';
import { getCatalogCurrency } from '@artemis/store/catalog/selectors';
import {
  MARKETING_CAMPAIGN_TYPE,
  MERCHANT_AUTH_MODE,
  MERCHANT_DISPLAY_MODE,
} from '@artemis/utils/constants';
import { formatCurrency } from '@artemis/utils/currency-format';
import {
  millisToFormattedTime,
  millisToNearestXMinutes,
} from '@artemis/utils/time-format';

export const MERCHANT_STATUS = {
  unknown: 'UNKNOWN_MERCHANT_STATE',
  on: 'ON',
  off: 'OFF',
  whitelist: 'WHITELIST',
  debug: 'DEBUG',
  down: 'MERCHANT_DOWN',
  downManual: 'MERCHANT_DOWN_MANUAL',
  lost: 'LOST',
  accountIssue: 'ACCOUNT_ISSUE',
  overCapacity: 'MERCHANT_DOWN_OVER_CAPACITY',
  downPrompt: 'MERCHANT_DOWN_PROMPT',
  downUntilTomorrow: 'MERCHANT_DOWN_UNTIL_TOMORROW',
  unresponsiveOverCapacity: 'MERCHANT_DOWN_UNRESPONSIVE_OVER_CAPACITY',
  platformIssue: 'MERCHANT_DOWN_PLATFORM_ISSUE',
  unresponsivePrompt: 'MERCHANT_DOWN_UNRESPONSIVE_PROMPT',
  unresponsiveUntilTomorrow: 'MERCHANT_DOWN_UNRESPONSIVE_UNTIL_TOMORROW',
};

const getMerchantRoot = state => state.merchant;

export const getMerchantDeliveryInfoRoot = createSelector(
  getMerchantRoot,
  merchantRoot => merchantRoot.deliveryInfo,
);

export const getMerchantData = createSelector(
  getMerchantRoot,
  merchant => merchant && merchant.data && merchant.data.merchantData,
);

export const getMarketingDataList = createSelector(
  getMerchantRoot,
  merchant => merchant && merchant.data && merchant.data.marketingDataList,
);

export const getMerchantPromotions = createSelector(
  getMerchantRoot,
  merchant => merchant?.data?.promotions,
);

export const getCurrencyCode = createSelector(
  getMerchantData,
  getCatalogCurrency,
  (merchant, catalogCurrency) =>
    (merchant && merchant.currencyCode) || catalogCurrency,
);

export const getMerchantTheme = createSelector(
  getMerchantRoot,
  merchant => (merchant.data && merchant.data.theme) || {},
);

export const getMerchantName = createSelector(
  getMerchantData,
  merchant => merchant && merchant.name,
);

export const getMerchantAddressData = createSelector(
  getMerchantData,
  merchant => merchant && merchant.address,
);

export const getMerchantStreetAddress = createSelector(
  getMerchantAddressData,
  address => address && address.streetAddress,
);

export const getMerchantTimeZone = createSelector(
  getMerchantData,
  merchant => merchant && merchant.timezone,
);

export const getMerchantTodaysHours = createSelector(
  getMerchantData,
  merchant => merchant && merchant.todaysHours,
);

export const getMerchantOpenHours = createSelector(
  getMerchantData,
  merchant => merchant && merchant.openHours,
);

export const getMerchantImageUrl = createSelector(
  getMerchantData,
  merchant => merchant && merchant.imageUrl,
);

export const getMerchantPhone = createSelector(
  getMerchantData,
  merchant => merchant && merchant.phoneNumber,
);

export const getMerchantEmail = createSelector(
  getMerchantData,
  merchant => merchant && merchant.email,
);

export const getMerchantHighlightMessage = createSelector(
  getMerchantData,
  merchant => merchant && merchant.highlightMessage,
);

export const getMerchantLatLng = createSelector(
  getMerchantData,
  merchant => (merchant && merchant.latLng) || {},
);

export const getMerchantMapLink = createSelector(
  getMerchantLatLng,
  loc =>
    `https://maps.google.com/?q=${loc.latitude},${loc.longitude}&ll=${loc.latitude},${loc.longitude}`,
);

export const getMerchantCategories = createSelector(
  getMerchantData,
  merchant => merchant && merchant.categories,
);

export const getMerchantId = createSelector(
  getMerchantData,
  merchant => merchant && merchant.id,
);

export const getMerchantMenuId = createSelector(
  getMerchantData,
  merchant => merchant && merchant.menuItemGroupListId,
);

export const getMerchantSupportsDelivery = createSelector(
  getMerchantData,
  merchant => merchant && merchant.supportsDelivery,
);

export const getMerchantSupportsLoyaltyPlus = createSelector(
  getMerchantData,
  merchant => merchant && merchant.supportsLoyaltyPlus,
);

export const getMerchantDisplayMode = createSelector(
  getMerchantData,
  merchant => merchant?.displayConfiguration?.displayMode,
);

export const getIsMerchantDisplayModeDineIn = createSelector(
  getMerchantDisplayMode,
  displayMode => displayMode === MERCHANT_DISPLAY_MODE.DINE_IN,
);

export const getIsMerchantDisplayModeDelivery = createSelector(
  getMerchantDisplayMode,
  displayMode => displayMode === MERCHANT_DISPLAY_MODE.DELIVERY,
);

export const getIsMerchantDisplayModeOrderAhead = createSelector(
  getMerchantDisplayMode,
  displayMode => displayMode === MERCHANT_DISPLAY_MODE.ORDER_AHEAD,
);

export const getMerchantAuthMode = createSelector(
  getMerchantData,
  merchant => merchant?.authConfiguration?.authMode || 'DEFAULT',
);

export const getIsMerchantAuthModeCheckoutOTP = createSelector(
  getMerchantAuthMode,
  authMode => authMode === MERCHANT_AUTH_MODE.CHECKOUT_OTP,
);

export const getMerchantSupportsScheduling = createSelector(
  getMerchantData,
  merchant => {
    if (!merchant) return false;
    const { scheduledOrderTimeSlots: timeSlots } = merchant;
    if (!timeSlots || !timeSlots.length) {
      return false;
    }
    return true;
  },
);

export const getMerchantScheduledOrderTimeSlots = createSelector(
  getMerchantData,
  merchant => {
    if (!merchant) return {};
    const { scheduledOrderTimeSlots: timeSlots } = merchant;
    if (!timeSlots || !timeSlots.length) return {};
    const dateTimeMappings = timeSlots.reduce(
      (
        acc,
        { date, firstAvailableTime, lastAvailableTime, intervalMillis },
      ) => {
        // Dates can appear more than once. If we have already
        // visited the current date, then we'll concat onto it
        const formattedTimeSlots = acc[date] || [];
        let formattedTime;
        for (
          let i = firstAvailableTime;
          i <= lastAvailableTime;
          i += intervalMillis
        ) {
          formattedTime = millisToFormattedTime(i);
          formattedTimeSlots.push(formattedTime);
        }
        return {
          ...acc,
          [date]: formattedTimeSlots,
        };
      },
      {},
    );
    return dateTimeMappings;
  },
);

export const getMerchantState = createSelector(
  getMerchantData,
  merchant => merchant && merchant.state,
);

export const getMerchantUrl = createSelector(
  getMerchantData,
  merchant =>
    merchant && merchant.url && merchant.url.replace('/menu', '/order'),
);

export const getGFOMerchantUrl = createSelector(
  getMerchantData,
  merchant =>
    merchant && merchant.url && merchant.url.replace('/menu', '/place/order'),
);

export const getMerchantPickupInstructions = createSelector(
  getMerchantData,
  merchant => merchant && merchant.pickupInstructions,
);

export const getMerchantOpen = createSelector(
  getMerchantData,
  merchant => merchant && merchant.open,
);

export const getMerchantAvailable = createSelector(
  getMerchantState,
  getMerchantOpen,
  (state, isOpen) => {
    if (!isOpen) return false;
    switch (state) {
      case MERCHANT_STATUS.on:
      case MERCHANT_STATUS.whitelist:
      case MERCHANT_STATUS.debug:
        return true;
      case MERCHANT_STATUS.unknown:
      case MERCHANT_STATUS.off:
      case MERCHANT_STATUS.down:
      case MERCHANT_STATUS.downManual:
      case MERCHANT_STATUS.lost:
      case MERCHANT_STATUS.accountIssue:
      case MERCHANT_STATUS.downPrompt:
      case MERCHANT_STATUS.downUntilTomorrow:
      case MERCHANT_STATUS.unresponsivePrompt:
      case MERCHANT_STATUS.unresponsiveUntilTomorrow:
      case MERCHANT_STATUS.overCapacity:
      case MERCHANT_STATUS.unresponsiveOverCapacity:
      case MERCHANT_STATUS.platformIssue:
      default:
        return false;
    }
  },
);

export const getMerchantUnlisted = createSelector(getMerchantState, state =>
  [MERCHANT_STATUS.whitelist, MERCHANT_STATUS.debug].includes(state),
);

export const getEmailSubscriptionDefaultOn = createSelector(
  getMerchantData,
  merchant => merchant && merchant.emailSubscriptionDefaultOn,
);

export const getMerchantDefaultPhoneCountryCode = createSelector(
  getMerchantAddressData,
  address => address && address.defaultPhoneNumberCountryCode,
);

export const getPromotedMarketingCampaign = createSelector(
  getMarketingDataList,
  marketingDataList =>
    marketingDataList &&
    marketingDataList.find(
      marketingData => !!marketingData.marketingMenuHighlight,
    ),
);

export const getEmployeePricingMarketingCampaign = createSelector(
  getMarketingDataList,
  marketingDataList =>
    marketingDataList?.find(
      campaign =>
        campaign?.campaignType === MARKETING_CAMPAIGN_TYPE.EMPLOYEE_PRICING,
    ),
);

export const getPromotedMarketingBanner = createSelector(
  getPromotedMarketingCampaign,
  promotedMarketingCampaign =>
    promotedMarketingCampaign?.marketingMenuHighlight?.menuHighlightText,
);

export const getPromotedMarketingPopup = createSelector(
  getPromotedMarketingCampaign,
  promotedMarketingCampaign =>
    promotedMarketingCampaign?.marketingMenuHighlight?.menuPopUp,
);

export const getPromotedMarketingCampaignType = createSelector(
  getPromotedMarketingCampaign,
  promotedMarketingCampaign =>
    promotedMarketingCampaign?.campaignType || MARKETING_CAMPAIGN_TYPE.GENERIC,
);

export const getPromotedMarketingPopupSuppressed = createSelector(
  getPromotedMarketingCampaign,
  promotedMarketingCampaign =>
    promotedMarketingCampaign?.marketingMenuHighlight?.suppressPopupOnLoad,
);

export const getMerchantCountryUsesSpecialAddressFormats = createSelector(
  getMerchantAddressData,
  address => ['United Kingdom', 'UK'].includes(address?.country),
);

export const getMerchantUsesTakeaway = createSelector(
  getMerchantAddressData,
  address =>
    ['United Kingdom', 'UK'].includes(address?.country) ||
    ['Australia', 'AU'].includes(address?.country),
);

const getUserStampCardProgress = createSelector(
  getMerchantRoot,
  merchant => merchant?.data?.userStampCardProgress,
);

export const getUserStampsCollected = createSelector(
  getUserStampCardProgress,
  progress => progress?.totalStampsCollected,
);

export const getUserStampCardLength = createSelector(
  getUserStampCardProgress,
  progress => progress?.stampCardLength,
);

export const getIsMerchantLoading = createSelector(
  getMerchantRoot,
  merchantRoot => merchantRoot.isLoading,
);

export const getMerchantDeliveryCostCheckpoints = createSelector(
  getMerchantDeliveryInfoRoot,
  getCurrencyCode,
  (deliveryInfo, currencyCode) => {
    if (!deliveryInfo) return {};

    const { deliveryMinimumOrderValueMicro, freeDeliveryOrderValueMicro } =
      deliveryInfo;
    return {
      ...(deliveryMinimumOrderValueMicro != null && {
        deliveryMinimumOrderValueMicro,
        deliveryMinimumOrderValue: formatCurrency({
          value: deliveryMinimumOrderValueMicro,
          currencyCode,
        }),
      }),
      ...(freeDeliveryOrderValueMicro != null && {
        freeDeliveryOrderValueMicro,
        freeDeliveryOrderValue: formatCurrency({
          value: freeDeliveryOrderValueMicro,
          currencyCode,
        }),
      }),
    };
  },
);

export const getHasDeliveryMinimumOrderValue = createSelector(
  getMerchantDeliveryInfoRoot,
  deliveryInfo => !!deliveryInfo?.deliveryMinimumOrderValueMicro,
);

export const getDeliveryMinimumOrderValue = createSelector(
  getMerchantDeliveryInfoRoot,
  getCurrencyCode,
  (deliveryInfo, currencyCode) =>
    formatCurrency({
      value: deliveryInfo?.deliveryMinimumOrderValueMicro,
      currencyCode,
    }),
);

export const getHasFreeDeliveryOrderValue = createSelector(
  getMerchantDeliveryInfoRoot,
  deliveryInfo => !!deliveryInfo?.freeDeliveryOrderValueMicro,
);

export const getMerchantDeliveryInfo = createSelector(
  getMerchantDeliveryInfoRoot,
  deliveryInfo => {
    if (!deliveryInfo) return {};

    const { deliveryChargeMicro, estimatedDeliveryTimeRange } = deliveryInfo;

    if (!estimatedDeliveryTimeRange) {
      return {
        merchantDeliveryChargeMicro: deliveryChargeMicro,
        estimatedDeliveryTimeInMinutes: undefined,
      };
    }

    const { minimum, maximum } = estimatedDeliveryTimeRange;

    if (!minimum || !maximum) {
      return {
        merchantDeliveryChargeMicro: deliveryChargeMicro,
        estimatedDeliveryTimeInMinutes: undefined,
      };
    }

    return {
      merchantDeliveryChargeMicro: deliveryChargeMicro,
      estimatedDeliveryTimeInMinutes: {
        min: millisToNearestXMinutes(minimum, 5),
        max: millisToNearestXMinutes(maximum, 5),
      },
    };
  },
);

export const getIsMerchantDeliveryInfoLoading = createSelector(
  getMerchantRoot,
  merchant => merchant?.isLoadingDeliveryInfo,
);

export const getMerchantSupportsGroupOrdering = createSelector(
  getMerchantData,
  merchant => merchant?.supportsGroupOrdering || false,
);

export const getShouldSuppressSearchIndexing = createSelector(
  getMerchantData,
  merchant => merchant?.suppressSearchIndexing || false,
);

export const getIsRelayMerchant = createSelector(
  getMerchantData,
  merchant => merchant?.isRelayMerchant || false,
);

export const getExternalMenuUrl = createSelector(
  getMerchantData,
  merchant => merchant?.externalMenuUrl || '#',
);

export const getMerchantServiceFeeDisclosure = createSelector(
  getMerchantRoot,
  merchant => merchant && merchant.data && merchant.data.serviceFeeDisclosure,
);

export const getShowServiceFeeDisclosure = createSelector(
  getMerchantServiceFeeDisclosure,
  serviceFeeDisclosure => serviceFeeDisclosure?.showDisclosure || false,
);

export const getServiceFeeDisclosurePopup = createSelector(
  getMerchantServiceFeeDisclosure,
  serviceFeeDisclosure => ({
    subtitle: serviceFeeDisclosure?.popupSubtitle,
    description: serviceFeeDisclosure?.popupDescription,
  }),
);
