import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { authenticated } from '@artemis/store/auth/slice';
import {
  MENU_DEBUG_PARAM,
  MENU_DRAFT_ID_PARAM,
  MERCHANT_GROUP_REFERER_PARAM,
  MID_PARAM,
} from '@artemis/utils/query/constants';
import { getCheckoutCodeFromQuery } from '@artemis/utils/promo';

const loadMerchantOptions = {
  refetchMenuAfterLoad: true,
};

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
  override: {},
  isLoadingDeliveryInfo: false,
  deliveryInfo: null,
};

const loadMerchantReducer = state => {
  state.isLoading = true;
};

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    loadMerchant: {
      prepare: (query = {}, options = loadMerchantOptions) => {
        const {
          [MID_PARAM]: externalId,
          [MERCHANT_GROUP_REFERER_PARAM]: brandExternalId,
          [MENU_DEBUG_PARAM]: debug,
          [MENU_DRAFT_ID_PARAM]: menuDraftId,
        } = query;
        const cartCheckoutCode = getCheckoutCodeFromQuery(query);
        return {
          payload: {
            externalId,
            brandExternalId,
            cartCheckoutCode,
            debug: debug !== undefined,
            menuDraftId,
            options,
          },
        };
      },
      reducer: loadMerchantReducer,
    },
    loadMerchantById: {
      prepare: (id, options = loadMerchantOptions) => ({
        payload: { id, options },
      }),
      reducer: loadMerchantReducer,
    },
    loadMerchantData: loadMerchantReducer,
    merchantLoaded: state => {
      state.isLoading = false;
    },
    loadMerchantSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadMerchantError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    merchantDeliveryInfoLoading: state => {
      state.isLoadingDeliveryInfo = true;
    },
    merchantDeliveryInfoLoaded: (state, action) => {
      state.isLoadingDeliveryInfo = false;
      state.deliveryInfo = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(HYDRATE, (_, action) => action.payload.merchant)
      .addCase(authenticated, loadMerchantReducer);
  },
});

export const {
  loadMerchant,
  loadMerchantById,
  loadMerchantData,
  merchantLoaded,
  loadMerchantSuccess,
  loadMerchantError,
  merchantDeliveryInfoLoading,
  merchantDeliveryInfoLoaded,
} = merchantSlice.actions;

export default merchantSlice.reducer;
