/**
 * Delivery Sagas
 */
import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { fetchDelivery } from '@artemis/api/athena';
import { loadDelivery, loadDeliveryError, loadDeliverySuccess } from './slice';

export function* loadDeliverySaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchDelivery, apiClient, payload);
    yield put(loadDeliverySuccess(data));
  } catch (err) {
    yield put(
      loadDeliveryError(err?.response?.data?.error || { errorType: 'UNKNOWN' }),
    );
  }
}

export default function* getDeliveryData() {
  yield takeLatest(loadDelivery.type, loadDeliverySaga);
}
