/**
 * MerchantGroup Sagas
 */

import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { fetchMerchantGroup } from '@artemis/api/athena';
import {
  loadMerchantGroup,
  loadMerchantGroupError,
  loadMerchantGroupSuccess,
} from './slice';

export function* loadMerchantGroupSaga({
  payload: { externalId, brandExternalId },
}) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(
      fetchMerchantGroup,
      apiClient,
      externalId,
      brandExternalId,
    );
    yield put(loadMerchantGroupSuccess(data));
  } catch (err) {
    yield put(loadMerchantGroupError(err));
  }
}

export default function* merchantGroupData() {
  yield takeLatest(loadMerchantGroup.type, loadMerchantGroupSaga);
}
