import { put, takeLatest, call, select } from 'redux-saga/effects';
import { getSupportedPaymentMethods } from '@artemis/integrations/embed/webviewHooks';
import { getPaymentMethods as getHqOPaymentMethods } from '@artemis/integrations/embed/hqo/hqoClient';
import { EMBEDDED_PAYMENT_METHODS } from '@artemis/integrations/embed/webviewHooks/constants';
import { getIsEmbedded, getIsEmbeddedHqO } from './selectors';
import {
  loadPaymentMethods,
  loadPaymentMethodsError,
  loadPaymentMethodsSuccess,
  setPaymentMethodsLoading,
  setSupportedPaymentMethodsLoading,
} from './slice';

export function* loadPaymentMethodsSaga() {
  const isEmbedded = yield select(getIsEmbedded);
  if (!isEmbedded) return;

  // HqO will load stored cards along with supported payment methods
  // Other integrations will only load supported payment methods
  yield put(setSupportedPaymentMethodsLoading(true));

  // Use HqO SDK to get payment methods for embedded HqO
  const isHqO = yield select(getIsEmbeddedHqO);
  if (isHqO) {
    yield put(setPaymentMethodsLoading(true));
    try {
      const hqoPaymentMethods = yield call(getHqOPaymentMethods);
      const supportedPaymentMethods = hqoPaymentMethods?.apple_pay // eslint-disable-line camelcase
        ? [EMBEDDED_PAYMENT_METHODS.APPLE_PAY]
        : [];

      yield put(
        loadPaymentMethodsSuccess({
          storedCards: hqoPaymentMethods?.cards,
          supportedPaymentMethods,
        }),
      );
    } catch (e) {
      yield put(loadPaymentMethodsError(e));
    }
    return;
  }

  // Use JavaScript bridge for other embedded integrations
  try {
    const supportedPaymentMethods = yield call(getSupportedPaymentMethods);
    if (Array.isArray(supportedPaymentMethods)) {
      yield put(loadPaymentMethodsSuccess({ supportedPaymentMethods }));
    } else if (typeof supportedPaymentMethods === 'string') {
      yield put(
        loadPaymentMethodsSuccess({
          supportedPaymentMethods: JSON.parse(supportedPaymentMethods),
        }),
      );
    } else {
      // getSupportedPaymentMethods is not implemented by the integration client
      yield put(
        loadPaymentMethodsSuccess({
          supportedPaymentMethods: [],
        }),
      );
    }
  } catch (e) {
    yield put(loadPaymentMethodsError(e));
  }
}

export default function* embedData() {
  yield takeLatest(loadPaymentMethods.type, loadPaymentMethodsSaga);
}
