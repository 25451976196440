/**
 * Returns number of selected options (accounting for options with quantities)
 */
export const getSelectedOptionsCount = options =>
  options
    .filter(option => option.selected)
    .reduce((acc, option) => acc + (option.incrementCount || 1), 0);

/**
 * Returns number of unique selected options
 */
export const getUniqueSelectedOptionsCount = options =>
  options.filter(option => option.selected).length;

/**
 * Returns boolean indicating if more options need to be selected.
 */
export const getNeedsMoreSelected = (options, choice) => {
  if (
    choice.minSelectable &&
    getSelectedOptionsCount(options) < choice.minSelectable
  ) {
    return true;
  }
  if (
    choice.minUniqueSelectable &&
    getUniqueSelectedOptionsCount(options) < choice.minUniqueSelectable
  ) {
    return true;
  }
  return false;
};

/**
 * Returns the total price of selected options (accounting for options with quantities)
 */
export const getSelectedOptionsTotalPriceMicro = options =>
  options
    .filter(option => option.selected)
    .reduce(
      (acc, option) =>
        acc + (option.incrementCount || 1) * (option.priceMicro || 0),
      0,
    );

/**
 * Formats the option quantity selected by the user as a string
 * e.g. if the user selects 3 increments of a 1/2 option, this returns "1.5"
 */
export const formatIncrement = ({
  incrementCount,
  incrementNumerator = 1,
  incrementDenominator = 1,
}) => {
  const quantity = incrementCount * (incrementNumerator / incrementDenominator);
  const isWholeNumber = Math.round(quantity) === quantity;

  return isWholeNumber ? `${quantity}` : `${quantity.toFixed(1)}`;
};
