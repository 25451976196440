import { put, takeLatest, call, getContext, select } from 'redux-saga/effects';
import {
  getCatalogMerchants,
  getNearbyMerchants,
  getCatalogDetails,
} from '@artemis/api/contentGateway';
import { getUserExternalId } from '@artemis/store/user/selectors';
import { updateWorkspaceId } from '@artemis/store/workspace/slice';
import {
  loadMerchants,
  loadMerchantsSuccess,
  loadMoreMerchantsSuccess,
  loadMerchantsError,
  loadNearbyMerchantsSuccess,
  loadMoreNearbyMerchantsSuccess,
  loadNearbyMerchantsError,
  reloadMerchants,
  reloadNearbyMerchants,
  loadCatalogDetailsSuccess,
  loadCatalogDetailsError,
  loadMoreMerchants,
  loadNearbyMerchants,
  loadMoreNearbyMerchants,
  loadCatalogDetails,
} from './slice';

export function* loadMerchantsSaga(action) {
  const { payload } = action;
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(getCatalogMerchants, apiClient, { ...payload });
    yield put(loadMerchantsSuccess(data));
  } catch (error) {
    yield put(loadMerchantsError({ error }));
  }
}

export function* loadMoreMerchantsSaga(action) {
  const { payload } = action;
  const apiClient = yield getContext('contentGatewayApiClient');
  try {
    const { data } = yield call(getCatalogMerchants, apiClient, { ...payload });
    yield put(loadMoreMerchantsSuccess(data));
  } catch (error) {
    yield put(loadMerchantsError({ error }));
    // if it's failing because nextPageToken, re-run search
    if (
      error?.response?.data?.errors[0].parameter === 'nextPageToken' &&
      error?.response?.data?.errors[0].code === 'INVALID_PARAMETER'
    ) {
      yield put(reloadMerchants(payload));
    }
  }
}

export function* loadNearbyMerchantsSaga(action) {
  const { payload } = action;
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const externalUserId = yield select(getUserExternalId);
    const { data } = yield call(getNearbyMerchants, apiClient, {
      externalUserId,
      ...payload,
    });
    yield put(loadNearbyMerchantsSuccess(data));
  } catch (error) {
    yield put(loadNearbyMerchantsError({ error }));
  }
}

export function* loadMoreNearbyMerchantsSaga(action) {
  const { payload } = action;
  const apiClient = yield getContext('contentGatewayApiClient');
  try {
    const externalUserId = yield select(getUserExternalId);
    const { data } = yield call(getNearbyMerchants, apiClient, {
      externalUserId,
      ...payload,
    });
    yield put(loadMoreNearbyMerchantsSuccess(data));
  } catch (error) {
    yield put(loadNearbyMerchantsError({ error }));
    // if it's failing because nextPageToken, re-run search
    if (
      error?.response?.data?.errors[0].parameter === 'nextPageToken' &&
      error?.response?.data?.errors[0].code === 'INVALID_PARAMETER'
    ) {
      yield put(reloadNearbyMerchants(payload));
    }
  }
}

export function* loadCatalogDetailsSaga(action) {
  const { payload } = action;
  const apiClient = yield getContext('contentGatewayApiClient');
  try {
    const { data, headers } = yield call(getCatalogDetails, apiClient, {
      ...payload,
    });

    // Unique identifier to be used for sending analytics events from the web app.
    // If the requested catalog is "Merchants List of a Workspace", then header's value is Workspace ID.
    // PR: https://github.com/RitualCo/openapi-specifications/pull/246/files
    yield put(loadCatalogDetailsSuccess(data));
    const analyticsId = headers['x-ritual-analytics-id'];
    yield put(updateWorkspaceId(analyticsId));
  } catch (err) {
    yield put(loadCatalogDetailsError(err));
  }
}

export default function* catalogData() {
  yield takeLatest(loadMerchants.type, loadMerchantsSaga);
  yield takeLatest(reloadMerchants.type, loadMerchantsSaga);
  yield takeLatest(loadMoreMerchants.type, loadMoreMerchantsSaga);
  yield takeLatest(loadNearbyMerchants.type, loadNearbyMerchantsSaga);
  yield takeLatest(reloadNearbyMerchants.type, loadNearbyMerchantsSaga);
  yield takeLatest(loadMoreNearbyMerchants.type, loadMoreNearbyMerchantsSaga);
  yield takeLatest(loadCatalogDetails.type, loadCatalogDetailsSaga);
}
