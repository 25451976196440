import { put, takeLatest, call } from 'redux-saga/effects';
import fetchIpData from '@artemis/integrations/ipData';
import { loadIpData, loadIpDataError, loadIpDataSuccess } from './slice';

export function* loadIpDataSaga(action) {
  const {
    payload: { ipAddress },
  } = action;
  try {
    const data = yield call(fetchIpData, ipAddress);
    yield put(loadIpDataSuccess(data));
  } catch (err) {
    yield put(loadIpDataError(err));
  }
}

export default function* ipData() {
  yield takeLatest(loadIpData.type, loadIpDataSaga);
}
