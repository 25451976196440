export const VERIFICATION_ERROR_CODE = {
  EMAIL_DOES_NOT_MATCH_TEAM: 'EMAIL_DOES_NOT_MATCH_TEAM',
  EMAIL_DOMAIN_NOT_ALLOWED: 'EMAIL_DOMAIN_NOT_ALLOWED',
  TEAM_INVITE_REQUIRED: 'TEAM_INVITE_REQUIRED',
};

export const JOIN_ERROR_CODE = {
  EMAIL_VERIFICATION_REQUIRED: 'EMAIL_VERIFICATION_REQUIRED',
  USER_NOT_WHITELISTED: 'USER_NOT_WHITELISTED',
};

export const TEAM_MEMBERSHIP_STATUS = {
  NOT_JOINED: 'NOT_JOINED',
  MEMBER: 'MEMBER',
};

export const JOIN_TEAM_PAGE_PATH = '/order/team/join';
export const JOIN_TEAM_PAGE_PATH_V2 = '/order/team/join/v2';

export const EMAIL_VERIFICATION_STATUS = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  PENDING: 'PENDING',
  VERIFIED: 'VERIFIED',
};

export const TEAM_STATUS = {
  DOES_NOT_EXIST: 'DOES_NOT_EXIST',
  INVITE_ONLY: 'INVITE_ONLY',
  JOINABLE: 'JOINABLE',
  JOINED: 'JOINED',
};
