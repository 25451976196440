import { css } from 'styled-components';

export default {
  horizontal: css`
    background:
      /* Shadow covers */ linear-gradient(
        white 100%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(white 100%, rgba(255, 255, 255, 0)) 100% 100%,
      /* Shadows */
        radial-gradient(
          0 50%,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          100% 50%,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        100% 0;
    background:
      /* Shadow covers */ linear-gradient(
        white 100%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(white 100%, rgba(255, 255, 255, 0)) 100% 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 0 50%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 100% 50%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        100% 0;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100px 100%, 100px 100%, 20px 100%, 20px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  `,
  vertical: css`
    background:
      /* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          50% 0,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          50% 100%,
          farthest-side,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background:
      /* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  `,
  /* This renders a basic edge to edge flat linear gradient */
  verticalLinear: css`
    background:
      /* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ linear-gradient(rgba(85, 85, 85, 0.1), rgba(0, 0, 0, 0)),
      linear-gradient(rgba(0, 0, 0, 0), rgba(85, 85, 85, 0.1)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 8px, 100% 8px;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  `,
};
