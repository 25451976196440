import {
  createFulfillment,
  findFulfillments,
  getFulfillmentDetails,
  getFulfillmentScheduledTimeSlots,
  getFulfillmentStatus,
  joinFulfillment,
  leaveFulfillment,
  updateFulfillment,
  getFulfillmentTipOptions,
  getFulfillmentValidationErrors,
  removeFulfillmentGuest,
  cancelFulfillment,
  confirmFulfillment,
  getFulfillmentTypeOptions,
  createInvite,
} from '@artemis/api/fulfillment';
import {
  all,
  call,
  getContext,
  put,
  takeLatest,
  select,
  delay,
} from 'redux-saga/effects';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import { loadMerchantByIdSaga } from '@artemis/store/merchant/sagas';
import { addToast } from '@artemis/store/toasts/slice';
import { handleStripePaymentChallenge } from '@artemis/utils/payment';
import {
  loadInviteFulfillment,
  loadInviteFulfillmentSuccess,
  loadInviteFulfillmentError,
  joinFulfillment as joinFulfillmentAction,
  joinFulfillmentSuccess,
  joinFulfillmentError,
  findFulfillments as findFulfillmentsAction,
  findFulfillmentsSuccess,
  findFulfillmentsError,
  loadFulfillment,
  loadFulfillmentSuccess,
  loadFulfillmentError,
  loadFulfillmentStatus,
  loadFulfillmentStatusSuccess,
  loadFulfillmentStatusError,
  leaveFulfillment as leaveFulfillmentAction,
  leaveFulfillmentSuccess,
  leaveFulfillmentError,
  createFulfillment as createFulfillmentAction,
  createFulfillmentSuccess,
  createFulfillmentError,
  updateFulfillment as updateFulfillmentAction,
  updateFulfillmentSuccess,
  updateFulfillmentError,
  loadScheduledTimeSlots,
  loadScheduledTimeSlotsSuccess,
  loadScheduledTimeSlotsError,
  beginPollingForFulfillmentUpdates,
  loadTipDetailsSuccess,
  loadTipDetailsError,
  setPaymentSplitModal,
  setSchedulingModal,
  validateFulfillmentError,
  validateFulfillmentSuccess,
  removeGuestSuccess,
  removeGuestError,
  cancelFulfillment as cancelFulfillmentAction,
  cancelFulfillmentSuccess,
  cancelFulfillmentError,
  confirmFulfillment as confirmFulfillmentAction,
  confirmFulfillmentSuccess,
  confirmFulfillmentError,
  setCancelModal,
  loadFulfillmentTypeOptions,
  loadFulfillmentTypeOptionsSuccess,
  loadFulfillmentTypeOptionsError,
  createInvite as createInviteAction,
  createInviteSuccess,
  createInviteError,
  setDeliveryModal,
  setGuestLimitModal,
  loadTipDetails,
  validateFulfillment,
  removeGuest,
} from './slice';
import {
  getFulfillmentStatus as selectGetFulfillmentStatus,
  getFulfillmentId,
  getFirstAvailableTimeslot,
} from './selectors';

export function* loadInviteFulfillmentSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(getFulfillmentDetails, apiClient, {
      fulfillmentId,
    });
    yield put(loadInviteFulfillmentSuccess({ data }));
  } catch (err) {
    yield put(loadInviteFulfillmentError({ error: err }));
  }
}

export function* joinFulfillmentSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(joinFulfillment, apiClient, { fulfillmentId });
    yield put(joinFulfillmentSuccess({ data }));
    yield put(loadFulfillment({ fulfillmentId }));
  } catch (err) {
    yield put(joinFulfillmentError({ error: err }));
  }
}

export function* findFulfillmentsSaga({ payload }) {
  try {
    const {
      merchantId,
      basketId,
      orderToken,
      orderId,
      fulfillmentState,
      guestStatus,
    } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(findFulfillments, apiClient, {
      merchantIds: merchantId,
      basketIds: basketId,
      orderTokens: orderToken,
      orderIds: orderId,
      fulfillmentStateFilter: fulfillmentState,
      guestStatus,
    });
    yield put(findFulfillmentsSuccess({ data }));
  } catch (err) {
    yield put(findFulfillmentsError({ error: err }));
  }
}

export function* loadFulfillmentSaga({ payload }) {
  try {
    const { fulfillmentId, includeOrderId, loadMerchant } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(getFulfillmentDetails, apiClient, {
      fulfillmentId,
      includeOrderId: includeOrderId || false,
    });
    yield put(loadFulfillmentSuccess({ data }));

    const merchantId = data?.merchantIds?.[0];
    if (loadMerchant && merchantId) {
      yield call(loadMerchantByIdSaga, {
        payload: {
          id: merchantId,
          options: { refetchMenuAfterLoad: false },
        },
      });
    }
  } catch (err) {
    yield put(loadFulfillmentError({ error: err }));
  }
}

export function* loadFulfillmentStatusSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(getFulfillmentStatus, apiClient, {
      fulfillmentId,
    });
    yield put(loadFulfillmentStatusSuccess({ data }));
  } catch (err) {
    yield put(loadFulfillmentStatusError({ error: err }));
  }
}

const guestsEqual = (oldGuests, newGuests) => {
  if (oldGuests.length !== newGuests.length) {
    return false;
  }

  for (let i = 0; i < oldGuests.length; i += 1) {
    if (
      oldGuests[i].externalUserId !== newGuests[i].externalUserId ||
      oldGuests[i].status !== newGuests[i].status
    ) {
      return false;
    }
  }
  return true;
};

const shouldReloadFulfillmentDetails = (oldStatus, newStatus) => {
  if (isEmpty(oldStatus) || isEmpty(newStatus)) {
    return false;
  }

  if (!oldStatus) {
    return false;
  }

  const fields = [
    'fulfillmentState',
    'expirationTime',
    'orderByTime',
    'completeTime',
  ];

  return (
    fields.some(field => !isEqual(oldStatus[field], newStatus[field])) ||
    !guestsEqual(oldStatus.guestStatus, newStatus.guestStatus)
  );
};

export function* pollForFulfillmentsUpdateSaga({ payload }) {
  const includeOrderId = payload?.includeOrderId || false;
  while (true) {
    const fulfillmentId = yield select(getFulfillmentId);
    const oldStatus = yield select(selectGetFulfillmentStatus);
    // Stop polling if there is no longer a fulfillment ID
    if (!fulfillmentId) {
      return;
    }
    try {
      yield call(loadFulfillmentStatusSaga, { payload: { fulfillmentId } });
      const newStatus = yield select(selectGetFulfillmentStatus);

      if (shouldReloadFulfillmentDetails(oldStatus, newStatus)) {
        yield put(loadFulfillment({ fulfillmentId, includeOrderId }));
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('pollForFulfillmentsUpdateSaga error:', err);
    } finally {
      yield delay(process.env.RT_GROUP_ORDER_POLL_FREQUENCY);
    }
  }
}

export function* leaveFulfillmentSaga({ payload }) {
  try {
    const { fulfillmentId, redirectUri } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    yield call(leaveFulfillment, apiClient, {
      fulfillmentId,
    });
    yield put(leaveFulfillmentSuccess());

    if (redirectUri) {
      window.location.assign(redirectUri);
    }
  } catch (err) {
    yield put(leaveFulfillmentError({ error: err }));
  }
}

export function* createFulfillmentSaga({ payload }) {
  try {
    const { fulfillment, persistedQuery, deliveryAddressId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(createFulfillment, apiClient, {
      fulfillment,
    });
    yield put(createFulfillmentSuccess({ data }));
    const fulfillmentId = data?.fulfillmentId;

    if (fulfillmentId) {
      const redirectUri = queryString.stringifyUrl({
        url: `/order/fulfillments/${fulfillmentId}/edit${persistedQuery}`,
        query: deliveryAddressId ? { deliveryAddressId } : {},
      });

      window.location.assign(redirectUri);
    }
  } catch (err) {
    yield put(createFulfillmentError({ error: err }));
  }
}

export function* updateFulfillmentSaga({ payload }) {
  try {
    const { fulfillmentId, fulfillment, resetScheduledTime } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    let { data } = yield call(updateFulfillment, apiClient, {
      fulfillmentId,
      fulfillment,
    });

    if (
      fulfillment?.deliveryDetails ||
      fulfillment?.guestMax ||
      fulfillment?.fulfillmentType
    ) {
      yield call(loadScheduledTimeSlotsSaga, { payload: { fulfillmentId } });
    }

    // Update fulfillment to earliest available timeslot
    if (resetScheduledTime) {
      const earliestTimeSlot = yield select(getFirstAvailableTimeslot);
      const response = yield call(updateFulfillment, apiClient, {
        fulfillmentId,
        fulfillment: {
          scheduledDetails: {
            scheduled: true,
            scheduledForTime: earliestTimeSlot,
          },
        },
      });
      data = response.data;
    }

    yield put(updateFulfillmentSuccess({ data }));

    if (fulfillment?.ownerPaymentOptions) {
      yield put(setPaymentSplitModal({ isOpen: false }));
    }
    if (fulfillment?.deliveryDetails) {
      yield put(setDeliveryModal({ isOpen: false }));
    }
    if (fulfillment?.guestMax) {
      yield put(setGuestLimitModal({ isOpen: false }));
    }
    if (fulfillment?.scheduledDetails) {
      yield put(setSchedulingModal({ isOpen: false }));
    }
  } catch (error) {
    yield put(updateFulfillmentError({ error }));
  }
}

export function* loadTipDetailsSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(getFulfillmentTipOptions, apiClient, {
      fulfillmentId,
    });
    yield put(loadTipDetailsSuccess({ data }));
  } catch (err) {
    yield put(loadTipDetailsError({ error: err }));
  }
}

export function* loadFulfillmentTypeOptionsSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(getFulfillmentTypeOptions, apiClient, {
      fulfillmentId,
    });
    yield put(loadFulfillmentTypeOptionsSuccess({ data }));
  } catch (err) {
    yield put(loadFulfillmentTypeOptionsError({ error: err }));
  }
}

export function* loadScheduledTimeSlotsSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(getFulfillmentScheduledTimeSlots, apiClient, {
      fulfillmentId,
    });
    yield put(loadScheduledTimeSlotsSuccess({ data }));
  } catch (err) {
    yield put(loadScheduledTimeSlotsError({ error: err }));
  }
}

export function* validateFulfillmentSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    const { data } = yield call(getFulfillmentValidationErrors, apiClient, {
      fulfillmentId,
    });
    yield put(validateFulfillmentSuccess({ data }));
  } catch (err) {
    yield put(validateFulfillmentError({ error: err }));
  }
}

export function* removeGuestSaga({ payload }) {
  try {
    const { fulfillmentId, externalUserId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    yield call(removeFulfillmentGuest, apiClient, {
      fulfillmentId,
      externalUserId,
    });
    yield all([
      put(removeGuestSuccess()),
      put(loadFulfillment({ fulfillmentId })),
    ]);
  } catch (err) {
    yield put(removeGuestError({ error: err }));
  }
}

export function* cancelFulfillmentSaga({ payload }) {
  try {
    const { fulfillmentId } = payload;
    const apiClient = yield getContext('fulfillmentApiClient');
    yield call(cancelFulfillment, apiClient, {
      fulfillmentId,
    });

    yield all([
      put(cancelFulfillmentSuccess()),
      call(loadFulfillmentSaga, { payload: { fulfillmentId } }),
    ]);
    yield put(setCancelModal({ isOpen: false }));
  } catch (err) {
    yield put(cancelFulfillmentError({ error: err }));
    yield put(addToast({ toast: { error: { message: 'errors.tryAgain' } } }));
  }
}

export function* confirmFulfillmentSaga({ payload }) {
  const {
    fulfillmentId,
    checksum,
    fundingSourceId,
    paymentMethod,
    subdomain,
    redirectUri,
  } = payload;

  const apiClient = yield getContext('fulfillmentApiClient');

  try {
    const { data } = yield call(confirmFulfillment, apiClient, {
      fulfillmentId,
      checksum,
      fundingSourceId,
      paymentMethod,
      subdomain,
    });
    yield put(confirmFulfillmentSuccess({ data }));

    if (redirectUri) {
      window.location.assign(redirectUri);
    }
  } catch (err) {
    const { paymentChallenge } = err?.response?.data || {};
    if (paymentChallenge) {
      try {
        // Prompt user to answer the challenge
        const {
          stripeChallenge: { apiKey, clientSecret },
          challengeMetadata,
        } = paymentChallenge;

        const { stripeId } = yield call(handleStripePaymentChallenge, {
          apiKey,
          clientSecret,
        });

        // Resubmit confirm request with the answer
        const { data } = yield call(confirmFulfillment, apiClient, {
          fulfillmentId,
          checksum,
          fundingSourceId,
          paymentMethod,
          paymentChallengeAnswer: {
            stripeChallengeAnswer: {
              stripeId,
            },
            challengeMetadataReplay: challengeMetadata,
          },
        });
        yield put(confirmFulfillmentSuccess({ data }));
        if (redirectUri) {
          window.location.assign(redirectUri);
        }

        return;
      } catch (e) {
        // intentionally left blank
      }
    }

    yield put(addToast({ toast: { error: { message: 'errors.tryAgain' } } }));
    yield put(confirmFulfillmentError({ error: err }));
  }
}

export function* createInviteSaga({ payload }) {
  const { fulfillmentId, subdomain, redirectUri } = payload;

  const apiClient = yield getContext('fulfillmentApiClient');

  try {
    const { data } = yield call(createInvite, apiClient, {
      fulfillmentId,
      subdomain,
    });
    yield call(joinFulfillment, apiClient, { fulfillmentId });
    yield put(createInviteSuccess({ data }));

    if (redirectUri) {
      window.location.assign(redirectUri);
    }
  } catch (err) {
    yield put(addToast({ toast: { error: { message: 'errors.tryAgain' } } }));
    yield put(createInviteError({ error: err }));
  }
}

export default function* groupOrderData() {
  yield takeLatest(loadInviteFulfillment.type, loadInviteFulfillmentSaga);
  yield takeLatest(joinFulfillmentAction.type, joinFulfillmentSaga);
  yield takeLatest(findFulfillmentsAction.type, findFulfillmentsSaga);
  yield takeLatest(loadFulfillment.type, loadFulfillmentSaga);
  yield takeLatest(loadFulfillmentStatus.type, loadFulfillmentStatusSaga);
  yield takeLatest(
    beginPollingForFulfillmentUpdates.type,
    pollForFulfillmentsUpdateSaga,
  );
  yield takeLatest(leaveFulfillmentAction.type, leaveFulfillmentSaga);
  yield takeLatest(createFulfillmentAction.type, createFulfillmentSaga);
  yield takeLatest(updateFulfillmentAction.type, updateFulfillmentSaga);
  yield takeLatest(loadScheduledTimeSlots.type, loadScheduledTimeSlotsSaga);
  yield takeLatest(loadTipDetails.type, loadTipDetailsSaga);
  yield takeLatest(
    loadFulfillmentTypeOptions.type,
    loadFulfillmentTypeOptionsSaga,
  );
  yield takeLatest(validateFulfillment.type, validateFulfillmentSaga);
  yield takeLatest(removeGuest.type, removeGuestSaga);
  yield takeLatest(cancelFulfillmentAction.type, cancelFulfillmentSaga);
  yield takeLatest(confirmFulfillmentAction.type, confirmFulfillmentSaga);
  yield takeLatest(createInviteAction.type, createInviteSaga);
}
