export const CHECKOUT_STEP_PAYMENT = 'PAYMENT';
export const CHECKOUT_STEP_REVIEW = 'REVIEW';

export const GROUP_SOURCE_TYPE = {
  PERSONAL: 'PERSONAL',
  CORPORATE: 'CORPORATE',
};

export const SHARE_MODE = {
  LINK_ONLY: 'LINK_ONLY',
  DEFAULT_TEAM: 'DEFAULT_TEAM',
};
