import isEmpty from 'lodash/isEmpty';
import {
  put,
  takeLatest,
  call,
  getContext,
  select,
  delay,
} from 'redux-saga/effects';
import {
  fetchMenu,
  fetchMenuItemChoices,
  fetchMenuWithExternalId,
} from '@artemis/api/athena';
import {
  MENU_DEBUG_PARAM,
  MENU_DRAFT_ID_PARAM,
} from '@artemis/utils/query/constants';
import { authenticated } from '@artemis/store/auth/slice';
import {
  loadMenu,
  loadMenuSuccess,
  loadMenuError,
  openCartItem,
  openItem,
  loadMenuItemChoices,
  loadMenuItemChoicesSuccess,
  loadMenuItemChoicesError,
  setItemQuery,
  itemQueryResult,
} from './slice';
import { getScheduledDateTime } from '../cart/selectors';
import { getMerchantMenuId } from '../merchant/selectors';
import {
  getMenuChoices,
  getMenuId,
  getMenuItemChoicesMap,
  getMenuItems,
  getMenuGroupsWithItemsUnfiltered,
} from './selectors';

export function* loadMenuSaga({ payload = {} }) {
  try {
    const { debug, menuDraftId, externalId, includeChoicesForItemId } = payload;
    const apiClient = yield getContext('athenaApiClient');
    if (externalId) {
      const { data } = yield call(fetchMenuWithExternalId, apiClient, {
        externalId,
        ...(!debug && { excludeChoices: true, includeChoicesForItemId }),
        ...(debug && { debug: true, menuDraftId }),
      });
      if (includeChoicesForItemId) {
        const { choices, options, ...rest } = data;
        yield put(
          loadMenuItemChoicesSuccess({
            itemId: includeChoicesForItemId,
            data: { choices, options },
          }),
        );
        yield put(loadMenuSuccess(rest));
      } else {
        yield put(loadMenuSuccess(data));
      }
    } else {
      let { id } = payload;
      if (!id) {
        id = yield select(getMenuId);
      }
      if (!id) {
        id = yield select(getMerchantMenuId);
      }
      const dateTime = yield select(getScheduledDateTime);
      const { data } = yield call(fetchMenu, apiClient, {
        id,
        dateTime,
        ...(!debug && { excludeChoices: true }),
        ...(debug && { debug: true, menuDraftId }),
      });
      yield put(loadMenuSuccess(data));
    }
  } catch (err) {
    yield put(loadMenuError(err));
  }
}

export function* reloadMenuAfterAuth({ payload = {} }) {
  const query = payload.query || {};
  const { [MENU_DEBUG_PARAM]: debug, [MENU_DRAFT_ID_PARAM]: menuDraftId } =
    query;

  // Do not reload the menu if there is no menu ID present
  const menuId = yield select(getMenuId);
  if (!menuId) {
    return;
  }

  yield call(loadMenuSaga, {
    payload: {
      id: menuId,
      debug: debug !== undefined,
      menuDraftId,
    },
  });
}

export function* loadMenuItemChoicesSaga({ payload }) {
  const { itemId } = payload;

  // Do nothing if all choices for the menu have already been fetched
  // Menu choices will either have all choices or nothing
  const menuChoices = yield select(getMenuChoices);
  if (!isEmpty(menuChoices)) {
    return;
  }

  // Do nothing if the item has no choices to fetch
  const menuItems = yield select(getMenuItems);
  const item = menuItems?.[itemId];
  if (!item?.choiceIds?.length) {
    return;
  }

  // Do nothing if choices for the item have already been fetched
  const itemChoicesMap = yield select(getMenuItemChoicesMap);
  if (itemChoicesMap[itemId]) {
    return;
  }

  try {
    yield put(loadMenuItemChoices());
    const menuId = yield select(getMenuId);
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchMenuItemChoices, apiClient, {
      menuId,
      itemId,
    });
    yield put(loadMenuItemChoicesSuccess({ itemId, data }));
  } catch (err) {
    yield put(loadMenuItemChoicesError(err));
  }
}

export function* filterItems({ payload }) {
  yield delay(500);
  const { itemQuery } = payload;
  if (itemQuery) {
    const query = itemQuery.toLowerCase();
    const groups = yield select(getMenuGroupsWithItemsUnfiltered);
    const result = {};
    let count = 0;
    groups.forEach(group => {
      const isMatchingGroupName = group.title.toLowerCase().includes(query);
      group.items.forEach(item => {
        if (result[item.id]) {
          return;
        }
        if (
          isMatchingGroupName ||
          item.title.toLowerCase().includes(query) ||
          item.description?.toLowerCase().includes(query)
        ) {
          result[item.id] = true;
          count += 1;
        }
      });
    });
    yield put(itemQueryResult({ result, resultCount: count }));
  } else {
    yield put(itemQueryResult({ result: null, resultCount: null }));
  }
}

export default function* menuData() {
  yield takeLatest([loadMenu.type], loadMenuSaga);
  yield takeLatest([authenticated.type], reloadMenuAfterAuth); // reload menu after user is authenticated
  yield takeLatest([setItemQuery.type], filterItems);
  yield takeLatest([openCartItem.type], loadMenuItemChoicesSaga);
  yield takeLatest([openItem.type], loadMenuItemChoicesSaga);
}
