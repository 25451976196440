import React, { useEffect } from 'react';
import parser from 'accept-language-parser';
import cookie, { serialize } from 'cookie';
import BaseApp from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { END } from 'redux-saga';
import ErrorBoundary from '@artemis/components/ErrorBoundary';
import { WrappedIntlProvider } from '@artemis/integrations/contentful/utils';
import theme from '@artemis/theme';
import AuthenticationProvider from '@artemis/integrations/auth';
import { getOrRefreshAccessToken } from '@artemis/integrations/auth/utils';
import RemoteConfigProvider from '@artemis/integrations/remoteConfig';
import { initializeSentry } from '@artemis/integrations/sentry';
import { wrapper } from '@artemis/store';
import GlobalStyle from '@artemis/theme/global-styles';
import Cookies from '@artemis/utils/cookies';
import { loadUser } from '@artemis/store/user/slice';
import { setEmbedded } from '@artemis/store/embed/slice';
import { getIsEmbedded } from '@artemis/store/embed/selectors';
import { installGlobalCallbacks } from '@artemis/integrations/embed/webviewHooks';
import { KEYCLOAK_TOKEN_COOKIE } from '@artemis/integrations/auth/constants';
import SiftIntegration from '@artemis/integrations/sift/SiftIntegration';
import { parseContextFromHeaders } from '@artemis/utils/orderSource';
import { setAuthenticatedOnServer } from '@artemis/store/auth/slice';
import { getLogger } from '@artemis/utils/log';
import {
  EMBED_PARAM,
  LANGUAGE_PARAM,
  PROMO_OFFER_ID_PARAM,
} from '@artemis/utils/query/constants';

import '@reach/combobox/styles.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'swiper/css';
import { OFFER_ID_COOKIE } from '@artemis/utils/cookies/constants';
import { addCookiesToResponse } from '@artemis/utils/cookie';

const Toasts = dynamic(() => import('@artemis/containers/Toasts'), {
  ssr: false,
});

const validLangs = {
  zh: true,
  en: true,
};

initializeSentry();

function parseCookies(req) {
  return cookie.parse(req?.headers?.cookie || '');
}

export function reportWebVitals(metric) {
  if (process.env.RT_SHOW_WEBVITALS) {
    // eslint-disable-next-line no-console
    console.log(metric);
  }
}

const App = ({ Component, cookies, ...rest }) => {
  const {
    store,
    props: { pageProps },
  } = wrapper.useWrappedStore(rest);

  useEffect(() => {
    const isEmbedded = getIsEmbedded(store.getState());
    if (isEmbedded) {
      installGlobalCallbacks();
    }
  }, []);

  return (
    <ErrorBoundary>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <RemoteConfigProvider>
        <Provider store={store}>
          <SiftIntegration />
          <AuthenticationProvider cookies={cookies}>
            <WrappedIntlProvider>
              <ThemeProvider theme={theme}>
                <Component {...pageProps} />
                <Toasts />
                <GlobalStyle />
              </ThemeProvider>
            </WrappedIntlProvider>
          </AuthenticationProvider>
        </Provider>
      </RemoteConfigProvider>
    </ErrorBoundary>
  );
};

App.getInitialProps = wrapper.getInitialAppProps(
  store =>
    async ({ Component, ctx, ...rest }) => {
      const cookies = parseCookies(ctx?.req);
      let locale = !ctx.req ? Cookies.get('rt-lang') : 'en';
      let contentfulLocale = 'en';

      if (ctx.req) {
        const { req, res, query } = ctx;
        req.logger = getLogger({ req });

        // Set offer ID cookie based on `offer` query parameter
        // This cookie is used by Athena to show certain promos, incentives, discounts, or campaigns
        const offerId = query[PROMO_OFFER_ID_PARAM];
        if (offerId) {
          req.offerId = offerId;
          addCookiesToResponse({
            res,
            cookies: [
              serialize(OFFER_ID_COOKIE, offerId, {
                path: '/',
                domain: process.env.RT_SHARED_COOKIE_DOMAIN,
                sameSite: 'Lax',
                secure: true,
              }),
            ],
          });
        }

        const organizationConfig = await parseContextFromHeaders(ctx);

        // Set accessToken on request
        const accessToken = await getOrRefreshAccessToken({
          req,
          res,
          query,
          organizationConfig,
        });
        if (accessToken) {
          req.accessToken = accessToken;
          cookies[KEYCLOAK_TOKEN_COOKIE] = accessToken;
          ctx.store.dispatch(setAuthenticatedOnServer(true));
        }

        if (validLangs[ctx.query[LANGUAGE_PARAM]]) {
          locale = ctx.query[LANGUAGE_PARAM];
        } else {
          const parsed = parser.parse(ctx.req.headers['accept-language']);
          const [lang] = parsed;
          locale =
            [lang?.code, lang?.region].filter(x => x).join('-') || locale;
          contentfulLocale =
            parsed.find(({ code }) => validLangs[code])?.code ||
            contentfulLocale;
        }

        /* eslint-disable no-param-reassign */
        ctx.req.rtLang = locale;
        ctx.contentfulLocale = contentfulLocale;
        ctx.isServer = true;
        /* eslint-enable no-param-reassign */

        if (accessToken) {
          ctx.store.dispatch(loadUser());
        }
      }

      const pageProps = {
        ...(
          await BaseApp.getInitialProps({
            Component,
            ctx,
            ...rest,
          })
        ).pageProps,
      };

      if (EMBED_PARAM in ctx.query) {
        store.dispatch(setEmbedded(ctx.query[EMBED_PARAM]));
      }

      if (ctx.req) {
        store.dispatch(END);
        await store.sagaTask.toPromise();
      }

      return {
        pageProps,
        locale,
        cookies,
      };
    },
);

App.propTypes = {
  Component: PropTypes.func.isRequired,
  cookies: PropTypes.any,
};

export default App;
