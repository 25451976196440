import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
};

const ipDataSlice = createSlice({
  name: 'ipData',
  initialState,
  reducers: {
    loadIpData: state => {
      state.isLoading = true;
    },
    loadIpDataSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadIpDataError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.ipData);
  },
});

export const { loadIpData, loadIpDataSuccess, loadIpDataError } =
  ipDataSlice.actions;

export default ipDataSlice.reducer;
