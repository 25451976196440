import Cookies from '@artemis/utils/cookies';

export const getAcceptLanguageHeader = ({ req, useMenuLanguage } = {}) => {
  const lang =
    req?.rtLang ||
    (useMenuLanguage
      ? [Cookies.get('rt-lang-menu'), Cookies.get('rt-lang')].join(',')
      : Cookies.get('rt-lang')) ||
    'en';
  return `${lang};q=0.9`;
};
