import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
  }

  html,
  body,
  #__next {
    width: 100%;
    margin: 0;
  }

  #__next {
    min-height: 100%;
    display: flex;
    flex-flow: column;
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  body {
    padding: 0;
    margin: 0;
  }
  body, input, textarea, select, button {
    font-family: ${props => props.theme.fontFamily};
  }
  p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  h1 {
    ${props => props.theme.typography.h1};
  }
  h2 {
    ${props => props.theme.typography.h2};
  }
  h3 {
    ${props => props.theme.typography.h3};
  }
  h4 {
    ${props => props.theme.typography.h4};
  }
  h5 {
    ${props => props.theme.typography.h5};
  }
  h6 {
    ${props => props.theme.typography.h6};
  }
  p, span, div, input, button, label {
    ${props => props.theme.typography.body};
  }
  input:is([type="button"], [type="submit"], [type="reset"]), input[type="file"]::file-selector-button, button{
    color: inherit;
  }

  /* Google Autocomplete */
  /*
    <div class="pac-container">
      <div class="pac-item">
        <span class="pac-icon pac-icon-marker"></span>
        <span class="pac-item-query">
          <span class="pac-matched">82</span>
          <span class="pac-matched">Peter</span>
          Street
        </span>
        <span>Toronto, ON, Canada</span>
      </div>
    </div>
  */
  .pac-container {
    border-radius: 6px;

    .pac-item {
      padding: 8px 10px;

      > span {
        font-size: ${props => props.theme.typography.caption.fontSize};
      }
      .pac-item-query, .pac-matched {
        font-size: ${props => props.theme.typography.bodySmall.fontSize};
      }
    }
  }

  /* Google reCAPTCHA */
  .grecaptcha-badge {
    visibility: hidden;
  }

  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  .iti.iti--container {
    z-index: 1500;
  }
`;

export default GlobalStyle;
