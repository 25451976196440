import { formatMoney } from 'accounting-js';
import Big from 'big.js';

export const MICRO_UNIT = 1000000;
export const FLOAT_MICRO_UNIT = 100 * MICRO_UNIT;
export const POINTS_PER_DOLLAR = 1000;

const CURRENCY_CODE_SYMBOL = {
  CAD: '$',
  USD: '$',
  AUD: '$',
  GBP: '£',
  EUR: '€',
  HKD: '$',
  NZD: '$',
  DOLLAR: '$',
};

export const microToDollars = microValue => {
  if (microValue == null || Number.isNaN(microValue)) return undefined;
  return Big(microValue).div(FLOAT_MICRO_UNIT).toNumber();
};

export const microToCents = microValue => {
  if (microValue == null || Number.isNaN(microValue)) return undefined;
  return Big(microValue).div(MICRO_UNIT).toNumber();
};

export const dollarsToMicro = dollarValue => {
  if (dollarValue == null || Number.isNaN(dollarValue)) return undefined;
  return Big(dollarValue).times(FLOAT_MICRO_UNIT).toNumber();
};

export const microToPoints = microValue => {
  if (microValue == null || Number.isNaN(microValue)) return undefined;
  return Big(microToDollars(microValue)).times(POINTS_PER_DOLLAR).toNumber();
};

export const guessCurrencyCodeFromLocale = locale => {
  switch (locale) {
    case 'en-GB':
      return CURRENCY_CODE_SYMBOL.GBP;
    case 'de-DE':
    case 'nl-NL':
      return CURRENCY_CODE_SYMBOL.EUR;
    default:
      return CURRENCY_CODE_SYMBOL.DOLLAR;
  }
};

export const getSymbolForCurrency = currencyCode =>
  CURRENCY_CODE_SYMBOL[currencyCode] || CURRENCY_CODE_SYMBOL.DOLLAR;

const getThousandSeparator = locale => {
  switch (locale) {
    case 'de-DE':
    case 'fr-CA':
      return ' ';
    case 'nl-NL':
      return '.';
    default:
      return ',';
  }
};

const currencyFormatByLocale = locale => {
  const thousand = getThousandSeparator(locale);
  switch (locale) {
    case 'de-DE':
    case 'fr-CA':
      return { format: '%v %s', decimal: ',', thousand };
    case 'nl-NL':
      return { format: '%s %v', decimal: ',', thousand };
    default:
      return '';
  }
};

export function formatCurrency({
  value = 0,
  precision = 2,
  isMicro = true,
  currencyCode = 'DOLLAR',
  userLocale,
}) {
  const isNegative = value < 0;
  const visibleValue = isNegative ? -1 * value : value;
  let formattedValue;
  if (!isMicro) {
    formattedValue = formatMoney(visibleValue, {
      symbol: CURRENCY_CODE_SYMBOL[currencyCode],
      precision,
    });
  } else {
    formattedValue = formatMoney(microToDollars(visibleValue), {
      symbol: CURRENCY_CODE_SYMBOL[currencyCode],
      precision,
      ...currencyFormatByLocale(userLocale),
    });
  }
  return isNegative ? `-${formattedValue}` : formattedValue;
}
// this map is based on https://github.com/RitualCo/midichlorian/blob/develop/legacy/common-proto/src/main/protobuf/locality.proto
export const CURRENCIES = {
  AU: 'AUD',
  CA: 'CAD',
  DE: 'EUR',
  GB: 'GBP',
  HK: 'HKD',
  NL: 'EUR',
  NZ: 'NZD',
  US: 'USD',
};

export const getCurrencyCodeFromCountryCode = country =>
  CURRENCIES[country] || null;
