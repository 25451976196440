/* eslint-disable func-names */
/**
 *
 * SiftIntegration
 *
 */
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRemoteConfig } from '@artemis/integrations/remoteConfig';
import { getIsAnalyticsIdentityLoading } from '@artemis/store/user/selectors';
import { getAnalyticsIdentity } from '@artemis/store/user/slice';
import { getCookieIds } from '@artemis/utils/analytics';

/**
 * SiftJavascriptSnippet tracks user interactions with your website and
 * collects device information for fraud detection
 *
 * https://developers.sift.com/docs/curl/javascript-api/overview
 */
const SiftJavascriptSnippet = ({
  siftUserId,
  siftSessionId,
  siftBeaconKey,
}) => {
  if (siftSessionId && siftBeaconKey) {
    // eslint-disable-next-line no-underscore-dangle, prettier/prettier, no-multi-assign
    const _sift = (window._sift = window._sift || []);
    _sift.push(['_setAccount', siftBeaconKey]);
    _sift.push(['_setUserId', siftUserId]);
    _sift.push(['_setSessionId', siftSessionId]);
    _sift.push(['_trackPageview']);
    (function () {
      function ls() {
        const e = document.createElement('script');
        e.src = 'https://cdn.sift.com/s.js';
        document.body.appendChild(e);
      }
      if (window.attachEvent) {
        window.attachEvent('onload', ls);
      } else {
        window.addEventListener('load', ls, false);
      }
    })();
  }
  return true;
};

SiftJavascriptSnippet.propTypes = {
  siftUserId: PropTypes.string,
  siftSessionId: PropTypes.string,
  siftBeaconKey: PropTypes.string,
};

SiftJavascriptSnippet.defaultProps = {
  siftUserId: '',
};

/**
 * SiftIntegration: wrapper for SiftJavascriptSnippet that fetches appropriate configs
 * Snippet tracks user interactions with your website and
 * collects device information for fraud detection
 *
 * https://developers.sift.com/docs/curl/javascript-api/overview
 */
const SiftIntegration = () => {
  const dispatch = useDispatch();
  dispatch(getAnalyticsIdentity());
  const { configLoaded, getBoolean } = useRemoteConfig();
  const isSiftEnabled = getBoolean('rw_enable_sift');
  const { euid, sessionId } = getCookieIds();
  const siftUserId = euid;
  const siftBeaconKey = process.env.RT_SIFT_BEACON_KEY;
  const siftSessionId = sessionId;
  // Using selector to trigger re-render after analytics identity is fetched
  useSelector(getIsAnalyticsIdentityLoading);

  if (configLoaded && isSiftEnabled) {
    return (
      <SiftJavascriptSnippet
        siftSessionId={siftSessionId}
        siftUserId={siftUserId}
        siftBeaconKey={siftBeaconKey}
      />
    );
  }
  return null;
};

export default SiftIntegration;
