import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  loadCartError,
  placeOrderError,
  updateCartError,
} from '@artemis/store/cart/slice';
import {
  loadMerchantsError,
  loadNearbyMerchantsError,
} from '@artemis/store/catalog/slice';
import { updateFulfillmentError } from '@artemis/store/groupOrder/slice';
import { loadMerchantError } from '@artemis/store/merchant/slice';
import { loadResourceSetError } from '@artemis/store/resourceSet/slice';
import {
  addTeamLocationError,
  createTeamError,
  joinTeamError,
  leaveTeamError,
  leaveTeamSuccess,
  loadVerificationStatusError,
  searchTeamsError,
} from '@artemis/store/teams/slice';
import { genKey, getMessageForErrorCode, getModalType } from './utils';

export const initialState = {
  active: [],
  activeModal: null,
};

const addErrorReducer = (state, action) => {
  const { error } = action.payload;
  const messageForCode = getMessageForErrorCode(error);
  if (messageForCode) {
    state.active.push({
      error: { message: messageForCode },
      key: genKey(),
    });
  } else if (error?.response?.data?.error) {
    const modalType = getModalType(error.response.data.error);
    if (modalType) {
      state.activeModal = {
        type: modalType,
        errorTitle: error.response.data.error?.localizedErrorMessage,
        errorBody: error.response.data.error?.errorBody,
      };
    } else if (
      error.response.status === 401 &&
      error.response.data.error === 'invalid_token'
    ) {
      state.active.push({
        error: { message: 'errors.sessionExpire' },
        key: genKey(),
      });
    } else {
      state.active.push({
        error: error.response.data.error,
        // generate a random identifier for each toast
        key: genKey(),
      });
    }
  } else if (
    error?.response?.data?.errors?.[0]?.parameter === 'nextPageToken' &&
    error?.response?.data?.errors?.[0]?.code === 'INVALID_PARAMETER'
  ) {
    state.active.push({
      error: { message: 'errors.searchTokenExpire' },
      key: genKey(),
    });
  } else if (error?.response?.status === 404) {
    state.active.push({
      error: { message: 'errors.generic.404' },
      // generate a random identifier for each toast
      key: genKey(),
    });
  } else {
    state.active.push({
      error: { message: 'errors.tryAgain' },
      // generate a random identifier for each toast
      key: genKey(),
    });
  }
};

const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast: (state, action) => {
      state.active.push({
        ...action.payload.toast,
        // generate a random identifier for each toast
        key: genKey(),
      });
    },
    removeToast: (state, action) => {
      state.active = state.active.filter(
        toast => toast.key !== action.payload.key,
      );
    },
    addError: addErrorReducer,
    removeModal: state => {
      state.activeModal = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(addTeamLocationError, (state, action) => {
        const { error } = action.payload;
        if (error?.response?.status === 409) {
          state.active.push({
            error: {
              message: 'teams.addLocationModal.errors.alreadyExists',
            },
            key: genKey(),
          });
        } else {
          addErrorReducer(state, action);
        }
      })
      .addCase(leaveTeamSuccess, state => {
        state.active.push({
          message: 'teams.leave.successMessage',
          key: genKey(),
        });
      })
      .addMatcher(
        isAnyOf(
          createTeamError,
          joinTeamError,
          leaveTeamError,
          loadCartError,
          loadMerchantError,
          loadMerchantsError,
          loadNearbyMerchantsError,
          loadResourceSetError,
          loadVerificationStatusError,
          placeOrderError,
          searchTeamsError,
          updateCartError,
          updateFulfillmentError,
        ),
        addErrorReducer,
      );
  },
});

export const { addToast, removeToast, addError, removeModal } =
  toastsSlice.actions;

export default toastsSlice.reducer;
