const merchant = {
  id: 'd0ab82a4e3cf4699b4e41a094f5f952b',
  name: 'Panago Pizza (Gerrard/Bay)',
  nameWithoutLocation: 'Panago Pizza',
  isAvailableNow: true,
  address: {
    streetAddress: '44 Gerrard Street West',
    city: 'Toronto',
  },
  location: {
    latitude: 43.658897,
    longitude: -79.383798,
  },
  menuPath: '/panago-pizza-gerrard-bay-toronto/aa99',
  timeZone: 'America/Toronto',
  currencyCode: 'CAD',
};

export const CartItem = {
  entityType: 'CART',
  entityId: 'd0ab82a4e3cf4699b4e41a094f5f952b',
  itemCount: 5,
  subtotalMicro: 1234000000,
  fulfillmentType: 'IN_STORE',
  merchant,
};

export const ScheduledOrderItem = {
  entityType: 'ORDER',
  entityId: '676a620b34134d73a9243555d1e8d6b2',
  scheduledTime: '2022-09-02T20:06:58.663Z',
  orderStatus: 'SCHEDULED',
  fulfillmentType: 'IN_STORE',
  merchant,
};

export const PlacedOrderItem = {
  entityType: 'ORDER',
  entityId: '676a620b34134d73a9243555d1e8d6b2',
  orderStatus: 'PLACED',
  fulfillmentType: 'IN_STORE',
  merchant,
};

export const ReadyOrderItem = {
  entityType: 'ORDER',
  entityId: '676a620b34134d73a9243555d1e8d6b2',
  readyTime: '2022-09-02T19:36:58.664Z',
  orderStatus: 'RECEIVED',
  fulfillmentType: 'IN_STORE',
  merchant,
};

export const CancelledOrderItem = {
  entityType: 'ORDER',
  entityId: '676a620b34134d73a9243555d1e8d6b2',
  orderStatus: 'CANCELLED',
  fulfillmentType: 'IN_STORE',
  merchant,
};

export const FulfillmentOrderItem = {
  entityType: 'FULFILLMENT',
  entityId: '31a271a34da84d3b8438242c942487fb',
  scheduledTime: '2022-09-02T20:06:58.664Z',
  fulfillmentType: 'IN_STORE',
  fulfillmentDetails: {
    fulfillmentState: 'OPEN',
    guestStatus: 'BROWSING',
    isCurrentUserAdmin: false,
    guestOrderId: '676a620b34134d73a9243555d1e8d6b2',
  },
  merchant,
};

export const ActiveOrders = {
  data: [
    CartItem,
    ScheduledOrderItem,
    PlacedOrderItem,
    ReadyOrderItem,
    CancelledOrderItem,
    FulfillmentOrderItem,
  ],
};
