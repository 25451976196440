import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import Cookies from '@artemis/utils/cookies';
import {
  getAccessToken,
  refreshAccessToken,
} from '@artemis/integrations/embed/webviewHooks';
import { setupStubbedWebviewHooks } from '@artemis/integrations/embed/webviewHooks/stubs';
import Loading from '@artemis/components/Loading';
import { getKeycloakProfile } from '@artemis/api/keycloak';
import { getAuthenticatedOnServer } from '@artemis/store/auth/selectors';
import { authenticated } from '@artemis/store/auth/slice';
import {
  logIntegrationInitialized,
  logIntegrationInitialLoad,
} from '@artemis/integrations/embed/analytics';
import { EMBED_INTEGRATION_TYPE } from '@artemis/integrations/embed/constants';
import { logErrorInSentry } from '@artemis/integrations/sentry';
import { EMBED_LOCAL_TEST, MID_PARAM } from '@artemis/utils/query/constants';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import AuthenticationContext from './AuthenticationContext';
import { formatUserProfile } from './utils';
import { KEYCLOAK_TOKEN_COOKIE } from './constants';

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;

const getAuthData = ({ userProfile }) => ({
  authenticated: true,
  login: () => {},
  logout: () => {},
  register: () => {},
  showLogin: false,
  loginTitle: '',
  shouldShowGuestCheckout: false,
  returnUri: '',
  isTokenExpired: false,
  userProfile,
  initialized: true,
  message: '',
  setAuthModal: () => {},
});

const callWithThrottle = throttle(func => func(), 15_000);

const EmbeddedAuthenticationContextWrapper = ({ keycloakConfig, children }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const isEmbedLocalTest = useQueryCheck(EMBED_LOCAL_TEST);

  const query = router?.query || {};
  const useStubs =
    isEmbedLocalTest && process.env.RT_ENVIRONMENT === 'development';

  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [tokenValid, setTokenValid] = useState(false);
  const [userProfile, setUserProfile] = useState({});

  const authData = getAuthData({ userProfile });

  const authenticatedOnServer = useSelector(getAuthenticatedOnServer);

  const getTokenFromCookie = () => Cookies.get(KEYCLOAK_TOKEN_COOKIE);

  const setToken = token => {
    if (token) {
      Cookies.set(KEYCLOAK_TOKEN_COOKIE, token);
      setTokenLoaded(true);

      if (token.length > 4000) {
        logErrorInSentry(
          new Error('Token from Ritual SDK may be too large for cookie'),
        );
      }
    }
  };

  const clearToken = () => {
    Cookies.remove(KEYCLOAK_TOKEN_COOKIE);
    setTokenValid(false);
    setTokenLoaded(false);
  };

  const loadProfile = async () => {
    const keycloakProfile = await getKeycloakProfile({
      keycloakConfig,
      token: getTokenFromCookie(),
    });
    setUserProfile(formatUserProfile(keycloakProfile));
  };

  const clearAndRefreshToken = () => {
    clearToken();
    refreshAccessToken().then(setToken);
  };

  // Add stubbed implementation of JavaScript bridge for local testing, when `?embedLocalTest=1` is present
  useEffect(() => {
    if (useStubs) {
      setupStubbedWebviewHooks();
    }
  }, [useStubs]);

  // Get the access token from the JavaScript hooks if there is no cookie yet
  useEffect(() => {
    logIntegrationInitialLoad({
      integrationType: EMBED_INTEGRATION_TYPE.DEFAULT,
    });

    if (Cookies.get(KEYCLOAK_TOKEN_COOKIE)) {
      setTokenLoaded(true);
      return;
    }
    getAccessToken().then(setToken);
  }, []);

  // Reload certain content if server-side rendering was unauthenticated but the page is now authenticated
  // e.g. guest cart conversion, load stamp card data, reload menu with item perks
  //
  // This could happen after a user logs in or navigates between two different Ritual domains
  useEffect(() => {
    if (!authenticatedOnServer && tokenValid) {
      dispatch(authenticated(query[MID_PARAM], query));
    }
  }, [authenticatedOnServer, tokenValid]);

  // Load user profile after token is loaded
  useEffect(() => {
    if (tokenLoaded) {
      // If profile can't be loaded token may be invalid, and should be refreshed
      loadProfile()
        .then(() => {
          setTokenValid(true);
          logIntegrationInitialized({
            integrationType: EMBED_INTEGRATION_TYPE.DEFAULT,
          });
        })
        .catch(() => {
          logErrorInSentry(
            new Error(
              'JWT token from Ritual SDK is not valid, token may have expired or keycloak client is misconfigured',
            ),
          );
          callWithThrottle(clearAndRefreshToken);
        });
    }
  }, [tokenLoaded]);

  // Refresh token every hour
  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAccessToken().then(setToken);
    }, ONE_HOUR);

    return () => window.clearInterval(intervalId);
  }, []);

  // Show a loading page until token is obtained via the JavaScript hooks
  if (!tokenValid) {
    return <Loading isLoading data-testid="embedded-auth-loading" />;
  }

  return (
    <AuthenticationContext.Provider value={authData}>
      {children}
    </AuthenticationContext.Provider>
  );
};

EmbeddedAuthenticationContextWrapper.propTypes = {
  children: PropTypes.element,
  keycloakConfig: PropTypes.shape({
    'auth-server-url': PropTypes.string,
    realm: PropTypes.string,
  }),
};

export default EmbeddedAuthenticationContextWrapper;
