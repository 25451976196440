import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import {
  updateCartSuccess,
  updateCartError,
  perkError,
} from '@artemis/store/cart/slice';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
  activeItem: null,
  itemChoices: {
    isLoading: false,
    data: {},
    error: null,
  },
  itemSearch: {
    query: '',
    displayQuery: '',
    result: null,
    resultCount: null,
  },
  expressServiceModalOpen: false,
  serviceFeeModalOpen: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    loadMenu: state => {
      state.isLoading = true;
    },
    loadMenuSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadMenuError: {
      prepare: error => ({ payload: { error } }),
      reducer: (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
    setItemQuery: {
      reducer: (state, action) => {
        state.isLoading = true;
        state.itemSearch = {
          query: action.payload.itemQuery,
          displayQuery: state.itemSearch.displayQuery,
          result: state.itemSearch.result,
          resultCount: state.itemSearch.resultCount,
        };
      },
    },
    clearItemQuery: {
      reducer: state => {
        state.isLoading = false;
        state.itemSearch = {
          query: '',
          displayQuery: '',
          result: null,
          resultCount: null,
        };
      },
    },
    itemQueryResult: {
      reducer: (state, action) => {
        state.isLoading = false;
        state.itemSearch = {
          query: state.itemSearch.query,
          displayQuery: state.itemSearch.query,
          result: action.payload.result,
          resultCount: action.payload.resultCount,
        };
      },
    },
    openItem: {
      prepare: (itemId, groupTitle) => ({
        payload: { itemId, groupTitle },
      }),
      reducer: (state, action) => {
        state.activeItem = {
          id: action.payload.itemId,
          options: [],
          note: '',
          groupTitle: action.payload.groupTitle,
        };
      },
    },
    openCartItem: (state, action) => {
      state.activeItem = {
        cartItemId: action.payload.cartItemId,
        id: action.payload.itemId,
        options: action.payload.options,
        note: action.payload.note,
        quantity: action.payload.quantity,
        perk: action.payload.perk,
      };
    },
    closeItem: state => {
      state.activeItem = null;
    },
    loadMenuItemChoices: state => {
      state.itemChoices.isLoading = true;
    },
    loadMenuItemChoicesSuccess: (state, action) => {
      state.itemChoices.isLoading = false;
      state.itemChoices.data[action.payload.itemId] = action.payload.data;
    },
    loadMenuItemChoicesError: (state, action) => {
      state.itemChoices.isLoading = false;
      state.itemChoices.error = action.payload;
    },
    setExpressServiceModalOpen: (state, action) => {
      state.expressServiceModalOpen = action.payload.isOpen;
    },
    setServiceFeeModalOpen: (state, action) => {
      state.serviceFeeModalOpen = action.payload.isOpen;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(HYDRATE, (_, action) => action.payload.menu)
      .addMatcher(
        isAnyOf(updateCartSuccess, updateCartError, perkError),
        state => {
          state.activeItem = null;
        },
      );
  },
});

export const {
  loadMenu,
  loadMenuSuccess,
  loadMenuError,
  setItemQuery,
  clearItemQuery,
  itemQueryResult,
  openItem,
  openCartItem,
  closeItem,
  loadMenuItemChoices,
  loadMenuItemChoicesSuccess,
  loadMenuItemChoicesError,
  setExpressServiceModalOpen,
  setServiceFeeModalOpen,
} = menuSlice.actions;

export default menuSlice.reducer;
