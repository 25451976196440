import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isLoading: false,
  error: null,
};

const merchantPreviewSlice = createSlice({
  name: 'merchantPreview',
  initialState,
  reducers: {
    loadMerchantPreview: {
      prepare: (merchantPreviewId, countryCode) => ({
        payload: { merchantPreviewId, countryCode },
      }),
      reducer: state => {
        state.isLoading = true;
      },
    },
    loadMerchantPreviewSuccess: state => {
      state.isLoading = false;
    },
    loadMerchantPreviewError: {
      prepare: error => ({ payload: { error } }),
      reducer: (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
      },
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.merchantPreview);
  },
});

export const {
  loadMerchantPreview,
  loadMerchantPreviewSuccess,
  loadMerchantPreviewError,
} = merchantPreviewSlice.actions;

export default merchantPreviewSlice.reducer;
