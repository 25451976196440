import React from 'react';
import { useSelector } from 'react-redux';
import Proptypes from 'prop-types';
import { SSRKeycloakProvider, getKeycloakInstance } from '@react-keycloak/ssr';
import {
  getIsEmbedded,
  getIsEmbeddedHqO,
} from '@artemis/store/embed/selectors';
import useConfigureSentryTags from '@artemis/integrations/sentry/useConfigureSentryTags';
import AuthenticationContextWrapper from './AuthenticationContextWrapper';
import EmbeddedAuthenticationContextWrapper from './EmbeddedAuthenticationContextWrapper';
import EmbeddedHqOWrapper from './EmbeddedHqOWrapper';
import { SSRCookies } from './CustomTokenPersistor';
import useKeycloakConfig from './useKeycloakConfig';

// getAuthInstance should only be used when you do not have access to React.Context
// Currently that means it should only be used inside of sagas.
export { getKeycloakInstance as getAuthInstance };

const Auth = ({ children, cookies }) => {
  useConfigureSentryTags(); // this is called here rather than _app.jsx because it needs access to redux

  const isEmbeddedHqO = useSelector(getIsEmbeddedHqO);
  const isEmbedded = useSelector(getIsEmbedded);
  const keycloakConfig = useKeycloakConfig();

  let origin = process.env.RT_BROWSER_ORIGIN;
  if (typeof window !== 'undefined') {
    origin = window.location.origin;
  }

  if (isEmbeddedHqO) {
    return (
      <SSRKeycloakProvider
        initConfig={{
          checkLoginIframe: false,
          onLoad: 'check-sso',
          silentCheckSsoRedirectUri: `${origin}/order/silent-check-sso.html`,
        }}
        keycloakConfig={keycloakConfig}
        persistor={SSRCookies(cookies)}
      >
        <EmbeddedHqOWrapper>{children}</EmbeddedHqOWrapper>
      </SSRKeycloakProvider>
    );
  }

  if (isEmbedded) {
    return (
      <EmbeddedAuthenticationContextWrapper keycloakConfig={keycloakConfig}>
        {children}
      </EmbeddedAuthenticationContextWrapper>
    );
  }

  return (
    <SSRKeycloakProvider
      initConfig={{
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${origin}/order/silent-check-sso.html`,
      }}
      keycloakConfig={keycloakConfig}
      // this is a replacement for ServerPersistors.SSRCookies(cookies) that does not encode the cookies
      persistor={SSRCookies(cookies)}
    >
      <AuthenticationContextWrapper>{children}</AuthenticationContextWrapper>
    </SSRKeycloakProvider>
  );
};

Auth.propTypes = {
  children: Proptypes.element,
  cookies: Proptypes.object,
};

export default Auth;
