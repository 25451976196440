import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { getWorkspace } from '@artemis/api/contentGateway';
import { loadNearbyMerchantsSaga } from '@artemis/store/catalog/sagas';
import {
  loadWorkspace,
  loadWorkspaceSuccess,
  loadWorkspaceError,
} from './slice';

export function* loadWorkspaceSaga(action) {
  const {
    payload: { workspaceId, shouldLoadNearbyMerchants, filters },
  } = action;
  try {
    const apiClient = yield getContext('contentGatewayApiClient');
    const { data } = yield call(getWorkspace, apiClient, { workspaceId });
    yield put(loadWorkspaceSuccess(data));
    if (shouldLoadNearbyMerchants) {
      yield call(loadNearbyMerchantsSaga, {
        payload: { ...data.location, ...filters },
      });
    }
  } catch (err) {
    yield put(loadWorkspaceError(err));
  }
}

export default function* workspaceData() {
  yield takeLatest(loadWorkspace.type, loadWorkspaceSaga);
}
