export const ORDER_STATES = {
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  IN_TRANSIT: 'IN_TRANSIT',
  PLACED: 'PLACED',
  PREPARED: 'PREPARED',
  RECEIVED: 'RECEIVED',
  REJECTED: 'REJECTED',
  UNKNOWN_ORDER_STATUS: 'UNKNOWN_ORDER_STATUS',
  DELIVERY_CREATED: 'DELIVERY_CREATED',
  COURIER_CONFIRMED: 'COURIER_CONFIRMED',
  COURIER_ENROUTE_PICKUP: 'COURIER_ENROUTE_PICKUP',
  COURIER_CONFIRMED_STORE_ARRIVAL: 'COURIER_CONFIRMED_STORE_ARRIVAL',
  COURIER_PICKED_UP: 'COURIER_PICKED_UP',
  COURIER_ENROUTE_DROPOFF: 'COURIER_ENROUTE_DROPOFF',
  COURIER_CONFIRMED_CONSUMER_ARRIVAL: 'COURIER_CONFIRMED_CONSUMER_ARRIVAL',
  COURIER_DROPPED_OFF: 'COURIER_DROPPED_OFF',
  DELIVERY_CANCELED: 'DELIVERY_CANCELED',
  SCHEDULED: 'SCHEDULED',
};

// Maintain sequential order of statuses
export const DELIVERY_STATES = {
  COURIER_CONFIRMED: 'COURIER_CONFIRMED',
  COURIER_ENROUTE_PICKUP: 'COURIER_ENROUTE_PICKUP',
  COURIER_CONFIRMED_STORE_ARRIVAL: 'COURIER_CONFIRMED_STORE_ARRIVAL',
  COURIER_PICKED_UP: 'COURIER_PICKED_UP',
  COURIER_ENROUTE_DROPOFF: 'COURIER_ENROUTE_DROPOFF',
  COURIER_CONFIRMED_CONSUMER_ARRIVAL: 'COURIER_CONFIRMED_CONSUMER_ARRIVAL',
  COURIER_DROPPED_OFF: 'COURIER_DROPPED_OFF',
};
