import { useState, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { useInterval } from 'react-use';
import { useKeycloak } from '@react-keycloak/ssr';
import { getCookieIds } from '@artemis/utils/analytics';
import Cookies from '@artemis/utils/cookies';
import { v4 } from 'public-ip';
import { useSelector, useDispatch } from 'react-redux';
import { addToast } from '@artemis/store/toasts/slice';
import { getMerchantDefaultPhoneCountryCode } from '@artemis/store/merchant/selectors';
import {
  getAdditionalOTPLoginParams,
  getIsEmbeddedHqO,
  getShouldUseOTPLoginFlow,
} from '@artemis/store/embed/selectors';
import { ORDER_SOURCE } from '@artemis/utils/constants';
import { isInIframe } from '@artemis/utils/iframe';
import {
  HQO_LINKOUT_PARAM,
  MID_PARAM,
  PROMO_PARAM,
} from '@artemis/utils/query/constants';
import { removeSessionItem, SESSION_KEY } from '@artemis/utils/sessionStorage';
import { useRouter } from 'next/router';
import { getAuthenticatedOnServer } from '@artemis/store/auth/selectors';
import { getOrderSource } from '@artemis/store/order/selectors';
import useQueryCheck from '@artemis/utils/query/useQueryCheck';
import { authenticated } from '../../store/auth/slice';
import { formatUserProfile } from './utils';
import {
  KEYCLOAK_REFRESH_TOKEN_COOKIE,
  KEYCLOAK_ANONYMOUS_TOKEN_COOKIE,
  QUERY_AUTH_REDIRECTED,
} from './constants';

const setAdditionalParamsCookie = async ({ countryCode }) => {
  let ipAddress = '';
  try {
    ipAddress = await v4();
    // eslint-disable-next-line no-empty
  } catch {}
  const additionalParams = {
    device_info: {
      device_id: getCookieIds().deviceId,
      web_ritual_app_version: '110001',
      web_user_agent: navigator.userAgent,
      ip_address: ipAddress,
    },
    country_code: countryCode,
  };

  const THIRTY_DAYS_IN_SECONDS = 60 * 60 * 24 * 30;
  document.cookie = `ADDITIONAL_PARAMS=${btoa(
    JSON.stringify(additionalParams),
  )};max-age=${THIRTY_DAYS_IN_SECONDS};path=/;domain=.ritual.co`;
};

/**
 * Dynamically creates a form and submits it using POST method.
 * Source: https://stackoverflow.com/a/133997
 */
const post = (path, params) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = path;

  Object.keys(params).forEach(key => {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key];
      form.appendChild(hiddenField);
    }
  });

  document.body.appendChild(form);
  form.submit();
};

const useAuth = () => {
  const dispatch = useDispatch();
  const { keycloak, initialized } = useKeycloak();
  const router = useRouter();
  const { [MID_PARAM]: mid, [PROMO_PARAM]: promo } = router.query;
  const isHqoLinkout = useQueryCheck(HQO_LINKOUT_PARAM);

  const [, setProfileLoaded] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [returnUri, setReturnUri] = useState(false);
  const [isCloseable, setIsCloseable] = useState(true);
  const [loginTitle, setLoginTitle] = useState(null);
  const [message, setMessage] = useState(null);
  const [loginImage, setLoginImage] = useState(null);

  const [shouldShowGuestCheckout, setShouldShowGuestCheckout] = useState(false);

  const countryCode = useSelector(getMerchantDefaultPhoneCountryCode);
  const shouldUseOTPLoginFlowHqO = useSelector(getShouldUseOTPLoginFlow);
  const additionalOTPLoginParams = useSelector(getAdditionalOTPLoginParams);
  const isEmbeddedHqO = useSelector(getIsEmbeddedHqO);
  const authenticatedOnServer = useSelector(getAuthenticatedOnServer);
  const orderSource = useSelector(getOrderSource);

  const isWindowInIframe = isInIframe();
  const allowLogin = useMemo(
    () =>
      !isWindowInIframe &&
      !isHqoLinkout &&
      !isEmbeddedHqO &&
      initialized &&
      !keycloak.authenticated,
    [
      isWindowInIframe,
      isHqoLinkout,
      isEmbeddedHqO,
      initialized,
      keycloak.authenticated,
    ],
  );

  const redirectedFromAuth = useMemo(
    () =>
      initialized &&
      keycloak.authenticated &&
      router.query[QUERY_AUTH_REDIRECTED] !== undefined,
    [initialized, keycloak.authenticated, router.query],
  );

  const getExpandedParams = extraParams => {
    const params = new URLSearchParams({
      originUrl: window.location.href,
      ...(promo ? { promo } : {}),
      ...(orderSource === ORDER_SOURCE.ORGANIZATION ? { isOrg: true } : {}),
      ...extraParams,
    }).toString();

    return `&${params}`;
  };

  // Automatically attempt to refresh token onTokenExpired
  useEffect(() => {
    keycloak.onTokenExpired = () => {
      keycloak?.updateToken(60).catch(() => {
        keycloak.clearToken();
        dispatch(
          addToast({ toast: { error: { message: 'errors.sessionExpire' } } }),
        );
      });
    };
  }, [keycloak]);

  // Check if the token needs to be updated every minute
  useInterval(
    () => {
      keycloak?.updateToken(60).catch(() => {
        keycloak.clearToken();
        dispatch(
          addToast({ toast: { error: { message: 'errors.sessionExpire' } } }),
        );
      });
    },
    initialized && keycloak?.authenticated ? 60000 : null,
  );

  // Clear guest address if the user has just authenticated
  useEffect(() => {
    if (redirectedFromAuth) {
      window.history.replaceState(
        null,
        '',
        queryString.exclude(router.asPath, [QUERY_AUTH_REDIRECTED]),
      );

      removeSessionItem(SESSION_KEY.GUEST_ADDRESS_DETAILS);
    }
  }, [redirectedFromAuth]);

  // Reload certain content if server-side rendering was unauthenticated but the page is now authenticated
  // e.g. guest cart conversion, load stamp card data, reload menu with item perks
  //
  // This could happen after a user logs in or navigates between two different Ritual domains
  useEffect(() => {
    if (!authenticatedOnServer && keycloak.authenticated && initialized) {
      dispatch(authenticated(mid, router.query));
    }
  }, [authenticatedOnServer, keycloak.authenticated, initialized]);

  // Load profile once keycloak has finished loading.
  useEffect(() => {
    if (initialized && keycloak.authenticated && !keycloak.profile) {
      keycloak.loadUserProfile().then(() => {
        setProfileLoaded(true);
      });
    }

    if (
      initialized &&
      !keycloak.authenticated &&
      Cookies.get(KEYCLOAK_REFRESH_TOKEN_COOKIE)
    ) {
      Cookies.remove(KEYCLOAK_REFRESH_TOKEN_COOKIE);
      logout();
    }
  }, [initialized, keycloak.authenticated]);

  // Clear anonymous session token if authenticated
  // (having both kcToken and kcAnonymousToken cookies set causes a "request header or cookie too large" error)
  useEffect(() => {
    if (
      keycloak.authenticated &&
      Cookies.get(KEYCLOAK_ANONYMOUS_TOKEN_COOKIE)
    ) {
      Cookies.remove(KEYCLOAK_ANONYMOUS_TOKEN_COOKIE);
    }
  }, [keycloak.authenticated]);

  const login = async ({ provider, redirectUri }) => {
    const parsed = queryString.parseUrl(redirectUri || window.location.href);
    parsed.query[QUERY_AUTH_REDIRECTED] = null;
    const redirect = queryString.stringifyUrl(parsed);
    const keycloakUrl = keycloak.createLoginUrl({
      redirectUri: redirect,
      idpHint: provider,
    });

    await setAdditionalParamsCookie({ countryCode });
    const additionalParams = getExpandedParams();
    window.location.assign(`${keycloakUrl}${additionalParams}`);
  };

  const loginWithOTP = async ({
    redirectUri,
    phoneNumber = '',
    phoneNumberCallingCode = '',
    phoneNumberCountryCode = '',
  }) => {
    const additionalParams = getExpandedParams({
      login_method: 'zen',
      ...(phoneNumber && {
        phone_country: phoneNumberCountryCode,
        phone_calling: phoneNumberCallingCode,
        phone_local: phoneNumber,
      }),
      ...additionalOTPLoginParams,
    });
    const parsed = queryString.parseUrl(redirectUri || window.location.href);
    parsed.query[QUERY_AUTH_REDIRECTED] = null;
    const redirect = queryString.stringifyUrl(parsed);
    const keycloakUrl = keycloak.createLoginUrl({ redirectUri: redirect });
    await setAdditionalParamsCookie({ countryCode });
    const loginUrl = `${keycloakUrl}${additionalParams}`;
    const [url, query] = loginUrl.split('?');
    /* Unlike login() above, this function makes a POST request so that sensitive
    data (phone numbers, etc.) do not get exposed in the URL */
    post(url, Object.fromEntries(new URLSearchParams(query)));
  };

  const register = async opts => {
    const defaults = {
      provider: 'email',
    };

    const { provider, redirectUri } = { ...defaults, ...opts };

    const keycloakUrl = keycloak.createRegisterUrl({
      redirectUri: redirectUri || window.location.href,
      idpHint: provider,
    });
    await setAdditionalParamsCookie({ countryCode });
    const registerUrl = `${keycloakUrl}${getExpandedParams()}`;
    window.location.assign(registerUrl);
  };

  const logout = () => {
    keycloak.logout({
      redirectUri: window.location.href,
    });
  };

  const setAuthModal = ({
    showLoginModal,
    title,
    loginMessage,
    image,
    showGuestCheckout,
    newReturnUri,
    checkoutOTP,
    closeable = true,
  }) => {
    if (showLoginModal && (shouldUseOTPLoginFlowHqO || checkoutOTP)) {
      loginWithOTP({ redirectUri: newReturnUri });
      return;
    }

    setLoginTitle(title || null);
    setMessage(loginMessage || null);
    setLoginImage(image);
    setShouldShowGuestCheckout(showGuestCheckout || false);
    setReturnUri(newReturnUri || false);
    setShowLogin(showLoginModal || false);
    setIsCloseable(closeable);
  };

  return {
    authenticated: keycloak.authenticated,
    login,
    loginWithOTP,
    logout,
    register,
    showLogin,
    loginTitle,
    shouldShowGuestCheckout,
    returnUri,
    isTokenExpired: keycloak.isTokenExpired,
    userProfile: formatUserProfile(keycloak?.profile),
    keycloakInternals: keycloak,
    initialized,
    message,
    loginImage,
    setAuthModal,
    redirectedFromAuth,
    allowLogin,
    isCloseable,
  };
};

export default useAuth;
