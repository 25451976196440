/**
 * Merchant Sagas
 */
import { put, takeLatest, call, all, getContext } from 'redux-saga/effects';
import { fetchMerchant, fetchMerchantDeliveryInfo } from '@artemis/api/athena';
import { authenticated } from '@artemis/store/auth/slice';
import { loadCartSaga } from '@artemis/store/cart/sagas';
import { loadMenuSaga } from '@artemis/store/menu/sagas';
import {
  loadMerchant,
  loadMerchantById,
  loadMerchantData,
  merchantLoaded,
  loadMerchantSuccess,
  loadMerchantError,
  merchantDeliveryInfoLoading,
  merchantDeliveryInfoLoaded,
} from './slice';

export function* loadMerchantSaga({
  payload: {
    externalId,
    brandExternalId,
    cartCheckoutCode,
    debug,
    menuDraftId,
    options,
  },
}) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchMerchant, apiClient, {
      externalId,
      brandExternalId,
      cartCheckoutCode,
    });

    yield all(
      [
        put(loadMerchantSuccess(data)),
        options.refetchMenuAfterLoad &&
          call(loadMenuSaga, {
            payload: {
              id: data.merchantData.menuItemGroupListId,
              debug,
              menuDraftId,
            },
          }),
        call(loadCartSaga, { payload: { merchantId: data.merchantData.id } }),
      ].filter(Boolean),
    );
  } catch (error) {
    yield put(loadMerchantError({ error }));
  }
}

export function* loadMerchantByIdSaga({ payload: { id, options } }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchMerchant, apiClient, { id });
    const { id: merchantId } = data.merchantData;

    yield all(
      [
        put(loadMerchantSuccess(data)),
        options.refetchMenuAfterLoad &&
          call(loadMenuSaga, {
            payload: {
              id: data.merchantData.menuItemGroupListId,
            },
          }),
        call(loadCartSaga, { payload: { merchantId } }),
      ].filter(Boolean),
    );
  } catch (error) {
    yield put(loadMerchantError({ error }));
  }
}

// Saga for loading merchant data given a merchant external ID
export function* loadMerchantDataSaga({
  payload: { externalId, brandExternalId, cartCheckoutCode },
}) {
  // Do not attempt if there is no merchant external ID (e.g. non-merchant pages)
  if (externalId) {
    try {
      const apiClient = yield getContext('athenaApiClient');
      const { data } = yield call(fetchMerchant, apiClient, {
        externalId,
        brandExternalId,
        cartCheckoutCode,
      });
      yield put(loadMerchantSuccess(data));
    } catch (error) {
      yield put(loadMerchantError({ error }));
    }
  } else {
    // Prevent any merchant-related loading states from lingering
    yield put(merchantLoaded());
  }
}

export function* loadMerchantDeliveryInfoSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(fetchMerchantDeliveryInfo, apiClient, payload);
    yield put(merchantDeliveryInfoLoaded(data));
  } catch {
    yield put(merchantDeliveryInfoLoaded(null));
  }
}

export default function* merchantData() {
  yield takeLatest(loadMerchant.type, loadMerchantSaga);
  yield takeLatest(loadMerchantById.type, loadMerchantByIdSaga);
  yield takeLatest(loadMerchantData.type, loadMerchantDataSaga);
  yield takeLatest(
    merchantDeliveryInfoLoading.type,
    loadMerchantDeliveryInfoSaga,
  );
  yield takeLatest(authenticated.type, loadMerchantDataSaga);
}
