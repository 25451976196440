import { createSelector } from 'reselect';
import {
  getCurrencyCode,
  getMerchantId,
} from '@artemis/store/merchant/selectors';
import { getLocalMerchantSelections } from '@artemis/store/localSelections/selectors';
import { formatCurrency } from '@artemis/utils/currency-format';
import {
  getSelectedOptionsCount,
  getUniqueSelectedOptionsCount,
  getSelectedOptionsTotalPriceMicro,
  getNeedsMoreSelected,
} from '@artemis/components/ItemSelection/utils';

const getMenuRoot = state => state.menu;

export const getMenu = createSelector(getMenuRoot, menu => {
  if (!menu) {
    return null;
  }
  return menu.data;
});

export const getItemSearch = createSelector(getMenuRoot, menu => {
  if (!menu) {
    return null;
  }
  return menu.itemSearch || {};
});

const getActiveMenuItem = createSelector(getMenuRoot, menu => {
  if (!menu) {
    return null;
  }
  return menu.activeItem;
});

export const getMenuIsLoading = createSelector(getMenuRoot, menu => {
  if (!menu) {
    return false;
  }
  return menu.isLoading;
});

export const getMenuId = createSelector(getMenu, data => {
  if (!data) {
    return null;
  }
  return data.id;
});

export const getMenuHasInStorePricing = createSelector(getMenu, data => {
  if (!data) {
    return null;
  }
  return data.hasInStorePricing;
});

export const getisMenuIqEnabled = createSelector(getMenu, data => {
  if (!data) {
    return null;
  }
  return data.isMenuIqEnabled;
});

export const getMenuItems = createSelector(getMenu, data => {
  if (!data || !data.items) {
    return null;
  }
  return data.items;
});

export const getMenuItemsFiltered = createSelector(
  getMenu,
  getItemSearch,
  (data, itemSearch) => {
    if (!data || !data.items) {
      return null;
    }
    if (itemSearch.query && itemSearch.resultCount !== null) {
      if (itemSearch.resultCount > 0) {
        const items = {};
        Object.keys(itemSearch.result).forEach(id => {
          items[id] = data.items[id];
        });
        return items;
      }
      return null;
    }
    return data.items;
  },
);

export const getMenuChoices = createSelector(getMenu, data => {
  if (!data) {
    return null;
  }
  return data.choices;
});

const getMenuOptions = createSelector(getMenu, data => {
  if (!data) {
    return null;
  }
  return data.options;
});

export const getMenuItemChoicesMap = createSelector(getMenuRoot, menu => {
  if (!menu) {
    return null;
  }
  return menu.itemChoices.data;
});

export const getIsActiveMenuItemLoading = createSelector(getMenuRoot, menu => {
  if (!menu) {
    return false;
  }
  return menu.itemChoices.isLoading;
});

const transformMenuChoice = (choice, choices, options, currencyCode) => ({
  ...choice,
  options: choice.optionIds
    .map(optionId => {
      const option = options[optionId];
      if (!option) return null;
      const optionWithSubChoices = {
        ...option,
        price: formatCurrency({
          value: option.priceMicro,
          currencyCode,
        }),
        subChoices: option.subchoiceIds
          .map(subChoiceId => {
            const subChoice = choices[subChoiceId];
            if (!subChoice) return null;
            return transformMenuChoice(subChoice, choices, options);
          })
          .filter(c => c !== null),
      };
      return optionWithSubChoices;
    })
    .filter(c => c !== null),
});

export const getChoicesWithOptions = createSelector(
  getMenuChoices,
  getMenuOptions,
  getCurrencyCode,
  (choices, options, currencyCode) => {
    if (!choices || !options) {
      return null;
    }
    const choicesWithOptions = {};
    Object.entries(choices).forEach(([key, value]) => {
      choicesWithOptions[key] = transformMenuChoice(
        value,
        choices,
        options,
        currencyCode,
      );
    });
    return choicesWithOptions;
  },
);

export const getMenuItemsWithPrices = createSelector(
  getMenuItemsFiltered,
  getCurrencyCode,
  (items, currencyCode) => {
    if (!items) {
      return null;
    }
    const withPrices = Object.entries(items).map(([key, value]) => [
      key,
      {
        ...value,
        price: formatCurrency({
          value: value.displayPriceMicro,
          currencyCode,
        }),
        ...(value.perk
          ? {
              perk: {
                ...value.perk,
                originalPrice: formatCurrency({
                  value: value.perk.originalPriceMicro,
                  currencyCode,
                }),
                savings: formatCurrency({
                  value: value.perk.savingsMicro,
                  currencyCode,
                }),

                discountedPrice: formatCurrency({
                  value: value.perk.discountedPriceMicro,
                  currencyCode,
                }),
              },
            }
          : {}),
      },
    ]);
    return withPrices.reduce(
      (newObject, [key, value]) => ({
        ...newObject,
        [key]: value,
      }),
      {},
    );
  },
);

const getMenuGroupsSorted = createSelector(getMenu, data => {
  if (!data || !data.categoryIds) {
    return null;
  }
  return data.categoryIds
    .map(id => data.categories[id])
    .filter(
      category => category && category.itemIds && category.itemIds.length > 0,
    );
});

export const getMenuGroupsWithItemsUnfiltered = createSelector(
  getMenuGroupsSorted,
  getMenuItems,
  (groups, items) => {
    if (!groups || !items) {
      return [];
    }
    return groups
      .map(group => ({
        ...group,
        items: group.itemIds
          .filter(itemId => !!items[itemId])
          .map(itemId => items[itemId]),
      }))
      .filter(category => category.items.length > 0);
  },
);

export const getMenuGroupsWithItems = createSelector(
  getMenuGroupsSorted,
  getMenuItemsWithPrices,
  (groups, items) => {
    if (!groups || !items) {
      return [];
    }
    return groups
      .map(group => ({
        ...group,
        items: group.itemIds
          .filter(itemId => !!items[itemId])
          .map(itemId => items[itemId]),
      }))
      .filter(category => category.items.length > 0);
  },
);

export const getActiveMenuItemObject = createSelector(
  getMenuItemsWithPrices,
  getActiveMenuItem,
  (items, activeItem) => {
    if (!items || !activeItem) {
      return null;
    }
    return items[activeItem.id];
  },
);

const transformMenuChoices = ({ choices, options } = {}, currencyCode) => {
  if (!choices || !options) {
    return null;
  }
  const choicesWithOptions = {};
  Object.entries(choices).forEach(([key, value]) => {
    choicesWithOptions[key] = transformMenuChoice(
      value,
      choices,
      options,
      currencyCode,
    );
  });
  return choicesWithOptions;
};

const processChoice = (
  selectedOptions,
  choice,
  applyDefaults,
  localOptionSelections,
) => {
  const modifiedChoice = { ...choice };
  const options = modifiedChoice.options.map(option => {
    const modifiedOption = { ...option };
    if (modifiedOption.subChoices.length > 0) {
      modifiedOption.subChoices = modifiedOption.subChoices.map(subChoice =>
        processChoice(
          selectedOptions,
          subChoice,
          applyDefaults,
          localOptionSelections,
        ),
      );
    }

    // The cart response may include the same option multiple times with different prices
    const matchingCartOptions = selectedOptions.filter(
      o => o.menuItemOptionId === modifiedOption.id,
    );

    const incrementCount = matchingCartOptions.reduce(
      (result, cartOption) => result + (cartOption.incrementCount || 1),
      0,
    );

    // Combined price for all of the selected options with the same ID (the unit price may vary if there is a free quantity)
    const totalPriceMicro = matchingCartOptions.reduce(
      (result, cartOption) => result + (cartOption.priceMicro || 0),
      0,
    );

    const selected = matchingCartOptions.length > 0;

    return {
      ...modifiedOption,
      selected,
      incrementCount,
      totalPriceMicro,
    };
  });

  // Select all available default options
  if (applyDefaults) {
    options.forEach((option, i) => {
      const shouldSelect = localOptionSelections
        ? localOptionSelections.includes(option.id)
        : option.isDefault;

      if (shouldSelect && option.availability.isAvailable) {
        options[i].selected = true;
      }

      if (options[i].selected) {
        options[i].incrementCount = option.defaultIncrementCount || 1;
      }
    });
  }

  // Select additional options until minSelectable is selected
  const numSelected = getSelectedOptionsCount(options);
  let added = 0;
  for (
    let i = 0;
    i < options.length && added < modifiedChoice.minSelectable - numSelected;
    i += 1
  ) {
    const additionalOptionsNeeded = modifiedChoice.minSelectable - numSelected;

    if (options[i].availability.isAvailable) {
      const incrementCount = Math.min(
        additionalOptionsNeeded,
        options[i].maxIncrementCount || 1,
      );
      options[i].selected = true;
      options[i].incrementCount = incrementCount;
      added += incrementCount;
    }
  }
  modifiedChoice.numSelected = getSelectedOptionsCount(options);
  modifiedChoice.numUniqueSelected = getUniqueSelectedOptionsCount(options);
  modifiedChoice.needsMoreSelected = getNeedsMoreSelected(options, choice);
  modifiedChoice.selectedTotalPriceMicro =
    getSelectedOptionsTotalPriceMicro(options);
  return {
    ...modifiedChoice,
    options,
  };
};

const areRequiredChoicesInStock = choices => {
  for (let i = 0; i < choices.length; i += 1) {
    const choice = choices[i];
    const minSelectable = choice.minSelectable ?? 0;

    if (minSelectable > 0) {
      // Calculate the maximum number of options that can possibly be added
      const maxOptionsIncrement =
        choice.options?.reduce((total, option) => {
          const { isAvailable } = option.availability;
          if (!isAvailable || !areRequiredChoicesInStock(option.subChoices)) {
            return total;
          }

          const maxIncrementCount = option.maxIncrementCount ?? 1;
          return total + maxIncrementCount;
        }, 0) || 0;

      if (maxOptionsIncrement < minSelectable) {
        return false;
      }
    }
  }

  return true;
};

export const getActiveMenuItemWithChoices = createSelector(
  getActiveMenuItem,
  getActiveMenuItemObject,
  getCurrencyCode,
  getChoicesWithOptions,
  getMenuItemChoicesMap,
  getMerchantId,
  getLocalMerchantSelections,
  (
    activeItemData,
    activeItem,
    currencyCode,
    allChoices,
    itemChoicesMap,
    merchantId,
    localMerchantSelections,
  ) => {
    if (!activeItem || !activeItemData) {
      return null;
    }

    const activeItemChoices = itemChoicesMap[activeItemData.id];
    const choices = activeItemChoices
      ? transformMenuChoices(activeItemChoices, currencyCode)
      : allChoices;
    const alreadyInCart = !!activeItemData.cartItemId;
    const perk = activeItemData.perk || activeItem.perk;
    const perkOverrides = alreadyInCart
      ? { isSelected: !!activeItemData.perk?.isSelected }
      : {};
    const localOptionSelections = alreadyInCart
      ? undefined
      : localMerchantSelections[merchantId]?.menuItems?.[activeItem.id]
          ?.options;
    const itemChoiceIds = activeItem.choiceIds.filter(
      choiceId => !!choices?.[choiceId],
    );

    return {
      groupTitle: activeItemData.groupTitle || '',
      cartItemId: activeItemData.cartItemId,
      ...activeItem,
      availability: {
        ...activeItem.availability,
        isAvailable:
          activeItem.availability.isAvailable &&
          areRequiredChoicesInStock(
            itemChoiceIds.map(choiceId => choices?.[choiceId]),
          ),
      },
      quantity: activeItemData.quantity || 1,
      note: activeItemData.note,
      choices: itemChoiceIds.map(choiceId =>
        processChoice(
          activeItemData.options,
          choices?.[choiceId],
          !alreadyInCart,
          localOptionSelections,
        ),
      ),
      ...(perk && {
        perk: { ...perk, ...perkOverrides },
      }),
    };
  },
);

export const getDefaultLocale = createSelector(
  getMenu,
  menu => menu?.defaultLocale || 'en_CA',
);

export const getSupportedLocales = createSelector(
  getMenu,
  menu => menu?.supportedLocales,
);

export const getIsExpressServiceModalOpen = createSelector(
  getMenuRoot,
  menu => menu.expressServiceModalOpen,
);

export const getIsServiceFeeModalOpen = createSelector(
  getMenuRoot,
  menu => menu.serviceFeeModalOpen,
);
