import { createSlice } from '@reduxjs/toolkit';

const daysToMs = days => 1000 * 60 * 60 * 24 * days;

export const initialState = {
  merchants: {},
  timestamps: [],
};
/**
 * {
 *    merchants: {
 *      [merchantId]: {
 *        menuItems: {
 *          [itemId]: {
 *            options: [optionId, optionId, ...]
 *          },
 *          ...
 *        },
 *      },
 *      ...
 *   },
 *   timestamps: [
 *     // Unique by itemId and always sorted by createdAt ascending since items are pushed to the end
 *     {
 *        createdAt: timestamp,
 *        merchantId,
 *        itemId
 *     },
 *     ...
 *   ]
 * }
 */

const localSelectionsSlice = createSlice({
  name: 'localSelections',
  initialState,
  reducers: {
    setLocalSelections: (state, action) => {
      const { merchantId, itemId, optionIds } = action.payload;
      state.merchants[merchantId] = {
        menuItems: {
          ...state.merchants[merchantId]?.menuItems,
          [itemId]: {
            options: optionIds,
          },
        },
      };

      // Push a unique timestamp for the item
      state.timestamps = state.timestamps.filter(x => x.itemId !== itemId);
      state.timestamps.push({
        createdAt: Date.now(),
        merchantId,
        itemId,
      });
    },
    clearExpiredLocalSelections: (state, action) => {
      const msUntilExpiry = daysToMs(action.payload.daysUntilExpiry);
      const currentTime = Date.now();
      let i = 0;

      for (; i < state.timestamps.length; i += 1) {
        const { merchantId, itemId, createdAt } = state.timestamps[i];
        const expiry = createdAt + msUntilExpiry;

        if (expiry > currentTime) break;

        delete state.merchants[merchantId]?.menuItems?.[itemId];

        // Clear the merchant if it has no more saved menu items
        const menuItems = state.merchants[merchantId]?.menuItems;
        if (menuItems && Object.keys(menuItems).length === 0) {
          delete state.merchants[merchantId];
        }
      }
      // Remove all expired timestamps and keep the rest
      state.timestamps = state.timestamps.slice(i);
    },
  },
});

export const { setLocalSelections, clearExpiredLocalSelections } =
  localSelectionsSlice.actions;

export const { name } = localSelectionsSlice;

export default localSelectionsSlice.reducer;
