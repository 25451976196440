/**
 * Scheduling Sagas
 */

import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { setScheduledForTime as updateScheduledForTime } from '@artemis/api/athena';
import { loadMenu } from '@artemis/store/menu/slice';
import {
  setScheduledForTime,
  setScheduledForTimeSuccess,
  setScheduledForTimeError,
} from './slice';

export function* setScheduledForTimeSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(updateScheduledForTime, apiClient, payload);
    yield put(setScheduledForTimeSuccess(data));
    yield put(loadMenu());
  } catch (err) {
    yield put(
      setScheduledForTimeError(
        err.response
          ? err.response.data
          : { error: { errorBody: { reason: 'UNKNOWN' } } },
      ),
    );
  }
}

export default function* setScheduledForTimeData() {
  yield takeLatest(setScheduledForTime.type, setScheduledForTimeSaga);
}
