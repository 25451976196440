import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  redeemAllPreview: {
    isLoading: false,
    data: null,
    error: null,
  },
  redeemModal: {
    open: false,
    pointsToRedeem: 0,
    redeemPreview: {
      isLoading: false,
      data: null,
      error: null,
    },
  },
  redeem: {
    isLoading: false,
    error: null,
  },
};

const pointsSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {
    openRedeemModal: state => {
      state.redeemModal.open = true;
    },
    closeRedeemModal: state => {
      state.redeemModal.open = false;
    },
    loadRedeemAllPreview: state => {
      state.redeemAllPreview.isLoading = true;
    },
    loadRedeemAllPreviewSuccess: (state, action) => {
      state.redeemAllPreview.isLoading = false;
      state.redeemAllPreview.data = action.payload;
    },
    loadRedeemAllPreviewError: (state, action) => {
      state.redeemAllPreview.isLoading = false;
      state.redeemAllPreview.error = action.payload;
    },
    loadRedeemModalPreview: state => {
      state.redeemModal.redeemPreview.isLoading = true;
    },
    loadRedeemModalPreviewSuccess: (state, action) => {
      state.redeemModal.redeemPreview.isLoading = false;
      state.redeemModal.redeemPreview.data = action.payload;
    },
    loadRedeemModalPreviewError: (state, action) => {
      state.redeemModal.redeemPreview.isLoading = false;
      state.redeemModal.redeemPreview.error = action.payload;
    },
    redeemPoints: state => {
      state.redeem.isLoading = true;
    },
    redeemPointsSuccess: state => {
      state.redeem.isLoading = false;
    },
    redeemPointsError: (state, action) => {
      state.redeem.isLoading = false;
      state.redeem.error = action.payload;
    },
    updatePointsToRedeem: (state, action) => {
      state.redeemModal.pointsToRedeem = action.payload;
    },
  },
});

export const {
  openRedeemModal,
  closeRedeemModal,
  loadRedeemAllPreview,
  loadRedeemAllPreviewSuccess,
  loadRedeemAllPreviewError,
  loadRedeemModalPreview,
  loadRedeemModalPreviewSuccess,
  loadRedeemModalPreviewError,
  redeemPoints,
  redeemPointsSuccess,
  redeemPointsError,
  updatePointsToRedeem,
} = pointsSlice.actions;

export default pointsSlice.reducer;
