import { put, takeLatest, call, getContext, select } from 'redux-saga/effects';
import {
  getRedeemPointsPreview,
  redeemPoints as postRedeemPoints,
} from '@artemis/api/clientUserGateway';
import { loadCart } from '@artemis/store/cart/slice';
import {
  getCurrencyCode,
  getMerchantId,
} from '@artemis/store/merchant/selectors';
import {
  getPointsToRedeem,
  getMaxRedeemablePoints,
} from '@artemis/store/points/selectors';
import { loadUser } from '@artemis/store/user/slice';
import { getUserExternalId } from '@artemis/store/user/selectors';
import {
  closeRedeemModal,
  loadRedeemAllPreview,
  loadRedeemAllPreviewSuccess,
  loadRedeemAllPreviewError,
  loadRedeemModalPreview,
  loadRedeemModalPreviewSuccess,
  loadRedeemModalPreviewError,
  redeemPoints,
  redeemPointsSuccess,
  redeemPointsError,
} from './slice';

export function* loadRedeemAllPointsPreviewSaga() {
  try {
    const pointsToRedeem = yield select(getMaxRedeemablePoints);
    const currency = yield select(getCurrencyCode);
    const externalUserId = yield select(getUserExternalId);

    if (!externalUserId || !currency) {
      return;
    }

    const apiClient = yield getContext('clientUserGatewayApiClient');
    const res = yield call(getRedeemPointsPreview, apiClient, {
      externalUserId,
      pointsToRedeem,
      currency,
    });
    yield put(loadRedeemAllPreviewSuccess(res.data));
  } catch (err) {
    yield put(loadRedeemAllPreviewError(err));
  }
}

export function* loadRedeemPointsModalPreviewSaga() {
  try {
    const pointsToRedeem = yield select(getPointsToRedeem);
    const currency = yield select(getCurrencyCode);
    const externalUserId = yield select(getUserExternalId);

    if (!externalUserId || !currency) {
      return;
    }

    const apiClient = yield getContext('clientUserGatewayApiClient');
    const res = yield call(getRedeemPointsPreview, apiClient, {
      externalUserId,
      pointsToRedeem,
      currency,
    });
    yield put(loadRedeemModalPreviewSuccess(res.data));
  } catch (err) {
    yield put(loadRedeemModalPreviewError(err));
  }
}

export function* redeemPointsSaga() {
  try {
    const pointsToRedeem = yield select(getPointsToRedeem);
    const currency = yield select(getCurrencyCode);
    const externalUserId = yield select(getUserExternalId);
    const merchantId = yield select(getMerchantId);

    const apiClient = yield getContext('clientUserGatewayApiClient');
    const res = yield call(postRedeemPoints, apiClient, {
      externalUserId,
      pointsToRedeem,
      currency,
    });
    yield put(redeemPointsSuccess(res.data));
    yield put(loadUser());
    yield put(closeRedeemModal());
    if (merchantId) {
      // Reload the cart because the order total may have updated
      yield put(loadCart({ merchantId }));
    }
  } catch (err) {
    yield put(redeemPointsError(err));
  }
}

export default function* pointsData() {
  yield takeLatest(
    loadRedeemModalPreview.type,
    loadRedeemPointsModalPreviewSaga,
  );
  yield takeLatest(loadRedeemAllPreview.type, loadRedeemAllPointsPreviewSaga);
  yield takeLatest(redeemPoints.type, redeemPointsSaga);
}
