import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isInitialized: false,
  isLoading: false,
  isUpdating: false,
  data: null,
  error: null,
};

const updateDeliveryAddressesReducer = state => {
  state.isUpdating = true;
};

const deliveryAddressesSlice = createSlice({
  name: 'deliveryAddresses',
  initialState,
  reducers: {
    loadDeliveryAddresses: state => {
      state.isLoading = true;
    },
    loadDeliveryAddressesSuccess: (state, action) => {
      state.isInitialized = true;
      state.isLoading = false;
      state.data = action.payload;
      state.error = null;
    },
    loadDeliveryAddressesError: (state, action) => {
      state.isInitialized = true;
      state.isLoading = false;
      state.error = action.payload;
    },
    removeDeliveryAddress: updateDeliveryAddressesReducer,
    createDeliveryAddress: updateDeliveryAddressesReducer,
    updateDeliveryAddressesSuccess: state => {
      state.isUpdating = false;
      state.error = null;
    },
    updateDeliveryAddressesError: (state, action) => {
      state.isUpdating = false;
      state.error = action.payload;
    },
    clearDeliveryAddressesError: state => {
      state.error = null;
    },
  },
});

export const {
  loadDeliveryAddresses,
  loadDeliveryAddressesSuccess,
  loadDeliveryAddressesError,
  removeDeliveryAddress,
  createDeliveryAddress,
  updateDeliveryAddressesSuccess,
  updateDeliveryAddressesError,
  clearDeliveryAddressesError,
} = deliveryAddressesSlice.actions;

export default deliveryAddressesSlice.reducer;
