import { CLAIM_CHECKOUTCODE } from './constants';
import { CHECKOUT_CODE_PARAM } from './query/constants';

export function getCheckoutCode(p) {
  return CLAIM_CHECKOUTCODE[p] || p;
}

export function getCheckoutCodeFromQuery(query) {
  return getCheckoutCode(query[CHECKOUT_CODE_PARAM]) || false;
}
