import { track } from '@artemis/utils/analytics';

const EVENT_ACTION_PM_APP_INTEGRATION_ACTION_INITIAL_LOAD =
  'RW_PM_APP_INTEGRATION_ACTION_INITIAL_LOAD';
const EVENT_ACTION_PM_APP_INTEGRATION_ACTION_INITIALIZED =
  'RW_PM_APP_INTEGRATION_ACTION_INITIALIZED';
const EVENT_ACTION_PM_APP_INTEGRATION_ACTION_AUTOMATIC_LOGOUT =
  'RW_PM_APP_INTEGRATION_ACTION_AUTOMATIC_LOGOUT';

// Logged on initial page impression, before UI calls any SDK methods
export const logIntegrationInitialLoad = ({ integrationType }) => {
  track(EVENT_ACTION_PM_APP_INTEGRATION_ACTION_INITIAL_LOAD, {
    integrationType,
  });
};

// Logged after the UI has successfully initialized using the SDK
export const logIntegrationInitialized = ({ integrationType }) => {
  track(EVENT_ACTION_PM_APP_INTEGRATION_ACTION_INITIALIZED, {
    integrationType,
  });
};

// Logged when the UI detects it should log the user out (HqO only)
export const logIntegrationAutomaticLogout = ({ integrationType }) => {
  track(EVENT_ACTION_PM_APP_INTEGRATION_ACTION_AUTOMATIC_LOGOUT, {
    integrationType,
  });
};
