import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

export const initialState = {
  isLoading: false,
  data: null,
  error: null,
  id: null,
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    loadWorkspace: state => {
      state.isLoading = true;
    },
    loadWorkspaceSuccess: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    loadWorkspaceError: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    updateWorkspaceId: (state, action) => {
      state.id = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(HYDRATE, (_, action) => action.payload.workspace);
  },
});

export const {
  loadWorkspace,
  loadWorkspaceSuccess,
  loadWorkspaceError,
  updateWorkspaceId,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
