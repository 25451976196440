import JsCookie from 'js-cookie';

// Chromium browsers limit cookie expiry to 400 days
// https://chromestatus.com/feature/4887741241229312
export const MAX_EXPIRY = 400;

/**
 * Sets default cookie attributes. Per-call attributes override these defaults.
 * Usage:
 * ```
 * Cookies.set('name', 'value', { expires: 365 });
 * Cookies.get('name');
 * Cookies.remove('name');
 * ```
 * */
const Cookies = JsCookie.withAttributes({
  expires: 30, // Expire after 30 days
  sameSite: 'Lax', // SameSite=Lax
});

export default Cookies;
