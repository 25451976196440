import { put, takeLatest, call, getContext } from 'redux-saga/effects';
import { setFulfillment as putFulfillment } from '@artemis/api/athena';
import {
  cartDeliveryInfoLoaded,
  cartDeliveryInfoLoading,
} from '@artemis/store/cart/slice';
import { FULFILLMENT_TYPE as FULFILLMENT } from '@artemis/utils/constants';
import {
  setFulfillment,
  setFulfillmentSuccess,
  setFulfillmentError,
  openModal,
} from './slice';

export function* setFulfillmentSaga({ payload }) {
  try {
    const apiClient = yield getContext('athenaApiClient');
    const { data } = yield call(putFulfillment, apiClient, payload);
    yield put(setFulfillmentSuccess(data));

    if (
      data.fulfillmentType === FULFILLMENT.DELIVERY &&
      !data.scheduledForTime
    ) {
      yield put(cartDeliveryInfoLoading(payload));
    } else {
      yield put(cartDeliveryInfoLoaded(null));
    }
  } catch (err) {
    yield put(
      setFulfillmentError({
        ...(err?.response?.data || {
          error: { errorBody: { reason: 'UNKNOWN' } },
        }),
        ...(payload.userDeliveryAddressId && {
          userDeliveryAddressId: payload.userDeliveryAddressId,
        }),
      }),
    );
    if (payload.isToggling && payload.userDeliveryAddressId) {
      yield put(openModal());
    }
  }
}

export default function* fulfillmentData() {
  yield takeLatest(setFulfillment.type, setFulfillmentSaga);
}
