import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/node';
import {
  getIsEmbedded,
  getIntegrationType,
} from '@artemis/store/embed/selectors';
import {
  getOrderSource,
  getOrderSourceId,
  getOrderSourceSubdomain,
} from '@artemis/store/order/selectors';

// Sets custom tags based on redux state
// Errors can be filtered in Sentry using these tags
const useConfigureSentryTags = () => {
  const isEmbbeded = useSelector(getIsEmbedded);
  const embeddedIntegrationType = useSelector(getIntegrationType);
  const orderSourceType = useSelector(getOrderSource);
  const orderSourceSubdomain = useSelector(getOrderSourceSubdomain);
  const orderSourceId = useSelector(getOrderSourceId);

  useEffect(() => {
    Sentry.setTag('x_embedded', isEmbbeded);
  }, [isEmbbeded]);

  useEffect(() => {
    if (embeddedIntegrationType) {
      Sentry.setTag('x_embedded_type', embeddedIntegrationType);
    }
  }, [embeddedIntegrationType]);

  useEffect(() => {
    Sentry.setTag('x_order_source_type', orderSourceType);
  }, [orderSourceType]);

  useEffect(() => {
    if (orderSourceSubdomain) {
      Sentry.setTag('x_order_source_subdomain', orderSourceSubdomain);
    }
  }, [orderSourceSubdomain]);

  useEffect(() => {
    if (orderSourceId) {
      Sentry.setTag('x_order_source_id', orderSourceId);
    }
  }, [orderSourceId]);
};

export default useConfigureSentryTags;
