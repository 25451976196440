/* eslint-disable no-param-reassign */
import axios from 'axios';
import { getKcTokenClientSide } from '@artemis/integrations/auth/utils';
import { logFailedRequest } from '@artemis/utils/log';
import { getAcceptLanguageHeader } from './util';

const createServerApiClient = ({ req }) => {
  const baseURL = process.env.RT_FULFILLMENT_SERVER_BACKEND_URL;
  const apiClient = axios.create({ baseURL });

  apiClient.interceptors.request.use(config => {
    config.headers['Accept-Language'] = getAcceptLanguageHeader({ req });

    if (req?.accessToken) {
      config.headers.authorization = `Bearer ${req.accessToken}`;
    }

    return config;
  });

  apiClient.interceptors.response.use(
    () => {},
    err => {
      logFailedRequest({ logger: req.logger, err });
    },
  );

  return apiClient;
};

const createBrowserApiClient = () => {
  const baseURL = process.env.RT_FULFILLMENT_BROWSER_BACKEND_URL;

  const apiClient = axios.create({
    baseURL,
    withCredentials: true,
    headers: { 'Accept-Language': getAcceptLanguageHeader() },
  });

  apiClient.interceptors.request.use(async config => {
    try {
      const accessToken = getKcTokenClientSide();
      if (accessToken) {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    } catch {
      // intentionally empty
    }
    return config;
  });

  return apiClient;
};

const createFulfillmentsApiClient = ({ isServer, req, res }) => {
  if (isServer) {
    return createServerApiClient({ req, res });
  }
  return createBrowserApiClient();
};

export const createFulfillment = async (apiClient, { fulfillment }) =>
  apiClient.post(`/fulfillments`, fulfillment);

export const findFulfillments = async (
  apiClient,
  {
    merchantIds,
    basketIds,
    orderIds,
    orderTokens,
    fulfillmentStateFilter,
    guestStatus,
  },
) =>
  apiClient.get(`/fulfillments`, {
    params: {
      merchantIds,
      basketIds,
      orderIds,
      orderTokens,
      fulfillmentStateFilter,
      guestStatus,
    },
  });

export const getFulfillmentDetails = async (
  apiClient,
  { fulfillmentId, includeOrderId },
) =>
  apiClient.get(`/fulfillments/${fulfillmentId}`, {
    params: { includeOrderId },
  });

export const getFulfillmentStatus = async (apiClient, { fulfillmentId }) =>
  apiClient.get(`/fulfillments/${fulfillmentId}/status`);

export const cancelFulfillment = async (apiClient, { fulfillmentId }) =>
  apiClient.delete(`/fulfillments/${fulfillmentId}`);

export const joinFulfillment = async (apiClient, { fulfillmentId }) =>
  apiClient.post(`/fulfillments/${fulfillmentId}/guests`);

export const removeFulfillmentGuest = async (
  apiClient,
  { fulfillmentId, externalUserId },
) =>
  apiClient.delete(`/fulfillments/${fulfillmentId}/guests/${externalUserId}`);

export const leaveFulfillment = async (apiClient, { fulfillmentId }) =>
  apiClient.post(`/fulfillments/${fulfillmentId}/guests/leave`);

export const updateFulfillment = async (
  apiClient,
  { fulfillmentId, fulfillment },
) =>
  apiClient.patch(`/fulfillments/${fulfillmentId}`, fulfillment, {
    params: { withValidation: true },
  });

export const getFulfillmentValidationErrors = async (
  apiClient,
  { fulfillmentId },
) => apiClient.get(`/fulfillments/${fulfillmentId}/validation`);

export const getFulfillmentScheduledTimeSlots = async (
  apiClient,
  { fulfillmentId },
) => apiClient.get(`/fulfillments/${fulfillmentId}/scheduled-time-slots`);

export const getFulfillmentTipOptions = async (apiClient, { fulfillmentId }) =>
  apiClient.get(`/fulfillments/${fulfillmentId}/tip-options`);

export const getFulfillmentFundingOptions = async (
  apiClient,
  { fulfillmentId },
) => apiClient.get(`/fulfillments/${fulfillmentId}/funding-options`);

export const getFulfillmentLocations = async (apiClient, { fulfillmentId }) =>
  apiClient.get(`/fulfillments/${fulfillmentId}/locations`);

export const confirmFulfillment = async (
  apiClient,
  {
    fulfillmentId,
    checksum,
    paymentMethod,
    paymentChallengeAnswer,
    fundingSourceId,
    subdomain,
  },
) =>
  apiClient.post(`/fulfillments/${fulfillmentId}/confirm`, {
    checksum,
    paymentMethod,
    fundingSourceId,
    paymentChallengeAnswer,
    subdomain,
  });

export const createInvite = async (apiClient, { fulfillmentId }) =>
  apiClient.post(`/fulfillments/${fulfillmentId}/create-invite`, {}); // TODO(MTO-959): add subdomain to body

export const getFulfillmentTypeOptions = async (apiClient, { fulfillmentId }) =>
  apiClient.get(`/fulfillments/${fulfillmentId}/fulfillment-type-options`);

export default createFulfillmentsApiClient;
