/* istanbul ignore file */
const PM_APP_KEYCLOAK_CLIENT_ID = 'web-pm-vornado';
const MAX_TOKEN_SIZE = 4000;

const getKeycloak = () => {
  const config = {
    ...process.env.RT_KEYCLOAK_CONFIG,
    clientId: PM_APP_KEYCLOAK_CLIENT_ID,
    resource: PM_APP_KEYCLOAK_CLIENT_ID,
  };

  return new window.Keycloak(config);
};

const login = keycloak => {
  const redirectUri = window.location.href;
  const url = `${keycloak.createLoginUrl({
    redirectUri,
  })}&login_method=zen`;
  window.location.assign(url);
};

const getInitConfig = () => ({
  checkLoginIframe: false,
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/order/silent-check-sso.html`,
});

// Calls function after a delay, to simulate latency of Ritual SDK
const callWithDelay = (func, delay = 1000) =>
  new Promise(resolve =>
    setTimeout(() => {
      func();
      resolve();
    }, delay),
  );

export const setupStubbedWebviewHooks = () => {
  const keycloakPromise = new Promise(resolve => {
    window.addEventListener('load', () => {
      const keycloak = getKeycloak();
      const initConfig = getInitConfig();
      keycloak.init(initConfig).then(authenticated => {
        if (authenticated) {
          resolve(keycloak);
        } else {
          login(keycloak);
        }
      });
    });
  });

  window.Android = {
    getRitualAccessToken: async () => {
      const keycloak = await keycloakPromise;
      const { token } = keycloak;
      if (token && token.length > MAX_TOKEN_SIZE) {
        // eslint-disable-next-line no-console
        console.error(
          'Token may be too large to fit into cookie; try using a different user',
        );
      }

      window.embeddedIntegration.onRitualGetAccessToken(token);
    },
    refreshRitualAccessToken: async () => {
      const keycloak = await keycloakPromise;
      keycloak.clearToken();
      keycloak.updateToken(-1).then(() => {
        window.embeddedIntegration.onRitualAccessTokenRefreshed(keycloak.token);
      });
    },
    getRitualSupportedPaymentMethods: () => {
      callWithDelay(() => {
        window.embeddedIntegration.onRitualGetSupportedPaymentMethods([
          'GOOGLE_PAY',
        ]);
      });
    },
    getRitualPayment: json => {
      const { total, currency } = JSON.parse(json);

      // eslint-disable-next-line no-alert
      const confirmed = prompt(
        `Do you agree to pay $${total} ${currency}?`,
        'YES',
      );
      if (confirmed === 'YES') {
        callWithDelay(() => {
          window.embeddedIntegration.onRitualPaymentReady(
            'stripe-payment-method-id',
          );
        });
      } else {
        callWithDelay(() => {
          window.embeddedIntegration.onRitualPaymentReady('');
        });
      }
    },
    ritualPaymentComplete: () => {
      callWithDelay(() => {
        window.embeddedIntegration.onRitualPaymentComplete(true);
      });
    },
  };
};
